<template>
  <div
    :dir="dict.dir[lang]"
    class="container-fluid p-0 bg-none h-auto"
    :class="lang === 'per' ? 'text-end' : 'text-start'"
    style="min-height: calc(100vh - 60px)"
  >
    <div class="p-3 m-0 d-flex bg-page-title justify-content-start">
      <div
        class="align-items-center"
        style="background: none !important"
        :class="
          dict.dir[lang] === 'rtl'
            ? 'page-title-icon-rtl'
            : 'page-title-icon-ltr'
        "
      >
        <img src="@/assets/icons/3.png" alt="dashboard" style="width: 60px" />
      </div>
      <div class="d-flex align-items-center">
        <h5 class="mb-0 mr-auto">{{ dict.title[lang] }}</h5>
      </div>
    </div>
    <div
      class="row g-0 p-2"
      :class="lang === 'per' ? 'text-end' : 'text-start'"
      style="min-height: calc(100vh - 152px)"
    >
      <div class="col-lg-12 h-100">
        <div
          class="card h-100 p-3"
          style="
            background-color: rgba(255, 255, 255, 0.75) !important;
            min-height: calc(100vh - 168px);
          "
        >
          <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
            <li
              v-for="(item, index) in tabs"
              class="mga-tab-nav-item"
              @click="activeTab = item.value"
              :key="index"
            >
              <a
                class="mga-tab-nav-link"
                :class="{ active: activeTab === item.value }"
                :id="item.name"
                data-toggle="tab"
                role="tab"
                aria-controls="tab1"
                aria-selected="true"
              >
                {{ dict[item.value][lang] }}
              </a>
            </li>
          </ul>
          <div class="card-body h-100" style="overflow: hidden">
            <transition name="slide-fade" mode="out-in">
              <div class="tab-content h-100" :key="activeTab">
                <div v-if="activeTab === 'tab1'" class="h-100">
                  <b-container fluid style="padding: 0 !important">
                    <ejs-grid
                      ref="proccessFlow_table"
                      :allowSorting="true"
                      :locale="lang"
                      :allowExcelExport="true"
                      :allowFiltering="true"
                      :editSettings="curTableOptions.editSettings"
                      :allowPdfExport="true"
                      :toolbar="toolbarGenerator"
                      :pdfExportComplete="pdfExportComplete"
                      :toolbarClick="toolbarClick"
                      :autoFit="true"
                      :allowPaging="true"
                      :enableRtl="lang == 'per'"
                      :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
                      :selectionSettings="curTableOptions.selectionOptions"
                      :dataSource="curTableOptions.items"
                    >
                      <e-columns>
                        <e-column width="50" type="checkbox"></e-column>
                        <e-column
                          v-for="(item, index) in curTableOptions.fields"
                          :key="index"
                          :field="item.key"
                          :headerText="item.label"
                          :template="item.hasTemplate ? item.key : null"
                        ></e-column>
                      </e-columns>

                      <template v-slot:modify="{ data }">
                        <div class="d-flex justify-content-between col-md-3">
                          <span
                            v-permission="'wb_edit_role'"
                            v-if="data.name != 'admin'"
                            @click="editProcess(data._id, $event)"
                            class="e-icons e-edit-6"
                          >
                          </span>
                          <span
                            v-permission="'wb_remove_role'"
                            v-if="data.name != 'admin'"
                            @click="removeProcess(data._id, $event)"
                            class="e-icons e-delete-1 text-danger"
                            style="font-size: 16px"
                          ></span>
                        </div>
                      </template>
                    </ejs-grid>
                  </b-container>
                </div>
              </div>
            </transition>
          </div>

          <ejs-dialog
            width="100%"
            height="100%"
            :isModal="true"
            :enableHtmlSanitizer="false"
            :visible="isVisible"
            :buttons=" [{ click: showMsgBoxTwo, buttonModel: { content: 'OK', isPrimary: true } },
          { click: resetModal, buttonModel: { content: 'Cancel' } }]"
            :resizeHandles="['All']"
            :showCloseIcon="true"
            header="Software Update"
            :close="resetModal"
          >
            <div>
              <create-process ref="crProcess" v-if="createProcess" />
            </div>
          </ejs-dialog>


          <b-modal
            id="processModal"
            size="xl"
            centered
            header-text-variant="dark"
            header-border-variant="primary"
            body-text-variant="dark"
            no-close-on-backdrop
            content-class="shadow"
            scrollable
            @hidden="resetModal"
            @ok="showMsgBoxTwo"
          >
            <template #modal-header>
              <h5>{{ modalTitle }}</h5>
              <i>
                <font-awesome-icon
                  icon="fas fa-xmark"
                  class="close"
                  @click="showMsgBoxTwo"
                />
              </i>
            </template>
            <div>
              <create-process ref="crProcess" v-if="createProcess" />
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CreateProcess from "@/components/processFlowComponents/crud/createProccess.vue";
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";

export default {
  components: { CreateProcess },
  data() {
    return {
      boxTwo: "",
      tabs: [{ name: "tab1", value: "tab1" }],
      dict: {
        dir: { eng: "ltr", per: "rtl" },
        newRole: { eng: "new proccessFlow", per: "چرخه فرآیند جدید" },
        title: { eng: "process flow diagram", per: "دیاگرام چرخه فرآیند" },
        tab1: { eng: "process flow diagram", per: "چرخه فرآیند" },
        mapName: { eng: "map name", per: "نام نقشه" },
        enterMapName: { eng: "enter map name", per: "نام نقشه را وارد کنید" },
        modalTitle: { eng: "process flow", per: "چرخه فرآیند" },
        confirmTitle: { eng: "close confirmation", per: "تایید بستن" },
        confirmRemoveTitle: { eng: "remove confirmation", per: "تایید حذف" },
        confirmText: {
          eng: "Are you sure to close this window?",
          per: "آیا از بستن این پنجره اطمینان دارید؟"
        },
        confirmRemoveText: {
          eng: "Are you sure to delete this process?",
          per: "آیا از حذف این چرخه فرآیند اطمینان دارید؟"
        },

        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        th_floorTitle: { eng: "name", per: "نام" },
        validName: { eng: "name is required!", per: "ورود نام الزامی است!" },
        search: { eng: "Type to Search", per: "برای جستجو تایپ کنید" },
        clear: { eng: "clear", per: "حذف" }
      },
      activeTab: "tab1",
      curTableOptions: {
        items: [],
        fields: [
          {
            key: "name",
            label: "name",
            hasTemplate: false
          },
          { key: "modify", label: "", hasTemplate: true }
        ],
        selectionOptions: {
          type: "Multiple",
          persistSelection: false,
          checkboxOnly: true
        },
        editSettings: {
          allowEditing: false,
          allowAdding: true,
          allowDeleting: true,
          mode: "Dialog"
        },
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "active",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },
      createProcess: false,
      isVisible: false
    };
  },
  computed: {
    ...mapGetters(["processListGetter"]),
    lang() {
      return this.$store.state.chpge.lang;
    },
    modalTitle() {
      return this.dict.modalTitle[this.lang];
    },
    toolbarGenerator() {
      if (this.userPermission.includes("wb_add_role")) {
        return [
          "Search",
          {
            text: this.dict.newRole[this.lang],
            id: "add_role",
            prefixIcon: "e-icons e-add"
          }
        ];
      } else return ["Search"];
    },
    userPermission() {
      return this.$store.state.data.user.permissions;
    }
  },
  methods: {
    toolbarClick(args) {
      if (args.item.id.includes("pdfexport")) {
        this.$refs.proccessFlow_table.showSpinner();
        this.$refs.proccessFlow_table.pdfExport();
      } else if (args.item.id.includes("excelexport")) {
        this.$refs.proccessFlow_table.excelExport();
      } else if (args.item.id.includes("csvexport")) {
        this.$refs.proccessFlow_table.csvExport();
      } else if (args.item.id.includes("add_role")) {
        this.createPage();
      }
    },
    pdfExportComplete() {
      this.$refs.proccessFlow_table.hideSpinner();
    },
    handleOk($bv) {
      $bv.preventDefault();
      this.$refs.crProcess.submitWorkSpageChanges();
    },
    showMsgBoxTwo($bv) {
      $bv.preventDefault();
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(this.dict.confirmText[this.lang], {
          title: this.dict.confirmTitle[this.lang],
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.lang == "per" ? "ذخیره و بستن" : "SAVE & CLOSE",
          cancelTitle: this.lang == "per" ? "بستن" : "CLOSE without SAVE",
          hideHeaderClose: true,
          noCloseOnBackdrop: true,
          centered: true
        })
        .then((value) => {
          if (value) {
            this.$refs.crProcess.submitWorkSpageChanges();
            this.isVisible = false;
            this.createProcess = false;
            this.getList();
          } else {
            this.boxTwo = value;
            this.isVisible = false;
            this.createProcess = false;
          }
        });
    },
    resetModal: function() {
      this.createProcess = false;
      this.isVisible = false;
    },
    createPage: function() {
      this.createProcess = true;
      this.$store.commit("chpge/changePageStatus", {
        type: null
      });
      this.isVisible = true;
      // this.$bvModal.show("processModal");
    },
    getList() {
      this.$store.dispatch("getAllProcess").then((res) => {
        this.curTableOptions.items = res;
        this.$refs.proccessFlow_table.ej2Instances.refresh();
      });
    },
    editProcess(id, data) {
      // this.$store.dispatch("chpge/changePage", "createProcess");
      this.createProcess = true;
      this.isVisible = true;
      this.$store.commit("chpge/changePageStatus", {
        type: "Edit",
        index: id
      });
    },
    removeProcess(id) {
      this.$bvModal
        .msgBoxConfirm(this.dict.confirmRemoveText[this.lang], {
          title: this.dict.confirmRemoveTitle[this.lang],
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.lang == "per" ? "بله" : "Yes",
          cancelTitle: this.lang == "per" ? "خیر" : "No",
          hideHeaderClose: true,
          noCloseOnBackdrop: true,
          centered: true
        })
        .then((value) => {
          if (value) {
            this.$store
              .dispatch("removeProcess", {
                _id: id
              })
              .then(() => {
                this.getList();
              });
            this.$bvModal.hide("processModal");
            this.createProcess = false;
          } else {
            this.$bvModal.hide("processModal");
          }
        });
    }
  },
  watch: {
    processListGetter: {
      handler(val) {
        this.curTableOptions.items = val;
      },
      deep: true
    }
  },
  mounted() {
    this.getList();
    this.$store.dispatch("data/sendDataForSSE", { id: [],action: "add" });
  },
  provide: {
    grid: [Page, Sort, Toolbar, PdfExport, ExcelExport, Edit, Search]
  }
};
</script>
<style src="@/assets/styles/elements.css"></style>
