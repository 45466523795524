<template>
  <div class=" bgLoad" style="z-index: 9999">
    <div style="background: transparent!important;">
      <img src="@/assets/gif/ezgif.com-apng-to-gif-converter.gif" width="200" alt="">
      <p class="pt-0 mt-0 text-white">loading...</p>
    </div>
<!--    <div class="dots">-->
<!--      <div class="row">-->
<!--        <span></span>-->
<!--        <span></span>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <span></span>-->
<!--        <span></span>-->
<!--        <span></span>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "loadingPage",
  data() {
    return {};
  }
};
</script>

<style scoped>
.bgLoad {
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.31);
  backdrop-filter: blur(1px);

}

.dots {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.row {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.dots span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #474bff;
  animation: bounce 0.5s infinite alternate;
}

.row:first-child span:nth-child(2) {
  animation-delay: 0.1s;
}

.row:nth-child(2) span:nth-child(1) {
  animation-delay: 0.2s;
}

.row:nth-child(2) span:nth-child(2) {
  animation-delay: 0.3s;
}

.row:nth-child(2) span:nth-child(3) {
  animation-delay: 0.4s;
}

/* Bounce animation */
@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-20px);
  }
}
</style>
