<template>

  <div
    ref="mainCont"
    class="card-body p-1 d-flex justify-content-center align-items-center"
    style="height: 100%; width: 100%"
  >
    <canvas ref="gauge" style="height: 100%; width: 100%"></canvas>

    <b-modal
      :id="widgetId"
      size="lg"
      centered
      body-class="my-body-class"
      hide-header-close
      :title="modalTitle[lang]"
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
      :dir="dict.dir[lang]"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div>
        <b-container fluid="md" class="p-0">
          <div class="p-3">
            <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
              <li
                v-for="(item, index) in tabList"
                class="mga-tab-nav-item"
                @click="activeTab = item"
                :key="index"
              >
                <a
                  class="mga-tab-nav-link"
                  :class="{ active: activeTab === item }"
                  :id="item"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="tab1"
                  aria-selected="true"
                >
                  {{ dict[item][lang] }}
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body h-100 p-3" style="overflow: hidden">
            <transition name="slide-fade" mode="out-in">
              <div class="tab-content h-100" :key="activeTab">
                <div v-if="activeTab === 'data'" class="h-100">
                  <div fluid class="container-fluid p-0 h-100">
                    <div class="d-flex align-items-center px-4">
                      <ejs-textbox
                        :value="myData.datas[0]['name']"
                        :placeholder="dict.pickData[lang]"
                        :enableRtl="lang == 'per'"
                        floatLabelType="Auto"
                        :showClearButton="true"
                        v-model="myData.datas[0]['name']"
                      ></ejs-textbox>
                      <b-button @click="addItem" class="btn-sm" variant="primary"><span
                        class="e-icons e-edit-6 "></span>
                      </b-button>

                    </div>
                  </div>
                </div>

                <div
                  v-if="Object.keys(gaugeOptions).includes(activeTab)"
                  class="h-100"
                >
                  <div fluid class="container-fluid p-0 h-100">
                    <div class="row">
                      <div
                        v-for="(option, index) in gaugeOptions[activeTab]"
                        :key="index"
                        class="col-md-6 col-12 my-2"
                      >
                        <div v-if="option.type === 'text'">
                          <ejs-textbox
                            :placeholder="dict[option.name][lang]"
                            :value="myData.attribute[option.name]"
                            :enableRtl="lang === 'per'"
                            floatLabelType="Auto"
                            v-model="myData.attribute[option.name]"
                          ></ejs-textbox>
                        </div>
                        <div v-if="option.type === 'array'">
                          <ejs-textbox
                            :placeholder="dict[option.name][lang]"
                            :enableRtl="lang === 'per'"
                            floatLabelType="Auto"
                            @input="splitMajorTicks($event,option.name)"
                            v-model="myData.attribute[option.name]"
                          ></ejs-textbox>
                        </div>
                        <div v-if="option.type === 'number'">
                          <ejs-textbox
                            :placeholder="dict[option.name][lang]"
                            :value="myData.attribute[option.name]"
                            :enableRtl="lang === 'per'"
                            floatLabelType="Auto"
                            v-model="myData.attribute[option.name]"
                          ></ejs-textbox>
                        </div>
                        <div v-if="option.type === 'boolean'">
                          <ejs-dropdownlist
                            :placeholder="dict[option.name][lang]"
                            :enableRtl="lang === 'per'"
                            :dataSource="strokeTicksList"
                            floatLabelType="Auto"
                            :fields="{ text: lang, value: 'value' }"
                            v-model="myData.attribute[option.name]"
                            @change="(val)=>{myData.attribute[option.name]=val.value}"
                          ></ejs-dropdownlist>

                        </div>
                        <div v-if="option.type === 'color'">
                          <ejs-textbox
                            :placeholder="dict[option.name][lang]"
                            :value="myData.attribute[option.name]"
                            :enableRtl="lang === 'per'"
                            floatLabelType="Auto"
                            type="color"
                            v-model="myData.attribute[option.name]"
                          ></ejs-textbox>
                        </div>
                        <div v-if="option.type === 'option'">
                          <ejs-dropdownlist
                            :placeholder="dict[option.name][lang]"
                            :enableRtl="lang === 'per'"
                            :dataSource="option.options"
                            floatLabelType="Auto"
                            :fields="{ text: 'text', value: 'value' }"
                            v-model="myData.attribute[option.name]"
                            @change="(val)=>{myData.attribute[option.name]=val.value}"
                          ></ejs-dropdownlist>

                        </div>
                        <div
                          v-else-if="option.type === 'table'"
                          style="
                            border: 1px solid #ced4da;
                            border-radius: 0.25rem;
                          "
                        >
                          <b-table
                            ref="table2"
                            :items="myData.attribute[option.name]"
                            :fields="option.fields"
                            thead-class="hidden_header"
                            responsive="sm"
                            small
                          >
                            <template #cell(from)="row">
                              <div class="d-flex align-items-center p-2">
                                <input
                                  type="text"
                                  v-model="row.item.from"
                                  class="w-100"
                                />
                              </div>
                            </template>
                            <template #cell(to)="row">
                              <div class="d-flex align-items-center p-2">
                                <input
                                  type="text"
                                  v-model="row.item.to"
                                  class="w-100"
                                />
                              </div>
                            </template>
                            <template #cell(color)="row">
                              <div class="d-flex align-items-center p-2">
                                <input
                                  type="color"
                                  v-model="row.item.color"
                                  class="w-100"
                                />
                              </div>
                            </template>
                          </b-table>
                          <div
                            style="
                              display: flex;
                              justify-content: right;
                              align-items: center;
                            "
                          >
                            <b-button
                              @click="
                                addRow(option.name, {
                                  from: '',
                                  to: '',
                                  color: '#fff',
                                })
                              "
                              size="sm"
                              variant="outline-danger"
                              style="
                                width: 20px;
                                padding: 0px;
                                outline: none !important;
                                border: none;
                                box-shadow: none;
                              "
                            >
                              <i>
                                <font-awesome-icon icon="fas fa-plus" />
                              </i>
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </b-container>
      </div>
    </b-modal>
    <dataPickerModal :plot-id="widgetId" />

  </div>
</template>

<script>
/* eslint-disable */

import dataPickerModal from "./widgetPickDataModal.vue";
import { RadialGauge } from "canvas-gauges";

export default {
  name: "CustomPath",
  components: {
    dataPickerModal

  },
  props: {
    widgetId: String,
    data: Object,
    sseData: Array,
    editable: Boolean,
    resized: Number,
    updateDOM: Number,
    setting: Boolean,
    lang: String,
    theme: String
  },
  data() {
    return {
      activeTab: "data", // Set the initial active tab
      tabList: ["data", "basic", "title", "value", "units", "ticks", "numbers", "animation", "valueBox", "border", "needle", "highlights", "bar", "radial"],
      gauge: null,
      modalTitle: { eng: "Edit Chart", per: "ویرایش چارت" },
      myData: {
        datas: [{ name: "" }],
        attribute: {}
      },
      gaugeOptions: {
        basic: [
          { name: "minValue", type: "number", default: 0 },
          { name: "maxValue", type: "number", default: 100 },
          { name: "animateOnInit", type: "boolean", default: false },
          { name: "colorPlate", type: "color", default: "#ffffff" },
          { name: "colorPlateEnd", type: "color", default: "" }
        ],
        title: [
          { name: "title", type: "text", default: false },
          { name: "colorTitle", type: "color", default: "#888" },
          { name: "fontTitle", type: "option", options: ["Arial", "font2"], default: "Arial" },
          { name: "fontTitleSize", type: "number", default: 24 },
          { name: "fontTitleStyle", type: "option", options: ["bold", "regular", "normal"], default: "normal" },
          { name: "fontTitleWeight", type: "option", options: ["bold", "regular", "normal"], default: "normal" }
        ],
        value: [
          { name: "valueInt", type: "number", default: 3 },
          { name: "valueDec", type: "number", default: 2 },
          { name: "colorValueText", type: "color", default: "#444" },
          { name: "colorValueTextShadow", type: "color", default: "rgba(0,0,0,0.3)" },
          { name: "animatedValue", type: "boolean", default: false },
          { name: "fontValue", type: "option", options: ["Arial", "font2"], default: "Arial" },
          { name: "fontValueSize", type: "number", default: 26 },
          { name: "fontValueStyle", type: "option", options: ["bold", "regular", "normal"], default: "normal" },
          { name: "fontValueWeight", type: "option", options: ["bold", "regular", "normal"], default: "normal" }
        ],
        units: [
          { name: "units", type: "text", default: false },
          { name: "colorUnits", type: "color", default: "#888" },
          { name: "fontUnits", type: "option", options: ["Arial", "font2"], default: "Arial" },
          { name: "fontUnitsSize", type: "number", default: 22 },
          { name: "fontUnitsStyle", type: "option", options: ["bold", "regular", "normal"], default: "normal" },
          { name: "fontUnitsWeight", type: "option", options: ["bold", "regular", "normal"], default: "normal" }
        ],
        ticks: [
          { name: "majorTicks", type: "array", default: ["0", "50", "100"] },
          { name: "exactTicks", type: "boolean", default: false },
          { name: "minorTicks", type: "number", default: 2 },
          { name: "strokeTicks", type: "boolean", default: true },
          { name: "majorTicksInt", type: "number", default: 1 },
          { name: "majorTicksDec", type: "number", default: 0 },
          { name: "colorMajorTicks", type: "color", default: "#444" },
          { name: "colorMinorTicks", type: "color", default: "#666" },
          { name: "colorStrokeTicks", type: "color", default: "" }
        ],
        numbers: [
          { name: "colorNumbers", type: "color", default: "#444" },
          { name: "fontNumbers", type: "option", options: ["Arial", "font2"], default: "Arial" },
          { name: "fontNumbersSize", type: "number", default: 20 },
          { name: "fontNumbersStyle", type: "option", options: ["bold", "regular", "normal"], default: "normal" },
          { name: "fontNumbersWeight", type: "option", options: ["bold", "regular", "normal"], default: "normal" },
          { name: "numbersMargin", type: "number", default: 1 }

        ],
        animation: [
          { name: "animation", type: "boolean", default: true },
          { name: "animationDuration", type: "number", default: 1500 },
          {
            name: "animationRule",
            type: "option",
            options: ["linear", "quad", "dequad", "quint", "dequint", "cycle", "decycle", "bounce", "debounce", "elastic", "delastic"],
            default: "linear"
          }
        ],
        valueBox: [
          { name: "valueBox", type: "boolean", default: true },
          { name: "valueBoxWidth", type: "number", default: 0 },
          { name: "valueBoxStroke", type: "number", default: 5 },
          { name: "valueText", type: "text", default: "" },
          { name: "valueTextShadow", type: "boolean", default: true },
          { name: "valueBoxBorderRadius", type: "number", default: 2.5 },
          { name: "colorValueBoxRect", type: "color", default: "#888" },
          { name: "colorValueBoxRectEnd", type: "color", default: "#666" },
          { name: "colorValueBoxBackground", type: "color", default: "#babab2" },
          { name: "colorValueBoxShadow", type: "color", default: "rgba(0,0,0,1)" }
        ],
        border: [
          { name: "borders", type: "boolean", default: false },
          { name: "colorBorderOuter", type: "color", default: "#ddd" },
          { name: "colorBorderOuterEnd", type: "color", default: "#aaa" },
          { name: "colorBorderMiddle", type: "color", default: "#eee" },
          { name: "colorBorderMiddleEnd", type: "color", default: "#f0f0f0" },
          { name: "colorBorderInner", type: "color", default: "#fafafa" },
          { name: "colorBorderInnerEnd", type: "color", default: "#ccc" },
          { name: "colorBorderShadow", type: "color", default: "rgba(0,0,0,0.5)" },
          { name: "borderOuterWidth", type: "number", default: 0 },
          { name: "borderMiddleWidth", type: "number", default: 0 },
          { name: "borderInnerWidth", type: "number", default: 0 },
          { name: "borderShadowWidth", type: "number", default: 1 }
        ],
        needle: [
          { name: "needle", type: "boolean", default: true },
          { name: "needleShadow", type: "boolean", default: true },
          { name: "needleType", type: "option", options: ["arrow", "line"], default: "arrow" },
          { name: "needleStart", type: "number", default: 20 },
          { name: "needleEnd", type: "number", default: 85 },
          { name: "needleWidth", type: "number", default: 1 },
          { name: "colorNeedle", type: "color", default: "rgba(240,128,128,1)" },
          { name: "colorNeedleEnd", type: "color", default: "rgba(255,160,122,.9)" },
          { name: "colorNeedleShadowUp", type: "color", default: "rgba(2,255,255,0.2)" },
          { name: "colorNeedleShadowDown", type: "color", default: "rgba(188,143,143,0.45)" }
        ],
        highlights: [
          {
            name: "highlights",
            type: "table",
            fields: [{ key: "from", label: "", type: "number" }, {
              key: "to",
              label: "",
              type: "number"
            }, { key: "color", label: "", type: "color" }],
            default: [{ from: 75, to: 100, color: "rgba(200, 50, 50, .75)" }]
          },
          { name: "highlightsWidth", type: "number", default: 15 },
          { name: "highlightsLineCap", type: "option", options: ["butt"], default: "butt" }
        ],
        bar: [
          { name: "barWidth", type: "number", default: 0 },
          { name: "barStrokeWidth", type: "number", default: 0 },
          { name: "barProgress", type: "boolean", default: true },
          { name: "colorBar", type: "color", default: "#ccc" },
          { name: "colorBarStroke", type: "color", default: "#222" },
          { name: "colorBarProgress", type: "color", default: "#888" },
          { name: "colorBarShadow", type: "color", default: "#000" },
          { name: "barShadow", type: "number", default: 0 }
        ],
        radial: [
          { name: "ticksAngle", type: "number", default: 270 },
          { name: "startAngle", type: "number", default: 45 },
          { name: "colorNeedleCircleOuter", type: "color", default: "#f0f0f0" },
          { name: "colorNeedleCircleOuterEnd", type: "color", default: "#ccc" },
          { name: "colorNeedleCircleInner", type: "color", default: "#e8e8e8" },
          { name: "colorNeedleCircleInnerEnd", type: "color", default: "#f5f5f5" },
          { name: "needleCircleSize", type: "number", default: 13 },
          { name: "needleCircleInner", type: "boolean", default: false },
          { name: "needleCircleOuter", type: "boolean", default: true },
          { name: "animationTarget", type: "option", options: ["plate", "needle"], default: "needle" },
          { name: "useMinPath", type: "boolean", default: false },
          { name: "barStartPosition", type: "option", options: ["right", "left"], default: "left" }
        ]
        // linear:[
        //   {name: 'borderRadius', type: "number"},
        //   {name: 'barBeginCircle', type: "number"},
        //   {name: 'tickSide', type: "option", options:['right','left']},
        //   {name: 'needleSide', type: "option", options:['right','left']},
        //   {name: 'numberSide', type: "option", options:['right','left']},
        //   {name: 'ticksWidth', type: "number"},
        //   {name: 'ticksWidthMinor', type: "number"},
        //   {name: 'ticksPadding', type: "number"},
        //   {name: 'barLength', type: "number"},
        //   {name: 'colorBarEnd', type: "color"},
        //   {name: 'colorBarProgressEnd', type: "color"}
        // ]
      },

      myDataTmp: { datas: [] },

      groupFields: [
        { key: "name", label: "" },
        { key: "modify", label: "" }],

      submitted: false,
      successful: false,
      message: "",

      strokeTicksList: [
        { per: "نمایش داده شود", eng: "true", value: true },
        { per: "نمایش داده نشود", eng: "false", value: false }
      ],

      textinfoList: [
        { text: "percent", value: "percent" },
        { text: "value", value: "value" },
        { text: "label and value", value: "label+value" },
        { text: "label and percent", value: "label+percent" },
        { text: "label and percent and value", value: "label+percent+value" }
      ],
      textpositionList: [
        { text: "inside", value: "inside" },
        { text: "outside", value: "outside" },
        { text: "auto", value: "auto" },
        { text: "none", value: "none" }
      ],
      insidetextorientationList: [
        { text: "horizontal", value: "horizontal" },
        { text: "radial", value: "radial" },
        { text: "tangential", value: "tangential" },
        { text: "auto", value: "auto" }
      ],
      holeList: [
        { text: "none", value: "0" },
        { text: "0.2", value: "0.2" },
        { text: "0.4", value: "0.4" },
        { text: "0.6", value: "0.6" }
      ],
      showlegendList: [
        { text: "show", value: "true" },
        { text: "don't show", value: "false" }
      ],
      dict: {
        dir: { eng: "ltr", per: "rtl" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        pickData: { per: "انتخاب داده", eng: "Pick Data" },
        title: { eng: "title", per: "عنوان" },
        data: { eng: "data", per: "داده" },
        basic: { eng: "basic", per: "تنظیمات پایه" },
        value: { eng: "value", per: "تنظیمات مقادیر" },
        ticks: { eng: "ticks", per: "تنظیمات تیک ها" },
        numbers: { eng: "numbers", per: "تنظیمات اعداد" },
        border: { eng: "border", per: "مرزبندی ها" },
        bar: { eng: "bar", per: "تنظیمات نوار" },
        radial: { eng: "radial", per: "تنظیمات محوری" },
        linear: { eng: "linear", per: "تنظیمات طولی" },

        minValue: { eng: "minimum value", per: "مقدار کمینه" },
        maxValue: { eng: "maximum value", per: "مقدار بیشینه" },
        units: { eng: "unit", per: "واحد" },


        majorTicks: { eng: "major ticks", per: "تیک های اصلی" },
        minorTicks: { eng: "number of minor ticks", per: "تعداد تیک های فرعی" },
        strokeTicks: { eng: "stroke ticks", per: "نوار پشت تیک ها" },

        colorPlate: { eng: "color plate", per: "رنگ پس زمینه" },
        colorPlateEnd: { eng: "color plate end", per: "رنگ انتهای صفحه" },
        borderShadowWidth: { eng: "border shadow width", per: "ضخامت سایه حاشیه" },
        borders: { eng: "borders", per: "خطوط حاشیه" },
        needleType: { eng: "needle type", per: "نوع نشانگر" },
        needleWidth: { eng: "needle width", per: "ضخامت نشانگر" },
        needleCircleSize: { eng: "needle circle size", per: "اندازه دایره عقربه" },
        needleCircleOuter: { eng: "needle circle outer", per: "دایره بیرونی عقربه" },
        needleCircleInner: { eng: "needle circle inner", per: "دایره درونی عقربه" },
        animationDuration: { eng: "animation duration", per: "مدت زمان انیمیشن" },
        animationRule: { eng: "animation rule", per: "قانون انیمیشن" },
        animateOnInit: { eng: "animate on init", per: "متحرک سازی در ابتدا" },
        colorTitle: { eng: "colorTitle", per: "رنگ عنوان" },
        fontTitle: { eng: "fontTitle", per: "فونت عنوان" },
        fontTitleSize: { eng: "fontTitleSize", per: "اندازه فونت عنوان" },
        fontTitleStyle: { eng: "fontTitleStyle", per: "سبک عنوان فونت" },
        fontTitleWeight: { eng: "fontTitleWeight", per: "وزن عنوان فونت" },

        valueInt: { eng: "valueInt", per: "مقدار اولیه" },
        valueDec: { eng: "valueDec", per: "مقدار کاهش" },
        colorValueText: { eng: "colorValueText", per: "رنگ متن" },
        colorValueTextShadow: { eng: "colorValueTextShadow", per: "رنگ سایه متن" },
        animatedValue: { eng: "animatedValue", per: "مقدار متحرک" },
        fontValue: { eng: "fontValue", per: "مقدار فونت" },
        fontValueSize: { eng: "fontValueSize", per: "سایز فونت مقدار" },
        fontValueStyle: { eng: "fontValueStyle", per: "سبک فونت مقدار" },
        fontValueWeight: { eng: "fontValueWeight", per: "وزن فونت مقدار" },

        colorUnits: { eng: "colorUnits", per: "واحدهای رنگی" },
        fontUnits: { eng: "fontUnits", per: "واحدهای فونت" },
        fontUnitsSize: { eng: "fontUnitsSize", per: "سایز فونت واحد" },
        fontUnitsStyle: { eng: "fontUnitsStyle", per: "سبک فونت واحد" },
        fontUnitsWeight: { eng: "fontUnitsWeight", per: "وزن فونت واحد" },


        exactTicks: { eng: "exactTicks", per: "نوع انیمیشن" },
        majorTicksInt: { eng: "majorTicksInt", per: "نوع انیمیشن" },
        majorTicksDec: { eng: "majorTicksDec", per: "نوع انیمیشن" },
        colorMajorTicks: { eng: "colorMajorTicks", per: "نوع انیمیشن" },
        colorMinorTicks: { eng: "colorMinorTicks", per: "نوع انیمیشن" },
        colorStrokeTicks: { eng: "colorStrokeTicks", per: "نوع انیمیشن" },

        colorNumbers: { eng: "colorNumbers", per: "رنگ اعداد" },
        fontNumbers: { eng: "fontNumbers", per: "فونت اعداد" },
        fontNumbersSize: { eng: "fontNumbersSize", per: "سایز فونت اعداد" },
        fontNumbersStyle: { eng: "fontNumbersStyle", per: "سبک فونت اعداد" },
        fontNumbersWeight: { eng: "fontNumbersWeight", per: "وزن فونت اعداد" },
        numbersMargin: { eng: "numbersMargin", per: "فواصل اعداد" },


        animation: { eng: "animation", per: "متحرک سازی" },


        valueBox: { eng: "valueBox", per: "" },
        valueBoxWidth: { eng: "valueBoxWidth", per: "نوع انیمیشن" },
        valueBoxStroke: { eng: "valueBoxStroke", per: "نوع انیمیشن" },
        valueText: { eng: "valueText", per: "نوع انیمیشن" },
        valueTextShadow: { eng: "valueTextShadow", per: "نوع انیمیشن" },
        valueBoxBorderRadius: { eng: "valueBoxBorderRadius", per: "نوع انیمیشن" },
        colorValueBoxRect: { eng: "colorValueBoxRect", per: "نوع انیمیشن" },
        colorValueBoxRectEnd: { eng: "colorValueBoxRectEnd", per: "نوع انیمیشن" },
        colorValueBoxBackground: { eng: "colorValueBoxBackground", per: "نوع انیمیشن" },
        colorValueBoxShadow: { eng: "colorValueBoxShadow", per: "نوع انیمیشن" },

        colorBorderOuter: { eng: "colorBorderOuter", per: "رنگ حاشیه بیرونی" },
        colorBorderOuterEnd: { eng: "colorBorderOuterEnd", per: "رنگ حاشیه بیرونی انتهایی" },
        colorBorderMiddle: { eng: "colorBorderMiddle", per: "رنگ حاشیه میانی" },
        colorBorderMiddleEnd: { eng: "colorBorderMiddleEnd", per: "رنگ انتهایی حاشیه میانی" },
        colorBorderInner: { eng: "colorBorderInner", per: "رنگ حاشیه داخلی" },
        colorBorderInnerEnd: { eng: "colorBorderInnerEnd", per: "رنگ حاشیه داخلی انتهایی" },
        colorBorderShadow: { eng: "colorBorderShadow", per: "رنگ سایه حاشیه" },
        borderOuterWidth: { eng: "borderOuterWidth", per: "ضخامت حاشیه بیرونی" },
        borderMiddleWidth: { eng: "borderMiddleWidth", per: "ضخامت حاشیه میانی" },
        borderInnerWidth: { eng: "borderInnerWidth", per: "ضخامت حاشیه درونی" },

        needle: { eng: "needle", per: "تنظیمات عقربه" },
        needleShadow: { eng: "needleShadow", per: "سایه عقربه" },
        needleStart: { eng: "needleStart", per: "ابتدای عقربه" },
        needleEnd: { eng: "needleEnd", per: "انتهای عقربه" },
        colorNeedle: { eng: "colorNeedle", per: "رنگ عقربه" },
        colorNeedleEnd: { eng: "colorNeedleEnd", per: "رنگ انتهاب عقربه" },
        colorNeedleShadowUp: { eng: "colorNeedleShadowUp", per: "رنگ سایه بالای عقربه" },
        colorNeedleShadowDown: { eng: "colorNeedleShadowDown", per: "رنگ سایه پایین عقربه" },

        highlights: { eng: "highlights", per: "تنظیمات هایلایت ها" },
        highlightsWidth: { eng: "highlightsWidth", per: "ضخامت هایلایت" },
        highlightsLineCap: { eng: "highlightsLineCap", per: "فاصله خطوط هایلایت" },

        barWidth: { eng: "barWidth", per: "عرض نوار" },
        barStrokeWidth: { eng: "barStrokeWidth", per: "عرض خطوط نوار" },
        barProgress: { eng: "barProgress", per: "نوار پیشرفت" },
        colorBar: { eng: "colorBar", per: "رنگ نوار" },
        colorBarStroke: { eng: "colorBarStroke", per: "رنگ خطوط نوار" },
        colorBarProgress: { eng: "colorBarProgress", per: "رنگ خطوط نوار پیشرفت" },
        colorBarShadow: { eng: "colorBarShadow", per: "رنگ سایه نوار" },
        barShadow: { eng: "barShadow", per: "سایه نوار" },

        ticksAngle: { eng: "ticksAngle", per: "زاویه تیک" },
        startAngle: { eng: "startAngle", per: "زاویه شروع" },
        colorNeedleCircleOuter: { eng: "colorNeedleCircleOuter", per: "رنگ سوزن دایره بیرونی " },
        colorNeedleCircleOuterEnd: { eng: "colorNeedleCircleOuterEnd", per: "رنگ انتهای سوزن دایره بیرونی" },
        colorNeedleCircleInner: { eng: "colorNeedleCircleInner", per: "رنگ سوزن دایره درونی" },
        colorNeedleCircleInnerEnd: { eng: "colorNeedleCircleInnerEnd", per: "رنگ انتهای سوزن دایره درونی" },
        animationTarget: { eng: "animationTarget", per: "نوع انیمیشن" },
        useMinPath: { eng: "useMinPath", per: "نوع انیمیشن" },
        barStartPosition: { eng: "barStartPosition", per: "موقعیت شروع نوار" },


        mode: { eng: "chart mode", per: "نوع ترسیم" },
        shape: { eng: "chart shape", per: "نحوه ترسیم" },
        dash: { eng: "chart dash", per: "نوع خط" },
        grid: { eng: "grid color", per: "رنگ گرید" },
        line: { eng: "line color", per: "رنگ خطوط" },
        font: { eng: "font color", per: "رنگ نوشتار" },
        textinfo: { eng: "text info", per: "رنگ نوشتار" },
        textposition: { eng: "text position", per: "رنگ نوشتار" },
        insidetextorientation: { eng: "inside text orientation", per: "رنگ نوشتار" },
        hole: { eng: "hole size", per: "رنگ نوشتار" },
        showlegend: { eng: "legend", per: "رنگ نوشتار" }
      }
    };
  },
  computed: {
    updatedData() {
      return this.$store.state.panel.data;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    getSSeData(){
      const a =this.updateDOM
      return this.sseData
    }
  },
  watch: {
    getSSeData: {
      handler(newValue) {
        for (var Data of newValue) {
          if ((this.myData.datas[0]) && (JSON.stringify(Data.id) === JSON.stringify(this.myData.datas[0]._id))) {
            if (Data.value != "") {
              this.myData.datas[0].value = parseFloat(Data.value);
              this.gauge.update({ value: this.myData.datas[0].value });
            } else {
              this.gauge.update({ value: 0 });
            }
          }
        }
      }, deep: true,
    },
    child2parent(newValue) {
      if ((newValue.task === "pickData") && (newValue.to === this.widgetId)) {
        if (newValue.item.type === "data") {
          this.myData.datas = [{ ...newValue.item, value: 0 }];
        }
      }
    },
    resized() {
      this.resizeChart();
    },
    setting(newValue) {
      if (newValue) {
        this.$root.$emit("bv::show::modal", this.widgetId);
      }
    },
    data(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        if (newValue) {
          this.myData = JSON.parse(JSON.stringify(newValue));
          // this.$refs.table1.refresh();
          this.selChart();
        }
      }
    }
  },
  methods: {
    splitMajorTicks(e, option) {
      if (typeof e.value === "string") {
        this.myData.attribute[option] = e.value;
      }
    },
    addRow(option, data) {
      this.myData.attribute[option].push(data);
    },
    selChart() {
      var options = {
        renderTo: this.$refs.gauge,
        width: 0,
        height: 0
      };

      Object.keys(this.gaugeOptions).forEach(key => {
        this.gaugeOptions[key].forEach(opt => {
          if (typeof this.myData.attribute[opt.name] !== "undefined") {
            if (opt.type === "number")
              options[opt.name] = parseFloat(this.myData.attribute[opt.name]);
            else
              options[opt.name] = this.myData.attribute[opt.name];

          } else {
            options[opt.name] = opt.default;
          }
        });
      });

      this.myData.attribute = JSON.parse(JSON.stringify(options));

      this.gauge = new RadialGauge(options).draw();

      setTimeout(() => {
        this.resizeChart();
      }, "100");

    },

    initModal: function() {
      this.myDataTmp = JSON.parse(JSON.stringify(this.myData));
    },
    resetModal: function() {
      this.myData = JSON.parse(JSON.stringify(this.myDataTmp));

      this.submitted = null;

      this.successful = null;
      this.message = null;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {
          if (typeof this.myData.attribute["majorTicks"] == "string") {
            this.myData.attribute["majorTicks"] = this.myData.attribute["majorTicks"].split(",");
          }
          this.$store.dispatch("data/editDashboard", { _id: this.widgetId, data: this.myData }).then(
            data => {
              this.$store.dispatch("chpge/child2parent", { task: "updateDash" });
              this.myDataTmp = JSON.parse(JSON.stringify(this.myData));
              this.$nextTick(() => {
                this.$bvModal.hide(this.widgetId);
              });
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
            }
          );
        }
      });
    },
    addItem: function(type) {
      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: this.widgetId,
        filter: {
          dataType: ["Number", "Object", "!complex"],
          usedBy: [],
          parents: [],
          archive: null,
          withChild: false,
          perPage: 5000,
          page: 1
        }
      });
      this.$root.$emit("bv::show::modal", "modal-widget-pick-data-" + this.widgetId);
    },
    removeItem: function(index) {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete this group")
        .then(value => {
          if (value === true) {
            this.myData.datas.splice(index, 1);
          }
        });
    },

    resizeChart() {
      const divElement = this.$refs.mainCont;
      if (divElement) {
        this.myData.attribute.height = divElement.clientHeight;
        this.myData.attribute.width = divElement.clientWidth;

        this.gauge.update({
          width: this.myData.attribute.width,
          height: this.myData.attribute.height
        });
      }
    }
  },

  mounted() {
    if (this.data && this.data.datas && this.data.attribute) {
      this.myData = this.data;
      if ("datas" in this.data) {
        let idList = [];
        this.data.datas.forEach((item) => {
          idList.push(item._id);
        });

        this.$emit("sseData", {
          id: this.widgetId,
          list: idList
        });
      }
    }
    this.selChart();
  }
};
</script>


<style scoped>
.remove {
  cursor: pointer;
  color: rgb(175, 235, 11);
  font-size: 18px;
}

.edit {
  cursor: pointer;
  color: rgb(9, 180, 210);
  font-size: 18px;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-100%);

}


</style>

<style>
.my-body-class {
  padding: 0px !important;
  /* Add any other custom styles you want */
}
</style>


