<template>
  <div
    :dir="dict.dir[lang]"
    class="container-fluid p-0 h-auto bg-none yekan"
    :class="lang === 'per' ? 'text-end' : 'text-start'"
    style="height: calc(100vh - 60px)"
  >
    <div class="p-3 m-0 bg-page-title d-flex">
      <div
        :class="
          dict.dir[lang] === 'rtl'
            ? 'page-title-icon-rtl'
            : 'page-title-icon-ltr'
        "
      >
        <img
          src="@/assets/icons/accounts-1.png"
          alt="dashboard"
          style="width: 60px"
        />
      </div>

      <div class="d-flex align-items-center">
        <h5 class="mb-0">{{ dict.title[lang] }}</h5>
      </div>
    </div>
    <div class="col-lg-12 h-100">
      <div
        class="card h-100"
        style="background-color: rgba(255, 255, 255, 0.75) !important"
      >
        <div class="p-3">
          <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block"
          >
            <li
              v-for="(item, index) in tabs"
              class="mga-tab-nav-item"
              @click="activeTab = item.value"
              :key="index"
            >
              <a
                class="mga-tab-nav-link"
                :class="{ active: activeTab === item.value }"
                :id="item.name"
                data-toggle="tab"
                role="tab"
                aria-controls="tab1"
                aria-selected="true"
              >
                {{ dict[item.name][lang] }}
              </a>
            </li>
          </ul>
        </div>
        <div class="card-body h-100" style="overflow: hidden">
          <transition name="slide-fade" mode="out-in">
            <div class="tab-content h-100" :key="activeTab">
              <div v-if="activeTab === 'licence'">
                <!--                <workbench-administrator :is-list="true" v-if="activeTab === 'licence'" :licence="true" />-->
                <licence-manager :key="reRender" ref="lic" v-if="activeTab === 'licence'" />
              </div>
              <div v-if="activeTab === 'workbench'" class="h-100">
                <workbench-administrator
                  :key="reRender"
                  :is-list="true"
                  v-if="activeTab === 'workbench'"
                />
              </div>
              <div v-if="activeTab === 'users'">
                <div v-if="activeTab === 'users'">
                  <users-administrator
                    :key="reRender"
                    :can-edit="true"
                    v-if="activeTab == 'users'"
                  />
                </div>
              </div>
              <div v-if="activeTab === 'authentication'">
                <div>
                  <connections :key="reRender" />
                </div>
              </div>
              <div v-if="activeTab === 'backUpManager'">
                <div class="d-flex gap-3 col-md-3 col-12">
                  <b-button @click="downloadBackup" variant="primary"
                  >{{ dict.downloadBackup[lang] }}
                  </b-button>
                  <b-button
                    @click="$bvModal.show('modal-upload-backup')"
                    variant="primary"
                  >{{ dict.backup[lang] }}
                  </b-button>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-upload-backup"
      size="md"
      centered
      hide-header-close
      no-close-on-backdrop
      header-border-variant="primary"
      header-class="my-modal-header "
      @hidden="resetModal"
      :title="dict.backup[lang]"
      :dir="dict.dir[lang]"
    >
      <template #modal-footer>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-end">
          <div class="d-flex" style="gap: 10px">
            <b-button variant="secondary" @click="resetModal"
            >{{ dict.cancel[lang] }}
            </b-button>
            <b-button variant="primary" @click="uploadBackup"
            >{{ dict.ok[lang] }}
            </b-button>
          </div>
        </div>
      </template>

      <div id="validMe" :dir="dict.dir[lang]" class="form-group mb-2">
        <!--      <label class="form-label" for="indoorMap">{{-->
        <!--          dict.indoorMap[lang]-->
        <!--        }}</label>-->
        <div class="d-flex justify-content-between align-items-center">
          <ejs-uploader
            ref="indoorMap"
            id="indoorMap"
            :enableRtl="lang == 'per'"
            @selected="getFile"
            :multiple="false"
            allowedExtensions=".enc"
          ></ejs-uploader>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import store from "@/store";
import WorkbenchAdministrator from "@/components/workbenchAdministrator.vue";
import UsersAdministrator from "@/components/usersAdministrator.vue";
import LicenceManager from "@/components/licenceManager.vue";
import Connections from "@/components/connections.vue";
import { persianLacale } from "@/lacale/persian";
import status from "@/lacale/statusCode.json";
import { ToastUtility } from "@syncfusion/ej2-vue-notifications";

export default {
  name: "adminPanel",
  components: {
    LicenceManager,
    Connections,
    UsersAdministrator,
    WorkbenchAdministrator
  },
  data() {
    return {
      reRender: 0,
      file: "",
      activeTab: "licence",
      active: "users",
      dict: {
        dir: { eng: "ltr", per: "rtl" },
        success: {
          per: "فایل موردنظر با موفقیت بارگذاری شد.",
          eng: "file uploaded successfully"
        },
        error: {
          per: "فایل بارگذاری نشد.",
          eng: "record not uploaded"
        },
        backup: { eng: "upload backup file", per: "بارگذاری فایل پشتیبانی" },
        downloadBackup: {
          eng: "download backup file",
          per: "بارگیری فایل پشتیبانی"
        },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        th_floorTitle: { eng: "name", per: "نام" },
        validName: { eng: "name is required!", per: "ورود نام الزامی است!" },
        search: { eng: "Type to Search", per: "برای جستجو تایپ کنید" },
        clear: { eng: "clear", per: "حذف" },
        title: { eng: "admin panel", per: "پنل مدیریت" },
        tab1: { eng: "licence manager", per: "مدیریت منابع" },
        tab2: { eng: "workbench managment", per: "مدیریت میزکار" },
        tab3: { eng: "users management", per: "مدیریت کاربران" },
        tab4: { eng: "authentication", per: "احراز هویت" },
        tab5: { eng: "backUp manager", per: "مدیریت پشتیبان گیری" },
        wbname: { eng: "workbench name", per: "نام میزکار" },
        wbowner: { eng: "workbench owner", per: "مالک میزکار" },
        wbresources: { eng: "workbench resources", per: "منابع میزکار" },

        regmsg: { eng: "Don't have an account?", per: "حساب کاربری ندارید؟" },
        register: { eng: "Register now", per: "ثبت نام" },
        errCaptcha: {
          eng: "invalid captcha",
          per: "کد امنیتی وارد شده اشتباه می باشد"
        },
        errUserPass1: {
          eng: "invalid username or password",
          per: "نام کاربری یا کلمه عبور اشتباه است"
        },
        errUserPass2: {
          eng: "check your keyboard language",
          per: "• وضعيت زبان(En/FA)سيستم خود را كنترل كنيد"
        },
        errUserPass3: {
          eng: "the password is caps sensitive",
          per: "• پسورد به حروف بزرگ و كوچك حساس است"
        }
      },
      tabs: [
        { name: "tab1", value: "licence" },
        { name: "tab2", value: "workbench" },
        { name: "tab3", value: "users" },
        { name: "tab4", value: "authentication" },
        { name: "tab5", value: "backUpManager" }
      ],
      aggregateTypes: []
    };
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    },
    wb() {
      return this.$store.state.data.workbench;
    }
  },
  watch: {
    active(val) {
      if (val == "users") this.getAdminUsers();
    },
    "wb": {
      handler(val) {
        this.reRender += 1;
        this.getWorkbenches();
        this.getAdminUsers();
        this.$store.dispatch("data/getWBListdata");
        this.$store.dispatch("data/sendDataForSSE", { id: [] ,action: "add"});
      }, deep: true
    }
  },
  methods: {
    downloadBackup() {
      this.$store.dispatch("data/getBackup").then((res) => {
        const blob = new Blob([res]);
        const href = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "file.enc");
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    },
    uploadBackup() {
      const formData = new FormData();
      formData.append("file", this.file);
      if (this.file != "")
        this.$store
          .dispatch("data/restoreBackup", formData)
          .then(() => {
            ToastUtility.show({
              content: this.dict.success[this.lang],
              timeOut: 3000,
              cssClass: "e-toast-success", // Add a custom class for Error styling
              position: { X: "Right", Y: "Bottom" }, // Adjust the position
              showCloseButton: true,
              enableRtl: this.lang == "per" ? true : false
            });
            location.reload();
            this.$store.dispatch("panel/closeSse");
            this.$store.dispatch("auth/logout");
            this.$store.dispatch("data/removeUserData");
            this.$store.dispatch("chpge/changePage", "signin");
            this.$bvModal.hide("modal-upload-backup");
          })
          .catch(() => {
            ToastUtility.show({
              content: this.dict.error[this.lang],
              timeOut: 3000,
              cssClass: "e-toast-danger",
              position: { X: "Right", Y: "Bottom" },
              showCloseButton: true,
              enableRtl: this.lang == "per" ? true : false
            });
          });
    },
    getAdminUsers() {
      this.$store.dispatch("data/getUsersAdminListdata");
    },
    getWorkbenches() {
      this.$store.dispatch("data/getWBListdata");
    },
    resetModal() {
      this.file = "";
      this.$bvModal.hide("modal-upload-backup");
    },
    getFile(e) {
      this.file = e.filesData[0].rawFile;
    }
  },
  mounted() {
    this.getWorkbenches();
    this.getAdminUsers();
    this.$store.dispatch("data/getWBListdata");
    this.$store.dispatch("data/sendDataForSSE", { id: [],action: "add" });
  }
};
</script>
