<template>
  <div
    :dir="dict.dir[lang]"
    class="container-fluid p-0 bg-none yekan"
    :class="lang === 'per' ? 'text-end' : 'text-start'"
    style="height: calc(100vh - 60px)"
  >
    <div class="p-3 m-0 bg-page-title d-flex">
      <div
        :class="
          dict.dir[lang] === 'rtl'
            ? 'page-title-icon-rtl'
            : 'page-title-icon-ltr'
        "
      >
        <img
          src="@/assets/icons/workbench_manager-2.png"
          alt="dashboard"
          style="width: 60px"
        />
      </div>

      <div class="d-flex align-items-center">
        <h5 class="mb-0">{{ dict.title[lang] }}</h5>
      </div>
    </div>

    <div
      class="row g-0 p-2"
      :class="lang === 'per' ? 'text-end' : 'text-start'"
      style="height: calc(100vh - 152px)"
    >
      <div class="col-lg-12 h-100">
        <div
          class="card"
          :class="{'h-100':isMoblieMode>1024}"
          style="background-color: rgba(255, 255, 255, 0.75) !important"
        >
          <div class="card-header">
            <div class="p-3">
              <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
                <li
                  v-for="(item, index) in tabs"
                  class="mga-tab-nav-item"
                  @click="activeTab = item.value"
                  :key="index"
                >
                  <a
                    class="mga-tab-nav-link"
                    :class="{ active: activeTab === item.value }"
                    :id="item.name"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="tab1"
                    aria-selected="true"
                  >
                    {{ dict[item.name][lang] }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body h-100" style="overflow: hidden">
            <transition name="slide-fade" mode="out-in">
              <div class="tab-content h-100" :key="activeTab">
                <div v-if="activeTab === 'tab1'" class="h-100">
                  <div fluid class="container-fluid yekan p-0 h-100">
                    <div class="row h-100">
                      <div class="col-lg-5 col-md-4 col-sm-12 h-100">
                        <div>
                          <div
                            class="
                              d-flex
                              flex-column
                              justify-content-center
                              align-items-center
                            "
                          >
                            <b-avatar
                              :src="avatar"
                              :text="`${
                                workbench.label ? workbench.label[0] : ''
                              } ${workbench.label ? workbench.label[0] : ''}`"
                              size="200px"
                              class="
                                rounded-circle
                                shadow-10
                                bg-alternate
                                avatar
                              "
                            ></b-avatar>

                            <div
                              class="
                                d-flex
                                mb-3 mt-2
                                justify-content-evenly
                                align-items-center
                              "
                              style="width: 30%"
                            >
                              <span
                                @click="openUploadDialog"
                                v-permission="'wb_edit_avatar'"
                                class="e-icons e-edit-6"
                              ></span>
                              <span
                                @click="removeAvatar"
                                v-permission="'wb_remove_avatar'"
                                class="e-icons e-delete-1 text-danger"
                              ></span>
                            </div>
                          </div>
                          <input
                            type="file"
                            ref="avatarInput"
                            style="display: none"
                            @change="uploadAvatar"
                          />
                        </div>

                        <div
                          class="form-outline p-2 mb-2 border rounded"
                          :class="lang === 'per' ? 'text-end' : 'text-start'"
                          style="
                            background-color: #eef2ff;
                            border-color: #a5b4fc !important;
                          "
                        >
                          <label
                            class="form-label"
                            for="username"
                            style="margin-left: 0px"
                          >{{ dict.wbname[lang] }}</label
                          >
                          <div class="d-flex mb-3">
                            <ejs-textbox
                              :autocomplete="'off'"
                              id="filter-input_connection"
                              v-model="workbench.label"
                              :showClearButton="true"
                              :enabled="editname"
                            ></ejs-textbox>
                            <span
                              v-if="!editname"
                              v-permission="'wb_edit_name'"
                              @click="editWorkbenchLabel"
                              class="e-icons e-edit-6 mt-4 mx-3"
                            ></span>
                            <span
                              v-if="editname"
                              @click="applyWorkbenchLabel"
                              class="e-icons mx-3 text-success e-check mt-4"
                            ></span>
                            <span
                              v-if="editname"
                              @click="cancleWorkbenchLabel"
                              class="e-icons e-close text-danger mt-4"
                            ></span>
                          </div>
                          <div
                            v-if="submitted && errors.has('username')"
                            class="alert alert-danger"
                            role="alert"
                          >
                            Username is required!
                          </div>
                        </div>

                        <div
                          class="form-outline p-2 mb-2 border rounded"
                          :class="lang === 'per' ? 'text-end' : 'text-start'"
                          style="
                            background-color: #eef2ff;
                            border-color: #a5b4fc !important;
                          "
                        >
                          <label
                            class="form-label"
                            for="username"
                            style="margin-left: 0px"
                          >{{ dict.wbowner[lang] }}</label
                          >
                          <div class="d-flex mb-3">
                            <ejs-dropdownlist
                              id="dropdownlist_permission"
                              :dataSource="creatorList"
                              :fields="{ text: 'text', value: 'value' }"
                              v-model="workbench.creator.id"
                              :enabled="editowner"
                            ></ejs-dropdownlist>
                            <span
                              v-permission="'wb_edit_owner'"
                              v-if="!editowner"
                              @click="editWorkbenchOwner"
                              class="e-icons e-edit-6 mt-3 mx-3"
                            ></span>
                            <span
                              v-if="editowner"
                              @click="applyWorkbenchOwner"
                              class="e-icons mx-3 e-check text-success mt-3"
                            ></span>
                            <span
                              v-if="editowner"
                              @click="cancelWorkbenchOwner"
                              class="e-icons e-close text-danger mt-3"
                            ></span>
                          </div>
                          <div
                            v-if="submitted && errors.has('username')"
                            class="alert alert-danger"
                            role="alert"
                          >
                            Username is required!
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-lg-7 col-md col-sm-12 h-100"
                        style="overflow-y: scroll"
                      >
                        <div
                          class="
                            form-outline
                            p-2
                            border
                            rounded
                            overflow-y-scroll
                          "
                          :class="lang === 'per' ? 'text-end' : 'text-start'"
                          style="
                            background-color: #eef2ff;
                            border-color: #a5b4fc !important;
                          "
                        >
                          <label
                            class="form-label"
                            for="username"
                            style="margin-left: 0px"
                          >{{ dict.wbresources[lang] }}</label
                          >
                          <wb-licence />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="activeTab === 'tab2'">
                  <b-container fluid class="p-0 h-100">
                    <ejs-grid
                      ref="wb_manager"
                      :width="'auto'"
                      :allowSorting="true"
                      :locale="lang"
                      :key="reRender"
                      :allowExcelExport="true"
                      :allowFiltering="true"
                      :editSettings="statTableOptions.editSettings"
                      :allowPdfExport="true"
                      :toolbar="toolbarGenerator"
                      :pdfExportComplete="pdfExportComplete"
                      :toolbarClick="toolbarClick"
                      :actionBegin="actionBegin"
                      :actionComplete="actionComplete"
                      :allowPaging="true"
                      :enableAdaptiveUI="true"
                      :enableRtl="lang == 'per'"
                      :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
                      :selectionSettings="statTableOptions.selectionOptions"
                      :dataSource="workbench.users"
                    >
                      <e-columns>
                        <e-column width="50" type="checkbox"></e-column>
                        <e-column
                          v-for="(item, index) in usersFields"
                          :key="index"
                          :field="item.key"
                          :width="150"
                          :headerText="item[lang]"
                          :template="item.hasTemplate ? item.key : null"
                        ></e-column>
                      </e-columns>
                      <template v-slot:avatar="{ data }">
                        <div>
                          <b-avatar
                            variant="info"
                            :src="avatarUrl[data.id]"
                            :data-b="getAvatar(data.id)"
                            :text="`${data.fname ? data.fname[0] : ''} ${
                              data.lname ? data.lname[0] : ''
                            }`"
                            style="background-color: #0d6efd;color: white"
                          ></b-avatar>
                        </div>
                      </template>
                      <template v-slot:name="{ data }">
                        {{
                          `${data.fname ? data.fname : ""} ${
                            data.lname ? data.lname : ""
                          }`
                        }}
                      </template>
                      <template v-slot:modify="{ data }">
                        <div>
                          <span
                            v-permission="'wb_remove_user'"
                            v-if="data.status !== 'creator'"
                            style="font-size: 17px"
                            class="e-icons e-delete-1 text-danger"
                            @click="removeUser(data.id)"
                          ></span>
                        </div>
                      </template>
                      <template v-slot:permission="{ data }">
                        <div>
                          <ejs-dropdownlist
                            id="dropdownlist_permission"
                            :dataSource="permissionList"
                            :fields="{ text: 'text', value: 'value' }"
                            v-model="data.permission.id"
                            @select="changeUserPermission(data.id, $event)"
                            :enabled="data.status != 'creator'"
                          ></ejs-dropdownlist>
                        </div>
                      </template>
                      <template v-slot:status="{ data }">
                        <p>{{ translateStatus[data.status][lang] }}</p>
                      </template>
                    </ejs-grid>
                  </b-container>
                </div>
                <div v-if="activeTab === 'tab4'">
                  <role-manager :key="reRender" v-if="activeTab === 'tab4'" />
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-text-coppied" hide-footer hide-header centered size="sm">
      <div class="my-modal-text">Message copied to clipboard!</div>
    </b-modal>

    <inviteUser />
  </div>
</template>

<script>
/* eslint-disable */
import inviteUser from "./workbenchModal.vue";
import { host } from "../models/host";
import RoleManager from "@/components/roleManager.vue";
import WbLicence from "@/components/wbLicences.vue";
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";
import { DialogUtility } from "@syncfusion/ej2-popups";
import { mapGetters } from "vuex";
// import jsonItemEditor  from './setContModalPubSubJson.vue';
// import statJsonItemEditor  from './setContMasterModalStatusJson.vue';
export default {
  name: "masterEdit",
  components: {
    WbLicence,
    RoleManager,
    inviteUser
  },
  data() {
    return {
      reRender: 0,
      avatar: "",
      avatarUrl: {},
      roles: [],
      translateStatus: {
        creator: { eng: "workbench creator", per: "سازنده میزکار" },
        invited: { per: "دعوت شده", eng: "invited" },
        owner: { per: "مالک", eng: "owner" },
        waiting: { per: "در انتظار تایید", eng: "waiting" }
      },
      activeTab: "tab1",
      tabs: [
        { name: "tab1", value: "tab1" },
        { name: "tab2", value: "tab2" },
        { name: "tab4", value: "tab4" }
      ],
      id: "",
      name: "",
      parent: "",
      title: "",
      registred: true,
      mapType: null,

      editname: false,
      editowner: false,
      workbenchLabel: "",
      workbenchOwner: {},

      usersFields: [
        // { key: '_id', label: 'id', sortable: true},
        { key: "avatar", per: "آواتار", eng: "avatar", hasTemplate: true },
        { key: "name", per: "نام", eng: "name", hasTemplate: true },
        { key: "username", per: "نام کاربری", eng: "username", hasTemplate: false },
        { key: "permission", per: "سطح دسترسی", eng: "permission", hasTemplate: true },
        { key: "status", per: "وضعیت", eng: "status", hasTemplate: true },
        { key: "modify", per: "", eng: "", hasTemplate: true }
      ],
      permissionList: [],

      submitted: false,
      successful: false,
      message: "",

      statTableOptions: {
        selectionOptions: {
          type: "Multiple",
          persistSelection: false,
          checkboxOnly: true
        },
        editSettings: {
          allowEditing: false,
          allowAdding: true,
          allowDeleting: true,
          mode: "Dialog"
        },
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "topic",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },

      dict: {
        refresh: { eng: "refresh datas", per: "به روزرسانی اطلاعات" },
        deleteConfirm: { eng: "Confirmation Delete", per: "تاییدیه حذف" },
        deleteConfirmContent: {
          eng: "Are you sure to remove the user from this workbench ?",
          per: "آیا از حذف این کاربر از این میزکار اطمینان دارید ؟"
        },
        roleConfirm: { eng: "Confirmation", per: "تاییدیه" },
        roleConfirmContent: {
          eng: "Are you sure to change the role ?",
          per: "آیا از تغییر این نقش برای کاربر اطمینان دارید ؟"
        },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        dir: { eng: "ltr", per: "rtl" },
        title: { eng: "workbench settings", per: "تنظیمات میزکار" },
        inviteUser: { eng: "invite user", per: "دعوت کاربر به میزکار" },
        tab1: { eng: "workbench properties", per: "مشخصات میزکار" },
        tab2: { eng: "users managment", per: "مدیریت کاربران" },
        tab3: { eng: "license management", per: "مدیریت لایسنس ها" },
        tab4: { eng: "roles management", per: "مدیریت نقش ها" },
        wbname: { eng: "workbench name", per: "نام میزکار" },
        wbowner: { eng: "workbench owner", per: "مالک میزکار" },
        wbresources: { eng: "workbench resources", per: "منابع میزکار" },
        regmsg: { eng: "Don't have an account?", per: "حساب کاربری ندارید؟" },
        register: { eng: "Register now", per: "ثبت نام" },
        errCaptcha: { eng: "invalid captcha", per: "کد امنیتی وارد شده اشتباه می باشد" },
        errUserPass1: { eng: "invalid username or password", per: "نام کاربری یا کلمه عبور اشتباه است" },
        errUserPass2: { eng: "check your keyboard language", per: "• وضعيت زبان(En/FA)سيستم خود را كنترل كنيد" },
        errUserPass3: { eng: "the password is caps sensitive", per: "• پسورد به حروف بزرگ و كوچك حساس است" }
      },
      refreshDrp: 0
    };
  },
  watch: {
    activeTab(val) {
      if (val == "tab2")
        this.getRoles();
      if (val == "tab1")
        this.getWbAvatar();
    },
    // workbench: {
    //   handler(val) {
    //     val.users.forEach((item) => {
    //       this.getAvatar(item.id);
    //     });
    //   }, deep: true
    // }
    workbench: {
      handler(v) {
        this.reRender += 1;
        this.getRoles();
        this.$store.dispatch("data/getUserData");
      }
    }
  },
  computed: {
    isMoblieMode() {
      return this.$store.state.chpge.screenWidth;
    },
    workbench() {
      return this.$store.state.data.workbench.activeWorkbench;
    },
    creatorList() {
      if (this.workbench.users.length > 0) {
        var admins = this.workbench.users.filter(x => (x.permission.name === "admin"));
        var adminList = [];
        admins.forEach(x => adminList.push({ text: x.fname, value: x.id }));
        return adminList;
      }
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    user() {
      return this.$store.state.data.user;
    },
    permissionUser() {
      return this.$store.state.data.user.permissions;
    },
    toolbarGenerator() {
      if (this.permissionUser.includes("wb_invite_user")) {
        return ["Search", {
          text: this.dict.inviteUser[this.lang],
          id: "inv_user",
          prefixIcon: "e-icons e-add"
        },
          {
            text: this.dict.refresh[this.lang],
            id: "refresh_table",
            prefixIcon: "e-icons e-refresh-2"
          }];
      } else return ["Search", {
        text: this.dict.refresh[this.lang],
        id: "refresh_table",
        prefixIcon: "e-icons e-refresh-2"
      }];
    }

  },
  methods: {

    getWbAvatar() {
      this.$store
        .dispatch("data/getWbAvatar", { id: this.workbench.id })
        .then((response) => {
          if (response != null) {
            this.avatar = response;
          }
        });
    },
    actionBegin(args) {
      if (args.requestType != "paging") {
        args.cancel = true;
        if (args.requestType == "add") {
          this.inviteUser();
        }
      }
    },
    actionComplete(args) {
    },
    toolbarClick(args) {
      if (args.item.id.includes("pdfexport")) {
        this.$refs.wb_admin.showSpinner();
        this.$refs.wb_admin.pdfExport();
      } else if (args.item.id.includes("excelexport")) {
        this.$refs.wb_admin.excelExport();
      } else if (args.item.id.includes("csvexport")) {
        this.$refs.wb_admin.csvExport();
      } else if (args.item.id.includes("inv_user")) {
        this.inviteUser();
      } else if (args.item.id.includes("refresh_table")) {
        this.getRoles();
        this.getWbAvatar();
        this.$store.dispatch("data/getWBdata");
      }
    },
    pdfExportComplete() {
      this.$refs.wb_manager.hideSpinner();
    },
    host() {
      return host;
    },
    openUploadDialog() {
      this.$refs.avatarInput.click();
    },
    uploadAvatar(event) {
      const file = event.target.files[0];
      if (file) {
        // Create a FormData object to send the file
        const formData = new FormData();
        formData.append("avatar", file);
        formData.append("task", "ulavatar");
        formData.append("id", this.workbench.id);

        this.$store.dispatch("data/editWBProfile", formData).then(
          Data => {
            this.successful = true;
            this.message = Data;
            // this.$store.dispatch('chpge/child2parent', {task: 'updateCB', data: this.updateCBCnt+1})

          },

          error => {
            this.message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            this.successful = false;
            // alert(this.message)
          }
        );
      }
    },
    removeAvatar() {
      const formData = new FormData();
      formData.append("task", "rmavatar");
      formData.append("id", this.workbench.id);

      this.$store.dispatch("data/editWBProfile", formData).then(
        Data => {
          this.successful = true;
          this.message = Data;
        },

        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
        }
      );
    },
    getUsers() {
      this.$store.dispatch("data/getWBusers").then(
        data => {
          this.workbench.users = data;
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    editWorkbenchLabel() {
      this.workbenchLabel = this.workbench.label;
      this.editname = true;
    },
    cancleWorkbenchLabel() {
      this.workbench.label = this.workbenchLabel;
      this.editname = false;
    },
    applyWorkbenchLabel() {
      this.editname = false;
      const formData = new FormData();
      formData.append("id", this.workbench.id);
      formData.append("task", "rnlabel");
      formData.append("label", this.workbench.label);

      this.$store.dispatch("data/editWBProfile", formData).then(
        Data => {
          this.successful = true;
          this.message = Data;
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
        }
      );
    },
    // applyWorkbenchLabel(){
    //     this.editname = false;
    //     var data = {id:this.workbench.id, label:this.workbench.label}

    //     console.log('send new name to server',data)
    //     this.$store.dispatch('data/changeWBlabel',data).then(
    //         data => {
    //             console.log("rename workbench: ",data)
    //         },
    //         error => {
    //             this.message =
    //                 (error.response && error.response.data && error.response.data.message) ||
    //                 error.message ||
    //                 error.toString();
    //                 // this.successful = false;
    //         }
    //     );
    // },
    editWorkbenchOwner() {
      this.workbenchOwner = this.workbench.creator;
      this.editowner = true;
    },
    cancelWorkbenchOwner() {
      this.workbench.creator = this.workbenchOwner;
      this.editowner = false;
    },
    applyWorkbenchOwner() {
      this.editowner = false;
      var data = { id: this.workbench.id, creator: this.workbench.creator.id };

      this.$store.dispatch("data/changeWBowner", data).then(
        data => {
          console.log("change workbench owner: ", data);
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    inviteUser() {
      this.$root.$emit("bv::show::modal", "modal-invite-user_box");
    },
    changeUserPermission(id, role) {

      const DialogObj = DialogUtility.confirm({
        title: this.dict.roleConfirm[this.lang],
        locale: this.lang,
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.roleConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            let roleName = "";
            this.permissionList.find((x) => {
              if (x.value == role.itemData.value)
                roleName = x.text;
            });

            this.$store.dispatch("data/editRoleWBusers", { id: id, role: roleName }).then(
              (response) => {
                this.message = response.message;
              },
              error => {
                this.message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
            );
            DialogObj.hide();
          }
        },
        cancelButton: {
          text: this.dict.cancel[this.lang], click: () => {
            this.reRender += 1;
            DialogObj.hide();
          }
        },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    },
    removeUser(id) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        enableRtl: this.lang == "per" ? true : false,
        content: this.dict.deleteConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            this.$store
              .dispatch("data/removeWBusers", {
                id: id
              }).then(() => {
              this.$store.dispatch("data/getWBdata");
            });
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });

      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    },
    getWBdata() {
      this.$store.dispatch("data/getWBdata").then(
        (response) => {
          console.log(response);
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getRoles() {
      this.$store.dispatch("data/getRolesList").then((res) => {
        this.roles = [];
        this.permissionList = [];
        this.roles = res.message;
        this.roles.forEach(x => {
          this.permissionList.push({
            text: x.name,
            value: x._id
          });
        });
      });
    },
    getAvatar(id) {
      this.$store
        .dispatch("data/getUsersAvatar", { id: id })
        .then((response) => {
          if (response != null) {
            this.$set(this.avatarUrl, id, response);
          }
        });
    }
  },
  created() {
    this.getRoles();
    this.getWbAvatar();
    // this.getWBdata();
  },
  mounted() {
    this.getRoles();
    this.getWbAvatar();
    this.$store.dispatch("data/getWBdata");
    this.$store.dispatch("data/sendDataForSSE", { id: [], action: "add" });
    // this.getWBdata();
  },
  provide: {
    grid: [Page, Sort, Toolbar, PdfExport, ExcelExport, Edit, Search]
  }
};
</script>
<style scoped src="@/assets/styles/theme.css"></style>

<style scoped>
.apply {
  color: #198754;
  cursor: pointer;
}

.apply :hover {
  color: #9b9b9b;
}

.reset {
  color: #dc3545;
  cursor: pointer;
}

.reset :hover {
  color: #9b9b9b;
}

.download-link {
  margin-bottom: 10px;
}

.my-modal-text {
  text-align: center;
  font-weight: bold;
  color: #198754;
  /* background-color: aliceblue !important; */
  padding: 20px;
}

/* .tab-content {
    overflow: hidden;
}

.tab-content > div {
    display: none;
    transition: transform 0.3s ease-in-out;
}

.tab-content > div.active {
    display: block;
    transform: translateX(0);
}

.tab-content > div.left-enter, .tab-content > div.left-leave-to {
    transform: translateX(-100%);
} */

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-100%);
}

</style>
<style>
.workbench-tab .tab-content {
  height: 100%;
}

.no-header-table thead {
  display: none;
}
</style>
