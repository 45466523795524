<template>
  <div ref="mainCont_fire" class="card-body p-1 d-flex justify-content-center align-items-center"
       style="height:calc(100%); width: 100%">
    <div :dir="dict.dir[lang]" :ref="plotId" :id="plotId" style="height:calc(100%);width: 100%">
      <p class="m-1 mx-2">{{ dict.currentEvents[lang] }}</p>
      <div class="py-3" :style="{'max-height':myData.attribute.height-10+'px'}"
           style="min-height:calc(100%);overflow-y: scroll">
        <div class="px-2">
          <ejs-accordion :key="reRender" expandMode="Single" :enableRtl="lang == 'per'"
                         style="overflow-y: hidden!important;">
            <div
              v-for="(item, index) in eventRows"
              :key="'acc' + index"
            >
              <div>
                <div>
                  <span>{{ item.title }}</span>
                </div>
              </div>
              <div style="overflow-y: auto">
                <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
                  <li
                    v-for="(item2,key) in detectItem(item)" :key="key"
                    class="mga-tab-nav-item"
                    @click="activeTab = key"
                  >
                    <a
                      class="mga-tab-nav-link"
                      :class="{ active: activeTab === key }"
                      :id="key"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="tab1"
                      aria-selected="true"
                    >
                      {{ dict[key][lang] }}
                    </a>
                  </li>
                </ul>
                <div class="mt-3" v-for="(item3,key2) in detectItem(item)" :key="key2">
                  <div v-show="activeTab==key2">
                    <p v-show="activeTab=='destination'" class="text-start" v-for="(x,k) in item3" :key="k">{{ k }} :
                      {{ x }}</p>
                    <div v-if="activeTab=='onRouteTLs'">
                      <div class="text-start">
                        <table class="table table-bordered" style="overflow-x: scroll">
                          <thead>
                          <tr>
                            <th v-for="(value2, key2) in item3[Object.keys(item3)[0]]" :key="key2">{{ key2 }}</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(value, key) in item3" :key="key">
                            <td v-for="(value2, key2) in value" :key="key2">{{ value2 }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div v-if="activeTab=='trafficLights'">
                      <div class="text-start">
                        <table class="table table-bordered" style="overflow-x: scroll">
                          <thead>
                          <tr>
                            <th v-for="(value2, key2) in item3[Object.keys(item3)[0]]" :key="key2">{{ key2 }}</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(value, key) in item3" :key="key">
                            <td v-for="(value2, key2) in value" :key="key2">{{ value2 }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div v-if="activeTab=='fireTrucks'">
                      <div class="text-start">
                        <table class="table table-bordered" style="overflow-x: scroll">
                          <thead>
                          <tr>
                            <th v-for="(value2, key2) in item3[Object.keys(item3)[0]]" :key="key2">{{ key2 }}</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(value, key) in item3" :key="key">
                            <td v-for="(value2, key2) in value" :key="key2">{{ value2 }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div v-if="activeTab=='fireTrucks'">
                      <div class="text-start">
                        <table class="table table-bordered" style="overflow-x: scroll">
                          <thead>
                          <tr>
                            <th v-for="(value2, key2) in item3[Object.keys(item3)[0]]" :key="key2">{{ key2 }}</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(value, key) in item3" :key="key">
                            <td v-for="(value2, key2) in value" :key="key2">{{ value2 }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div v-if="activeTab=='fireStations'">
                      <div class="text-start">
                        <table class="table table-bordered" style="overflow-x: scroll">
                          <thead>
                          <tr>
                            <th v-for="(value2, key2) in item3[Object.keys(item3)[0]]" :key="key2">{{ key2 }}</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(value, key) in item3" :key="key">
                            <td v-for="(value2, key2) in value" :key="key2">{{ value2 }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>

          </ejs-accordion>
          <!--              <div class="text-start my-0" v-for="sub in itemsList[item.value]" :key="sub">-->
          <!--                <p class="my-1">{{ sub.name }} : <span style="font-size: 13.5px">{{ sub.value }}</span></p>-->
          <!--              </div>-->
        </div>
      </div>


    </div>
    <data-picker-modal />
    <b-modal
      :id="plotId+'fireEvents'"
      size="lg"
      centered
      body-class="my-body-class"
      hide-header-close
      :title="dict.modalTitle[lang]"
      :cancel-title="dict.cancel[lang]"
      :ok-title="dict.ok[lang]"
      :dir="dict.dir[lang]"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div>
        <b-container fluid="md" class="p-0">
          <div class="card-body h-100 p-3 " style="overflow: hidden;">
            <div class="d-flex align-items-center">
              <ejs-textbox
                :placeholder="dict.pickData[lang]"
                :enableRtl="lang == 'per'"
                floatLabelType="Auto"
                v-model="selectedData['name']"
                :showClearButton="true"
              ></ejs-textbox>
              <b-button @click="pickData" class="btn-sm" variant="primary"><span
                class="e-icons e-edit-6 "></span></b-button>
            </div>
          </div>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { DialogUtility } from "@syncfusion/ej2-popups";
import DataPickerModal from "@/components/dataManagerModalDataPicker.vue";
import { v4 } from "uuid";
import {
  AccordionComponent
} from "@syncfusion/ej2-vue-navigations";
import L from "leaflet";
import { debounce } from "@/services/data.service";

export default {
  name: "widgetFireEvents",
  components: {
    DataPickerModal,
    "ejs-accordion": AccordionComponent
  },
  props: {
    plotId: String,
    data: Object,
    editable: Boolean,
    resized: Number,
    setting: Boolean
  },
  data() {
    return {
      dict: {
        dir: { eng: "ltr", per: "rtl" },
        event: { eng: "event", per: "حادثه" },
        cancel: { eng: "cancel", per: "لغو" },
        ok: { eng: "ok", per: "تایید" },
        modalTitle: { eng: "Edit widget", per: "ویرایش ویجت" },
        pickData: { per: "انتخاب داده", eng: "Pick Data" },
        fireStations: { eng: "fire stations", per: "ایستگاه ها" },
        destination: { eng: "destination", per: "محل حادثه" },
        hydrants: { eng: "hydrants", per: "هیدرانت ها" },
        trafficLights: { eng: "traffic Lights", per: "چراغ راهنمایی" },
        fireTrucks: { eng: "fire trucks", per: "ماشین ها" },
        fireFighters: { eng: "fire fighters", per: "پرسنل" },
        onRouteTLs: { eng: "on Route traffic lights", per: "چراغ ترافیک درمسیر" },
        currentEvents: { eng: "current events", per: "حوادث جاری" },
        title: { eng: "current events", per: "حوادث جاری" }
      },
      myData: {
        datas: [],
        attribute: {
          height: 100,
          width: 100
        }
      },
      selectedData: {
        name: ""
      },
      tabs: [
        { eng: "fire stations", per: "ایستگاه ها", value: "fireStations" },
        { eng: "destination", per: "محل حادثه", value: "destination" },
        { eng: "hydrants", per: "هیدرانت ها", value: "hydrants" },
        { eng: "traffic Lights", per: "چراغ راهنمایی", value: "trafficLights" },
        { eng: "fire trucks", per: "ماشین ها", value: "fireTrucks" },
        { eng: "fire fighters", per: "پرسنل", value: "fireFighters" },
        { eng: "on Route traffic lights", per: "چراغ ترافیک درمسیر", value: "onRouteTLs" },
        { eng: "on Route traffic lights", per: "چراغ ترافیک درمسیر", value: "times" }
      ],
      list: {},
      itemsList: {
        fireStations: [],
        destination: [],
        hydrants: [],
        trafficLights: [],
        fireTrucks: [],
        fireFighters: []
      },
      reRender: 0,
      activeTab: "hydrants",
      eventRows: {}
    };
  },
  methods: {
    updateDropdown() {
      const vm = this;
      var dropdown = vm.container;
      dropdown.innerHTML = "";
      vm.layerList.forEach(function(item, index) {
        var option = L.DomUtil.create("div", "", dropdown);
        option.style.width = "auto";
        option.style.height = "auto";
        option.style.backgroundColor = "white";
        option.style.boxShadow = "0 2px 5px rgba(0,0,0,0.2)";
        option.style.display = "block";
        option.style.alignItems = "right";
        option.style.justifyContent = "center";
        option.style.cursor = "pointer";
        option.style.textAlign = "right";
        option.style.marginTop = "5px";
        option.style.padding = "5px";
        option.innerHTML = item.title;
        option.onmouseover = function() {
          option.style.backgroundColor = "#f0f0f0";
          if (vm.myData.attribute.activeLayers.indexOf(item._id) > -1) {
            option.style.backgroundColor = "rgba(129,131,232,0.8)";
            option.style.color = "white";
          } else {
            option.style.backgroundColor = "#f0f0f0";
            option.style.color = "black";
          }
        };
        option.onmouseout = function() {
          option.style.backgroundColor = "white";
          if (vm.myData.attribute.activeLayers.indexOf(item._id) > -1) {
            option.style.backgroundColor = "rgba(129,131,232,0.8)";
            option.style.color = "white";
          } else {
            option.style.backgroundColor = "#f0f0f0";
            option.style.color = "black";
          }
        };
        option.onclick = function(e) {
          e.stopPropagation();
          vm.tempTriggered = false;
          if (vm.myData.attribute.activeLayers.indexOf(item._id) > -1)
            vm.myData.attribute.activeLayers.splice(vm.myData.attribute.activeLayers.indexOf(item._id), 1);
          else {
            vm.tempTriggered = false;
            vm.myData.attribute.activeLayers.push(item._id);
            option.style.backgroundColor = "rgba(129,131,232,0.8)";
            option.style.color = "white";
          }
        };
        dropdown.appendChild(option);
      });
    },
    detectItem(item) {
      const filteredObject = Object.fromEntries(
        Object.entries(item).filter(([key, value]) => key !== "title")
      );
      return filteredObject;
    },
    computeX(item) {
      let x = {};
      Object.entries(item).forEach(([key, value]) => {
        x[key] = value;
      });
      return x;
    },
    resize() {
      const divElement = this.$refs.mainCont_fire;
      if (divElement) {
        this.myData.attribute.height = divElement.clientHeight - 58;
        this.myData.attribute.width = divElement.clientWidth - 16;
      }
    },
    initModal() {
      this.selectedData = {
        dataType: this.myData.datas[0].dataType,
        name: this.myData.datas[0].name,
        type: this.myData.datas[0].type,
        id: this.myData.datas[0]._id

      };
    },
    setDataForSse(item) {
      item[1].status = 1;
      this.list[item[0]] = item[1];

      this.$store.dispatch("data/updateData", {
        _id: this.myData.datas[0]._id,
        values: this.list
      }).then();


    },
    resetModal() {
    },
    handleOk($bv) {
      $bv.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.$validator.validate().then(isValid => {
        if (isValid) {

          this.$store.dispatch("data/editDashboard", { _id: this.plotId, data: this.myData }).then(
            data => {
              this.$store.dispatch("chpge/child2parent", { task: "updateDash" });
              this.$nextTick(() => {
                this.$bvModal.hide(this.plotId + "fireEvents");
              });
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
              // alert(this.message);
            }
          );
        }
      });
    },
    pickData() {
      this.$store.dispatch("chpge/parent2child", {
        task: "pickedData",
        from: "widgetFireEvents" + this.plotId,
        filter: {
          dataType: ["freeObject", "freeArray"],
          usedBy: [],
          parents: [],
          archive: null,
          perPage: 5000,
          page: 1,
          withChild: true
        }
      });
      this.$root.$emit("bv::show::modal", "modal-data-picker");
    },
    async getMarkerData(id) {
      try {
        const res = await this.$store.dispatch("panel/getData", { id: id });
        return res;
      } catch (error) {
        console.error("An error occurred:", error);
        return null;
      }
    },
    async generateNewRow(ids, props, colId) {
      const row = await this.getMarkerData(ids);
      let rowObj = {};

      props.forEach(x => {
        row.forEach((item) => {
          if (Array.isArray(x[1])) {

            x[1].forEach(prop2 => {
              if (prop2 == item.id) {
                let colObj = {};
                item.value.forEach(valueItem => {
                  colObj[valueItem.name] = valueItem.value;
                });

                if (!rowObj[x[0]]) {
                  rowObj[x[0]] = {};
                }
                rowObj[x[0]] = { ...rowObj[x[0]], [prop2]: colObj };
              }
            });
          } else if (typeof x[1] == "string") {
            if (x[1] == item.id) {
              if (Array.isArray(item.value)) {
                let newRow1 = {};
                item.value.forEach(valueItem => {
                  newRow1[valueItem.name] = valueItem.value;
                });
                rowObj = { ...rowObj, [x[0]]: newRow1 };
              }
            } else if (x[0] != "destination" && x[1] != item.id) {
              rowObj = { ...rowObj, [x[0]]: x[1] };
            }
          } else if (typeof x[1] != "string" && !Array.isArray(x[1]) && x[1] != null) {
            let newRow2 = {};
            Object.entries(x[1]).forEach((subObj) => {
              newRow2[subObj[0]] = subObj[1];
            });
            rowObj = { ...rowObj, [x[0]]: newRow2 };
          }
        });
      });

      if (colId in this.eventRows) {
        this.eventRows[colId] = { ...rowObj, ...this.eventRows[colId] }; // Correctly merge with existing data
      } else {
        let newEventRows = { [colId]: rowObj };
        this.eventRows = { ...newEventRows, ...this.eventRows };
      }

      this.reRender += 1;
    },
    processList(val, id) {
      let datas = [];
      let rows = [];
      Object.entries(val).forEach(subItem => {
        if (subItem[0] != "mapLayer" && subItem[0] != "routes" && subItem[0] != "origin" && subItem[0] != "id" && subItem[0] != "times" && subItem[0] != "status") {

          if (Array.isArray(subItem[1])) {
            subItem[1].forEach((subArr) => {
              if (typeof subArr != "object" && subArr != null && subItem[0] != "times" && subItem[0] != "status") {
                datas.push(subArr);
              }
            });
          } else if (subItem[0] != "title" && subItem[1] != null && subItem[0] != "onRouteTLs" && subItem[0] != "status") {
            datas.push(subItem[1]);
          }
          rows.push(subItem);
        }
      });
      this.generateNewRow(datas, rows, id);
    }
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    },
    updatedData() {
      return this.$store.state.panel.data;
    },
    child2parent() {
      return this.$store.state.chpge.child2child;
    }
  },
  watch: {
    resized() {
      this.resize();
    },
    setting(newValue) {
      if (newValue) {
        this.$root.$emit("bv::show::modal", this.plotId + "fireEvents");
      }
      // } else this.handleSubmit();
    },
    child2parent(newValue) {
      if (newValue.to == "widgetFireEvents" + this.plotId) {
        if (Object.keys(newValue.item).length > 0) {
          this.selectedData = newValue.item;
          this.myData.datas[0] = {
            _id: newValue.item.id,
            dataType: newValue.item.dataType,
            name: newValue.item.name,
            type: newValue.item.type
          };
        }
      }
    },
    list: {
      handler(val) {
        debounce(async () => {
          for (const item of Object.entries(val)) {

            if (("status" in item[1] && item[1]["status"] == 0) && ("fireApproval" in item[1] && item[1]["fireApproval"] == null)) {
              const destInfo = await this.getMarkerData([item[1].destination]);
              let destination = {};
              destInfo[0].value.forEach(key => {
                destination[key.name] = key.value;
              });

              const DialogObj = DialogUtility.confirm({
                title: item[1].title,
                locale: this.lang,
                position: "center center",
                Enabletrl: this.lang == "per" ? true : false,
                content: "آیا حادثه " + destination.name + " " + destination.address + " را تایید میکنید؟",
                okButton: {
                  text: this.dict.ok[this.lang], click: () => {
                    item[1].fireApproval = true;
                    this.list[item[0]] = item[1];

                    this.$store.dispatch("data/updateData", {
                      _id: this.myData.datas[0]._id,
                      values: [{ id: "", value: this.list }]
                    }).then(() => {
                      DialogObj.hide();
                    });

                  }
                },
                cancelButton: {
                  text: this.dict.cancel[this.lang], click: () => {
                    item[1].fireApproval = false;
                    this.list[item[0]] = item[1];

                    this.$store.dispatch("data/updateData", {
                      _id: this.myData.datas[0]._id,
                      values: [{ id: "", value: this.list }]
                    }).then(() => {
                      DialogObj.hide();
                    });

                  }
                },
                showCloseIcon: false,
                closeOnEscape: false,
                animationSettings: { effect: "Zoom" }
              });
              if (this.lang == "per") {
                DialogObj.element.classList.add("e-control-rtl");
              } else {
                DialogObj.element.classList.remove("e-control-rtl");
                DialogObj.element.classList.add("e-control-ltr");
              }
            } else if (("status" in item[1] && item[1]["status"] == 1) && ("atributeApproval" in item[1] && item[1]["atributeApproval"] == null)) {
              console.log(item[1]);
              const DialogObj = DialogUtility.confirm({
                title: item[1].title,
                locale: this.lang,
                position: "center center",
                Enabletrl: this.lang == "per" ? true : false,
                content: "آیا حادثه را تایید میکنید؟",
                okButton: {
                  text: this.dict.ok[this.lang], click: () => {
                    item[1].atributeApproval = true;
                    this.list[item[0]] = item[1];

                    this.$store.dispatch("data/updateData", {
                      _id: this.myData.datas[0]._id,
                      values: [{ id: "", value: this.list }]
                    }).then(() => {
                      DialogObj.hide();
                    });

                  }
                },
                cancelButton: {
                  text: this.dict.cancel[this.lang], click: () => {
                    item[1].atributeApproval = false;
                    this.list[item[0]] = item[1];

                    this.$store.dispatch("data/updateData", {
                      _id: this.myData.datas[0]._id,
                      values: [{ id: "", value: this.list }]
                    }).then(() => {
                      DialogObj.hide();
                    });

                  }
                },
                showCloseIcon: false,
                closeOnEscape: false,
                animationSettings: { effect: "Zoom" }
              });
              if (this.lang == "per") {
                DialogObj.element.classList.add("e-control-rtl");
              } else {
                DialogObj.element.classList.remove("e-control-rtl");
                DialogObj.element.classList.add("e-control-ltr");
              }
            }
            this.processList(item[1], item[0]);
          }
        }, 50);
      }, deep: true
    },
    updatedData(newValue) {
      // console.log(newValue,'newValue');
      setTimeout(() => {
        for (let index = 0; index < newValue.length; index++) {
          const iData = newValue[index];

          if (iData.id === this.myData.datas[0]._id) {
            if (Object.keys(iData.value).length > 0) {
              // console.log('iData.value has keys:', iData.value);
              this.list = iData.value;
            }
            break;
          }
        }
      }, 10);
    }
  },
  mounted() {
    if (this.data && this.data.datas && this.data.attribute) {
      this.myData = this.data;
      if (this.myData.datas.length > 0) {
        this.$store.dispatch("data/sendDataForSSE", { id: [this.myData.datas[0]._id] ,action: "add"});
        this.$store.dispatch("panel/getData", { id: [this.myData.datas[0]._id] }).then(res => {
          // this.list = res[0].value;
        });
      }
    }

    // this.setDataForSse()

    // setTimeout(() => {
    //   let ID = v4();
    //
    //   this.list = {
    //     ...this.list, [ID]: {
    //       "hydrants": "6794e86e0d16b93d32da4e80",
    //       "destination": "6795f8a1d28f936097eea9bd",
    //       "fireStations": "678f4e215f599da19e4322b1",
    //       "trafficLights": "678f4ed35f599da19e432497",
    //       "fireTrucks": "678f4e9f5f599da19e4323bf",
    //       "fireFighters": "678f4e9f5f599da19e4323bf",
    //       "title": "new events"
    //     }
    //   };
    // }, 3000);
  }
};
</script>
<style>


</style>