<template>
  <div ref="mainCont" class="card-body p-1 d-flex justify-content-center align-items-center"
       style="height:calc(100%); width: 100%">
    <div style="height:calc(100%);width: 100%" class="row px-0">

      <div class="py-0 ps-1 col-4" :style="{'max-height':100-10+'px'}">
        <div style="overflow-y: auto" :style="{'max-height':myData.attribute.mapHeight+30+'px'}" class="px-0">
          <div tabindex="0" class="e-card shadow-lg custom-card my-2" v-for="(card, index) in EventRowGenerator"
               :key="index">
            <div class="e-card-header text-dark">
              <div class="e-card-header-caption">
                <div class="e-card-header-title">
                  <div class="d-flex mb-4" v-if="card.status<=8">
                    <span class="mt-1 mx-1" style="color: forestgreen"
                          v-if="card['fireApproval']!=false">{{ steppers[card.status][lang] }}</span>
                    <span class="mt-1 mx-1" style="color: firebrick"
                          v-if="card['fireApproval']==false">{{ dict.canceledEvent[lang] }}</span>
                    <div v-if="card['fireApproval']!=false && card.status<=7" class="loader">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="d-flex">
                      <span class="text-dark my-1 bold">{{ dict.incident[lang] }}{{ card.title }}</span>
                      <i @click="watchLayer(card)" v-if="activeLayers.indexOf(card.id)<=-1"
                         class="text-end my-1 text-dark mx-3 pt-1 e-icons e-eye"></i>
                      <i @click="watchLayer(card)" v-if="activeLayers.indexOf(card.id)>=0"
                         class="text-end my-1 text-dark mx-3 pt-1 e-icons e-eye-slash"></i>
                    </div>

                    <i @click="toggleContent(index)"
                       :class="{'e-icons e-chevron-up': isContentVisible === index, 'e-icons e-chevron-down': isContentVisible !== index, ' text-end my-1 text-dark': true}"></i>
                  </div>
                </div>
                <div class="e-card-sub-title text-dark">{{ getIrDate(card.times[0].fireAlarm, lang) }}</div>
                <!-- Add Stepper here -->
                <div class="stepper-container" style="overflow-x: auto">
                  <ejs-stepper
                    :enableRtl="lang=='per'"
                    :activeStep="card.status" linear="true"
                    :showTooltip="true"
                    labelPosition="top"
                    :template="tempContent"
                    :tooltipTemplate="tooltipContent">
                    <e-steps>
                      <e-step :cssClass="'icon-step'+index+'-'+lang" v-for="(step, index) in steppers" :label="index"
                              :text="step[lang]"
                              :key="index">
                      </e-step>
                    </e-steps>
                  </ejs-stepper>
                </div>
              </div>
            </div>
            <transition name="fade">
              <div class="e-card-content bg-light" v-if="isContentVisible === index">
                <div style="overflow-y: auto">
                  <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
                    <li v-for="(item2, key) in detectItem(card)" :key="key" class="mga-tab-nav-item"
                        @click="activeTab2 = key">
                      <a class="mga-tab-nav-link" :class="{ active: activeTab2 === key }" :id="key" data-toggle="tab"
                         role="tab" aria-controls="tab1" aria-selected="true">
                        {{ dict[key][lang] }}
                      </a>
                    </li>
                  </ul>
                  <div class="mt-3" v-for="(item3, key2) in detectItem(card)" :key="key2">
                    <div v-show="activeTab2==key2">
                      <div v-show="activeTab2=='destination'" class="text-start" v-for="(x, k) in item3" :key="k">
                        <p v-if="k=='location'">{{ k }} : <i @click="showTheMarker(x)"
                                                             class="text-end my-1 text-black mx-3 pt-1 e-icons e-eye"></i>
                        </p>
                        <p v-else> {{ k }} : {{ x }}</p>
                      </div>
                      <div v-if="activeTab2=='onRouteTLs'">
                        <div class="text-start" style="overflow-x: auto">
                          <table class="table table-bordered table-hover" style="overflow-x: auto">
                            <thead class="thead-dark">
                            <tr>
                              <th
                                v-for="(value2, key2) in filterKeys(item3[Object.keys(item3)[0]], ['command', 'status', 'location', 'distance'])"
                                :key="key2">
                                <span>{{ key2 }}</span>
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(value, key) in item3" :key="key">
                              <td
                                v-for="(value2, key2) in filterKeys(getValueTLS(key, value), ['command', 'status', 'location', 'distance'])"
                                :key="key2">
                                <span v-if="key2=='cross'">
                                  <img :src="trafficLightTranslator[value2['finalWay']]" alt="" width="50">
                                </span>
                                <span v-if="key2=='commands'">
                                  <div class="border-bottom" v-for="(item,key) in computeCommands(value2)" :key="key">
                                   <p> {{ item.time }}</p>
                                    <p>{{ dict.situation[lang] }}{{ dict[item.command][lang] }}</p>
                                  </div>
                                </span>
                                <span v-else-if="key2!='commands' && key2!='cross'">{{ value2 }}</span>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div v-if="activeTab2=='trafficLights'">
                        <div class="text-start">
                          <table class="table table-bordered table-hover" style="overflow-x: scroll">
                            <thead class="thead-dark">
                            <tr>
                              <th
                                v-for="(value2, key2) in filterKeys(item3[Object.keys(item3)[0]], ['model','color','code'])"
                                :key="key2">{{ key2 }}
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(value, key) in item3" :key="key">
                              <td v-for="(value2, key2) in value" :key="key2">{{ value2 }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div v-if="activeTab2=='fireTrucks'">
                        <div class="text-start">
                          <table class="table table-bordered table-hover" style="overflow-x: scroll">
                            <thead class="thead-dark">
                            <tr>
                              <th
                                v-for="(value2, key2) in filterKeys(item3[Object.keys(item3)[0]], ['brand','station','location','model','color','code'])"
                                :key="key2">{{ key2 }}
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(value, key) in item3" :key="key">
                              <td
                                v-for="(value2, key2) in filterKeys(item3[Object.keys(item3)[0]], ['brand','station','location','model','color','code'])"
                                :key="key2">
                                            <span v-if="key2 === 'registration number'">
                                                <svg-plak :data="value2" :key="key2" />
                                            </span>
                                <span v-else-if="key2 === 'type'">
                                  <img width="50" :src="fireTruckTranslator[value2]" alt="">
                                                                            </span>
                                <span v-else>{{ value2 }}</span>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div v-if="activeTab2=='fireStations'">
                        <div class="text-start">
                          <table class="table table-bordered table-hover" style="overflow-x: scroll">
                            <thead class="thead-dark">
                            <tr>
                              <th v-for="(value2, key2) in item3[Object.keys(item3)[0]]" :key="key2">{{ key2 }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(value, key) in item3" :key="key">
                              <td v-for="(value2, key2) in value" :key="key2">
                                 <span v-if="key2=='location'">
                                   <i @click="showTheMarker(value2)"
                                      class="text-end my-1 text-black mx-3 pt-1 e-icons e-eye"></i>
                                </span>
                                <span v-else>{{ value2 }}</span>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>


        </div>
      </div>
      <div v-if="mapinit" class="col-8 p-0">
        <div style="height:calc(100%);width: 100%" :ref="plotId" :id="plotId"></div>
      </div>
    </div>
    <!--    <div class="d-flex justify-content-center">-->
    <!--      <div>-->

    <!--      </div>-->
    <!--     -->
    <!--    </div>-->
    <!--    <div>-->
    <!--      <ul-->
    <!--        class="pagination"-->
    <!--        style="-->
    <!--            flex-direction: column;-->
    <!--            width: 100%;-->
    <!--            text-align: center;-->
    <!--            padding: 15px;-->
    <!--            overflow-y: scroll;-->
    <!--            min-height: 5rem;-->
    <!--            max-height: 10rem;-->
    <!--          "-->
    <!--      >-->
    <!--        <li-->
    <!--          v-for="(item, i) in layerList"-->
    <!--          :key="i"-->
    <!--          class="page-item"-->
    <!--          @click="changeLayer(item)"-->
    <!--        >-->
    <!--          <a class="page-link" href="#">{{ item.title }}</a>-->
    <!--        </li>-->
    <!--      </ul>-->
    <!--    </div>-->

    <b-modal
      :id="plotId"
      size="lg"
      centered
      body-class="my-body-class"
      hide-header-close
      :title="modalTitle[lang]"
      :cancel-title="dict.cancel[lang]"
      :ok-title="dict.ok[lang]"
      :dir="dict.dir[lang]"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div>
        <b-container fluid="md" class="p-0">
          <div class="card-header">
            <ul class="nav nav-tabs card-header-tabs">
              <li v-for="(tab, index) in tabList" :key="index" class="nav-item" @click="activeTab = tab">
                <p class="nav-link"
                   :class="{ active: activeTab === tab }"
                   :id=tab
                   data-toggle="tab"
                   role="tab"
                   aria-controls="tab1"
                   aria-selected="true"
                >
                  {{ dict[tab][lang] }}
                </p>
              </li>
            </ul>
          </div>
          <div class="card-body h-100 p-3 " style="overflow: hidden;">
            <transition name="slide-fade" mode="out-in">
              <div class="tab-content h-100" :key="activeTab">
                <div v-if="activeTab === 'data'" class="h-100 ">
                  <div fluid class="container-fluid p-0 h-100 " :key="reRender">
                    <div class="row mx-auto">
                      <div class="col-md-6 col-12 my-2">
                        <ejs-textbox
                          :placeholder="dict.lat[lang]"
                          :value="myData.attribute.center[0]"
                          :enableRtl="lang === 'per'"
                          floatLabelType="Auto"
                          v-model="myData.attribute.center[0]"
                        ></ejs-textbox>
                      </div>
                      <div class="col-md-6 col-12 my-2">
                        <ejs-textbox
                          :placeholder="dict.lng[lang]"
                          :value="myData.attribute.center[1]"
                          :enableRtl="lang === 'per'"
                          floatLabelType="Auto"
                          v-model="myData.attribute.center[1]"
                        ></ejs-textbox>
                      </div>
                      <div class="col-md-6 col-12 my-2">
                        <ejs-dropdownlist
                          :placeholder="dict.mapTile[lang]"
                          :enableRtl="lang === 'per'"
                          :dataSource="tileIdList"
                          floatLabelType="Auto"
                          :fields="{ text: 'text', value: 'value' }"
                          v-model="myData.attribute.tileId"
                          @change="(val)=>{myData.attribute.tileId=val.value}"
                        ></ejs-dropdownlist>
                      </div>
                      <div class="col-md-6 col-12 my-3">
                        <span>{{ dict.minZoom[lang] }}</span>
                        <ejs-slider
                          :showButtons="true"
                          v-model.number="myData.attribute.minZoom"
                          :step="1"
                          :enableRtl="lang=='per'"
                          :min="0"
                          :max="25"
                          :value="myData.attribute.minZoom"
                          v-model="myData.attribute.minZoom"
                          :tooltip="{ placement: 'Before', isVisible: true, showOn: 'Auto' }"
                          :ticks="{ placement: 'After', largeStep: 5, showSmallTicks: false }"
                          :type="'MinRange'"></ejs-slider>
                      </div>
                      <div class="col-md-6 col-12 my-3">
                        <span>{{ dict.maxZoom[lang] }}</span>
                        <ejs-slider
                          :showButtons="true"
                          v-model.number="myData.attribute.maxZoom"
                          :step="1"
                          :enableRtl="lang=='per'"
                          :min="0"
                          :max="25"
                          :value="myData.attribute.maxZoom"
                          v-model="myData.attribute.maxZoom"
                          :tooltip="{ placement: 'Before', isVisible: true, showOn: 'Auto' }"
                          :ticks="{ placement: 'After', largeStep: 5, showSmallTicks: false }"
                          :type="'MinRange'"></ejs-slider>
                      </div>
                      <div class="col-md-6 col-12 my-3">
                        <span>{{ dict.nativeZoom[lang] }}</span>
                        <ejs-slider
                          :showButtons="true"
                          :step="1"
                          :enableRtl="lang=='per'"
                          :min="0"
                          :max="25"
                          :value="myData.attribute.maxNativeZoom"
                          v-model="myData.attribute.maxNativeZoom"
                          :tooltip="{ placement: 'Before', isVisible: true, showOn: 'Auto' }"
                          :ticks="{ placement: 'After', largeStep: 5, showSmallTicks: false }"
                          :type="'MinRange'"></ejs-slider>
                      </div>
                      <div class="col-md-6 col-12 my-3">
                        <span>{{ dict.viewZoom[lang] }}</span>
                        <ejs-slider
                          :showButtons="true"
                          :step="1"
                          :enableRtl="lang=='per'"
                          :min="0"
                          :max="25"
                          :value="myData.attribute.viewZoom"
                          v-model="myData.attribute.viewZoom"
                          :tooltip="{ placement: 'Before', isVisible: true, showOn: 'Auto' }"
                          :ticks="{ placement: 'After', largeStep: 5, showSmallTicks: false }"
                          :type="'MinRange'"></ejs-slider>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="activeTab === 'basic'" class="h-100">
                  <div fluid class="container-fluid p-0 h-100">
                    <ul class="nav nav-tabs card-header-tabs">
                      <li v-for="(tab, index) in layerTyps" :key="index" class="nav-item"
                          @click="activeLayersTab = tab">
                        <p class="nav-link"
                           :class="{ active: activeLayersTab === tab }"
                           :id=tab
                           data-toggle="tab"
                           role="tab"
                           aria-controls="tab1"
                           aria-selected="true"
                        >
                          {{ dict[tab][lang] }}
                        </p>
                      </li>
                    </ul>
                    <div class="mt-4" v-show="activeLayersTab=='DB'">
                      <b-table ref="table1" :items="myData.datas.filter(item=>item.category=='DB')"
                               :fields="groupFields" thead-class="hidden_header"
                               responsive="sm" small>
                        <template #cell(modify)="row">
                          <div style="display:flex; justify-content:flex-end;">
                            <b-button pill size="sm" variant="outline-danger"
                                      @click="removeItem(row.item.id,row.item.category)"
                                      class="mr-2"
                                      style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                              <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                            </b-button>
                          </div>
                        </template>
                        <!-- <template #cell(color)="row" >
                          <div class="d-flex align-items-center p-2">
                            <input type="color" v-model="row.item.color" class="w-100"/>
                          </div>
                        </template> -->

                        <!-- <template #cell(title)="row" >
                          <div class="d-flex align-items-center p-2">
                            <input type="text" v-model="row.item.title" class="w-100"/>
                          </div>
                        </template> -->

                        <!-- <template #cell(mode)="row" >
                          <select id="mySelect" v-model="row.item.mode" class="form-select" aria-label="Default select example" >
                            <option v-for="(option, index) in modeList" :key="index" :value="option.value">{{ option.text }}</option>
                          </select>
                        </template>
                        <template #cell(shape)="row" >
                          <select id="mySelect" v-model="row.item.shape" class="form-select" aria-label="Default select example" >
                            <option v-for="(option, index) in shapeList" :key="index" :value="option.value">{{ option.text }}</option>
                          </select>
                        </template>
                        <template #cell(dash)="row" >
                          <select id="mySelect" v-model="row.item.dash" class="form-select" aria-label="Default select example" >
                            <option v-for="(option, index) in dashList" :key="index" :value="option.value">{{ option.text }}</option>
                          </select>
                        </template> -->


                      </b-table>
                      <div style="display:flex; justify-content: right; align-items: center;">
                        <b-button @click="addItem" size="sm" variant="outline-danger"
                                  style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                          <i>
                            <font-awesome-icon icon="fas fa-plus" />
                          </i>
                        </b-button>
                      </div>
                    </div>
                    <div v-if="activeLayersTab=='Controller'">

                      <b-table ref="table1" :items="myData.datas.filter(item=>item.category!='DB')"
                               :fields="groupFields" thead-class="hidden_header"
                               responsive="sm" small>
                        <template #cell(key)>
                          <div class="d-flex align-items-center">
                            <ejs-textbox
                              :placeholder="dict.pickData[lang]"
                              :enableRtl="lang == 'per'"
                              floatLabelType="Auto"
                              v-model="selectedData['name']"
                              :showClearButton="true"
                            ></ejs-textbox>
                          </div>
                        </template>
                        <template #cell(modify)="row">
                          <div style="display:flex; justify-content:flex-end;">
                            <b-button pill size="sm" variant="outline-danger"
                                      @click="removeItem(row.item.id,row.item.category)"
                                      class="mr-2"
                                      style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                              <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                            </b-button>
                          </div>
                        </template>

                      </b-table>
                      <div style="display:flex; justify-content: right; align-items: center;">
                        <b-button @click="pickData" size="sm" variant="outline-danger"
                                  style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                          <i>
                            <font-awesome-icon icon="fas fa-plus" />
                          </i>
                        </b-button>
                      </div>


                    </div>
                    <div v-if="activeLayersTab=='eventData'">

                      <b-table ref="table1" :items="myData.eventData"
                               :fields="groupFields" thead-class="hidden_header"
                               responsive="sm" small>
                        <template #cell(key)>
                          <div class="d-flex align-items-center">
                            <ejs-textbox
                              :placeholder="dict.pickData[lang]"
                              :enableRtl="lang == 'per'"
                              floatLabelType="Auto"
                              v-model="selectedDataEvent['name']"
                              :showClearButton="true"
                            ></ejs-textbox>
                          </div>
                        </template>
                        <template #cell(modify)="row">
                          <div style="display:flex; justify-content:flex-end;">
                            <b-button pill size="sm" variant="outline-danger"
                                      @click="removeDataItem(row.item.id)"
                                      class="mr-2"
                                      style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                              <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                            </b-button>
                          </div>
                        </template>

                      </b-table>
                      <div style="display:flex; justify-content: right; align-items: center;">
                        <b-button @click="pickDataEvent" size="sm" variant="outline-danger"
                                  style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                          <i>
                            <font-awesome-icon icon="fas fa-plus" />
                          </i>
                        </b-button>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </b-container>
      </div>
    </b-modal>

    <b-modal
      :id="'modal-widget-pick-map'+plotId"
      size="lg"
      centered
      body-class="MWS-body-class"
      body-text-variant="dark"
      content-class="shadow"
      @shown="initPickMapModal"
      :dir="dict.dir[lang]"
      @hidden="resetPickMapModal"
      hide-footer
    >
      <template #modal-header>
        <h5>{{ pickMap.modalTitle[lang] }}</h5>
        <i>
          <font-awesome-icon icon="fas fa-xmark" class="close" @click="pickMapHandleClose" />
        </i>
      </template>

      <div>
        <b-container fluid>
          <!-- <b-form-group>
              <b-form-radio-group
                  v-model="type"
                  class="d-flex"
                  style="justify-content:space-around"
              >
                  <b-form-radio value="data">Data</b-form-radio>
                  <b-form-radio value="config" class="ml-5">Config</b-form-radio>
              </b-form-radio-group>
          </b-form-group> -->

          <b-card>
            <b-row align-h="between" class="mb-2">
              <b-col lg="4" sm="6" md="5" class="my-1">
                <ejs-textbox
                  :value="pickMap.filter"
                  :placeholder="dict.search[lang]"
                  :enableRtl="lang === 'per'"
                  floatLabelType="Auto"
                  :showClearButton="true"
                  v-model="pickMap.filter"
                ></ejs-textbox>
              </b-col>
            </b-row>

            <b-table
              :items="pickMap.items"
              :fields="pickMap.fields"
              :current-page="pickMap.currentPage"
              :per-page="pickMap.perPage"
              :filter="pickMap.filter"
              :filter-included-fields="pickMap.filterOn"
              :sort-by.sync="pickMap.sortBy"
              :sort-desc.sync="pickMap.sortDesc"
              :sort-direction="pickMap.sortDirection"
              stacked="md"
              show-empty
              small
              hover
              @filtered="pickMapOnFiltered"
              @row-clicked="pickMapOnRowClicked"
            >
              <template #head(title)="row">
                {{ row.field[lang] }}
              </template>
              <template #head(type)="row">
                {{ row.field[lang] }}
              </template>
            </b-table>

            <b-row align-h="between">
              <b-col lg="3" sm="4" md="4" class="my-1">
                <ejs-dropdownlist
                  :dataSource="pickMap.pageOptions"
                  :fields="{ text: lang, value: 'value' }"
                  v-model="pickMap.perPage"
                  @change="(val)=>{pickMap.perPage=val.value}"
                ></ejs-dropdownlist>
              </b-col>

              <b-col lg="7" sm="8" md="8" class="my-1">
                <b-pagination
                  v-model="pickMap.currentPage"
                  :total-rows="pickMap.totalRows"
                  :per-page="pickMap.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-container>
      </div>
    </b-modal>
    <!--    <b-modal-->
    <!--      id="modal_for_Form"-->
    <!--      size="xl"-->
    <!--      centered-->
    <!--      :title="markerName"-->
    <!--      hide-header-close-->
    <!--      :ok-title="dict.ok[lang]"-->
    <!--      :cancel-title="dict.cancel[lang]"-->
    <!--      :dir="dict.dir[lang]"-->
    <!--      hide-footer-->
    <!--      header-border-variant="primary"-->
    <!--      header-class="my-modal-header "-->
    <!--    >-->
    <!--      <div>-->
    <!--        <form-widget />-->
    <!--      </div>-->
    <!--    </b-modal>-->
    <data-picker-modal />
  </div>
</template>

<script>
/* eslint-disable */
import { latLng } from "leaflet";
import L from "leaflet";
import "leaflet-rotatedmarker";
import { DialogUtility } from "@syncfusion/ej2-popups";
import FormWidget from "@/components/formWidget.vue";
import DataPickerModal from "@/components/dataManagerModalDataPicker.vue";
import { StepperComponent, StepsDirective, StepDirective } from "@syncfusion/ej2-vue-navigations";
import polyline from "@mapbox/polyline";
import { debounce } from "@/services/data.service";
import {
  AccordionComponent
} from "@syncfusion/ej2-vue-navigations";
import SvgPlak from "@/components/svgPlak.vue";

const photo = require("@/assets/images/steps/FIRE_ckeck.svg");
export default {
  name: "widgetCombinedMapTable",
  components: {
    SvgPlak,
    FormWidget, DataPickerModal,
    "ejs-accordion": AccordionComponent,
    "ejs-stepper": StepperComponent,
    "e-steps": StepsDirective,
    "e-step": StepDirective
  },
  props: {
    plotId: String,
    data: Object,
    editable: Boolean,
    resized: Number,
    updateDOM: Number,
    setting: Boolean,
    sseData: Array,
    archiveData: Array,
    lang: String,
    theme: String
  },
  data() {
    return {
      rowID: [],
      activeRoute: {},
      rowData: {},
      prevPos: [],
      tooltipContent: "<span class=\"template-content\"><span class=\"stepper-icon ${value.iconCss}\"></span><span class=\"step-label\">${value.text}</span></span>",
      tempContent: "<div class=\"template-content\"><span class=\"${step.iconCss}\"></span><br></div>",
      steppers: {
        0: { per: "در انتظار تایید وقوع حریق", eng: "Awaiting confirmation of fire" },
        1: { per: "در حال جستجو و تخصیص منابع", eng: "Searching and allocating resources" },
        2: { per: "در انتظار تایید منابع", eng: "Awaiting source confirmation" },
        3: {
          per: "اعلام عملیات به ایستگاه ها وصدور فرمان شروع عملیات",
          eng: "Announcing the operation to the stations and issuing the command to start the operation"
        },
        4: {
          per: "خروج ماشین از ایستگاه و شروع عملیات ردیابی",
          eng: "Leaving the car from the station and starting the tracking operation"
        },
        5: {
          per: "رسیدن ماشین به محل حادثه و آغاز عملیات اطفا",
          eng: "Arriving the car at the scene of the accident and starting the firefighting operation"
        },
        6: { per: "اتمام اطفا و بازگشت به ایستگاه", eng: "Finish extinguishing and return to the station" },
        7: { per: "خاتمه عملیات", eng: "Termination of operation" }

      },
      isContentVisible: false,
      trafficLightTranslator: {
        "North_South": require("@/assets/images/locations/North_South.svg"),
        "North_East": require("@/assets/images/locations/North_West.svg"),
        "North_West": require("@/assets/images/locations/North_East.svg"),
        "South_North": require("@/assets/images/locations/South_North.svg"),
        "South_East": require("@/assets/images/locations/South_West.svg"),
        "South_West": require("@/assets/images/locations/South_East.svg"),
        "East_North": require("@/assets/images/locations/East_North.svg"),
        "East_West": require("@/assets/images/locations/West_East.svg"),
        "East_South": require("@/assets/images/locations/East_South.svg"),
        "West_South": require("@/assets/images/locations/West_South.svg"),
        "West_North": require("@/assets/images/locations/West_North.svg"),
        "West_East": require("@/assets/images/locations/East_West.svg")
      },
      fireTruckTranslator: {
        "اطفائیه سنگین": require("@/assets/images/cars/fireTrcuk1.png")
      },
      reRenderObj: 0,
      counter: 0,
      activeTempLayer: [],
      createdlayers: [],
      listIdForSse: [],
      list: {},
      activeLayers: [],
      categories: [],
      tempTriggered: false,
      container: null,
      controllerContainer: null,
      markerTypes: {},
      customControl: null,
      customController: null,
      selectedData: {
        id: "",
        name: "",
        type: ""
      },
      selectedDataEvent: {
        id: "",
        name: "",
        type: ""
      },
      activeLayer: {},
      eventRows: {},
      activeTab2: "fireStations",
      layerList: [],
      tempLayerList: [],
      reRender: 0,
      reRenderMap: 0,
      activeTab: "data",
      activeLayersTab: "DB",
      tabList: ["data", "basic"],
      layerTyps: ["DB", "Controller", "eventData"],
      markerName: "",
      mapinit: false,

      hhh: 300,
      modalTitle: { eng: "Edit layers widget", per: "ویرایش ویجت لایه ها" },
      myData: {
        datas: [],
        eventData: [],
        attribute: {
          activeLayers: [],
          center: [0, 0],
          minZoom: 1,
          maxZoom: 25,
          maxNativeZoom: 22,
          tileId: 1,
          viewZoom: 18,
          mapHeight: 100,
          mapWidth: 100,
          dataType: "DB"
        }
      },
      watchlist: [],
      groupFields: [
        { key: "name", label: "" },
        // { key: 'type', label: ''},
        { key: "modify", label: "" }
      ],
      myDataTmp: { datas: [] },
      tabs: [
        { eng: "fire stations", per: "ایستگاه ها", value: "fireStations" },
        { eng: "destination", per: "محل حادثه", value: "destination" },
        { eng: "hydrants", per: "هیدرانت ها", value: "hydrants" },
        { eng: "traffic Lights", per: "چراغ راهنمایی", value: "trafficLights" },
        { eng: "fire trucks", per: "ماشین ها", value: "fireTrucks" },
        { eng: "fire fighters", per: "پرسنل", value: "fireFighters" },
        { eng: "on Route traffic lights", per: "چراغ ترافیک درمسیر", value: "onRouteTLs" },
        { eng: "on Route traffic lights", per: "چراغ ترافیک درمسیر", value: "times" }
      ],
      activeZone: null,
      carousel: false,
      schedule: false,
      group: false,
      control: false,
      carouselData: [],
      levels: null,
      markerLayer: null,
      markers: [],
      mymap: [],
      popup: L.popup(),
      map: {
        center: [0, 0],
        minZoom: "",
        maxZoom: "",
        maxNativeZoom: "",
        tileId: "",
        viewZoom: ""

      },
      tileIdList: [{ text: "Select One", value: null },
        { text: "outdoors", value: 0 },
        { text: "streets", value: 1 },
        { text: "dark", value: 2 },
        { text: "light", value: 3 },
        { text: "satellite", value: 4 },
        { text: "satellite streets", value: 5 },
        { text: "navigation day", value: 6 },
        { text: "navigation night", value: 7 }],
      dataLayersType: [{ per: "", eng: "Select One", value: null },
        { per: "لایه های ثابت", eng: "static", value: "static" },
        { per: "لیست لایه های متغیر", eng: "dynamic", value: "dynamic" }
      ],
      tiles: ["outdoors-v11",
        "streets-v11",
        "dark-v10",
        "light-v10",
        "satellite-v9",
        "satellite-streets-v11",
        "navigation-day-v1",
        "navigation-night-v1"],
      equipments: [],
      pickMap: {
        to: "",
        type: "data",
        items: [],
        fields: [
          { key: "type", eng: "type", per: "نوع", label: "type", sortable: true, sortDirection: "asc" },
          { key: "title", eng: "name", per: "نام", label: "name", sortable: true, sortDirection: "asc" }
          // { key: '_id', label: 'id', sortable: true },
        ],
        contFields: [
          {
            key: "key",
            eng: "choose data",
            per: "انتخاب داده لایه",
            label: "data",
            sortable: true,
            sortDirection: "asc"
          },
          { key: "type", eng: "type", per: "نوع", label: "type", sortable: true, sortDirection: "asc" },
          { key: "title", eng: "name", per: "نام", label: "name", sortable: true, sortDirection: "asc" }
          // { key: '_id', label: 'id', sortable: true },
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [{ value: 5, per: "5 ردیف", eng: "5 rows" },
          { value: 10, per: "10 ردیف", eng: "10 rows" },
          { value: 15, per: "15 ردیف", eng: "15 rows" },
          { value: 100, per: "100 ردیف", eng: "Show a lot" }],
        sortBy: "name",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        filterData: {},
        modalTitle: { eng: "select layers", per: "انتخاب لایه ها" }
      },
      selectedForm: {},
      dict: {
        canceledEvent: { eng: "incident canceled!", per: "رد عملیات و حادثه!" },
        "registration number": { eng: "registration number ", per: "شماره ساخت " },
        "serial number": { eng: "serial number ", per: "شماره سریال " },
        "tank level": { eng: "tank level", per: "سطح آب" },
        commands: { eng: "commands", per: "دستورات" },
        state: { eng: "state", per: "وضعیت" },
        cross: { eng: "cross", per: "نوع" },
        type: { eng: "type", per: "نوع" },
        name: { eng: "name : ", per: "نام :  " },
        location: { eng: "location : ", per: "محل :  " },
        situation: { eng: "situation : ", per: "وضعیت :  " },
        approaching: { eng: "approaching", per: "در حال عبور   " },
        passed: { eng: "passed", per: "عبور کرده   " },
        incident: { eng: "incident no  ", per: "حادثه شماره   " },
        chosenStation: { eng: "chosen station : ", per: "ایستگاه انتخابی :  " },
        chosenTrucks: { eng: "chosen truck : ", per: "خودرو انتخابی :  " },
        chosenRoute: { eng: "chosen route :  ", per: "مسیر انتخابی :  " },
        chosenTime: { eng: "Estimated time : ", per: "زمان تخمینی : " },
        chosenWay: { eng: "Estimated distance : ", per: "مسافت تخمینی : " },
        resourceConf: { eng: "Confirmation of sources of incident no ", per: "تایید منابع حادثه شماره  " },
        coord: { eng: "coordinator name :", per: "نام نماینده ساختمان : " },
        confirmEve: { eng: "Do you confirm the incident?", per: "آیا حادثه را تایید می کنید؟ " },
        address: { eng: "building address :", per: "آدرس ساختمان : " },
        phone: { eng: "coordinator phone :", per: "شماره تماس : " },
        building: { eng: "building name :", per: "نام ساختمان : " },
        firedAlarm: { eng: "Fire detection in the building ", per: "تشخیص حریق در ساختمان " },
        event: { eng: "event", per: "حادثه" },
        modalTitle: { eng: "Edit widget", per: "ویرایش ویجت" },
        fireStations: { eng: "fire stations", per: "ایستگاه ها" },
        destination: { eng: "destination", per: "محل حادثه" },
        hydrants: { eng: "hydrants", per: "هیدرانت ها" },
        trafficLights: { eng: "traffic Lights", per: "چراغ راهنمایی" },
        fireTrucks: { eng: "fire trucks", per: "ماشین ها" },
        fireFighters: { eng: "fire fighters", per: "پرسنل" },
        onRouteTLs: { eng: "traffic lights", per: "چراغ های ترافیک" },
        currentEvents: { eng: "current events", per: "حوادث جاری" },
        Eventtitle: { eng: "current events", per: "حوادث جاری" },
        eventData: { per: "داده ی رخداد", eng: "event Data" },
        pickData: { per: "انتخاب داده", eng: "Pick Data" },
        showMarkerInfo: { eng: "more details", per: "اطلاعات بیشتر" },
        dir: { eng: "ltr", per: "rtl" },
        deleteConfirm: { eng: "Confirmation Delete", per: "تاییدیه حذف" },
        deleteConfirmContent: {
          eng: "Are you sure to remove the data ?",
          per: "آیا از حذف این رکورد اطمینان دارید ؟"
        },
        search: { per: "جستجو", eng: "search" },
        title: { eng: "title", per: "عنوان" },
        data: { eng: "data", per: "داده" },
        dynamic: { eng: "layers type", per: "نوع لایه ها" },
        basic: { eng: "basic", per: "تنظیمات پایه" },
        cancel: { eng: "cancel", per: "لغو" },
        ok: { eng: "ok", per: "تایید" },
        lat: { eng: "latitude", per: "عرض جغرافیایی" },
        lng: { eng: "longitude", per: "طول جغرافیایی" },
        mapTile: { eng: "map tile", per: "تایل نقشه" },
        minZoom: { eng: "Minimum Zoom", per: "حداقل بزرگنمایی" },
        maxZoom: { eng: "Maximum Zoom", per: "حداکثر بزرگنمایی" },
        nativeZoom: { eng: "Max. Native Zoom", per: "حداکثر بزرگنمایی نقشه" },
        viewZoom: { eng: "View Zoom", per: "بزرگنمایی نقشه" },
        DB: { eng: "permanent layers", per: "لایه های دائمی " },
        Controller: { eng: "temporary layer", per: "لایه های موقت" },
        brand: { eng: "brand", per: "برند" },
        station: { eng: "station", per: "ایستگاه" },
        model: { eng: "model", per: "مدل" },
        color: { eng: "color", per: "رنگ" },
        code: { eng: "code", per: "کد" }
      },
      currentCircles: [],
      submitted: false,
      successful: false,
      message: "null",
      run: 0,
      counterRow: 0,
      datasID: []
    };
  },
  methods: {
    computeCommands(commands) {
      let list = [];
      commands.forEach((item) => {
        list.push({
          time: this.getIrDate(item.timeStamp, this.lang),
          command: item.command
        });
      });

      return list;
    },
    isDataExists(key, newData) {
      const newDataString = JSON.stringify(newData);
      return Object.values(this.list[key]).some(existingData => JSON.stringify(existingData) === newDataString);
    },
    showTheMarker(item) {
      if (this.currentCircles.length > 0) {
        this.currentCircles.forEach(circle => this.mymap.removeLayer(circle));
        this.currentCircles = [];
        clearInterval();
      }

      if (this.currentCircles.length <= 0) {
        const coordinates = item.split(",").map(Number);
        const baseRadius = 10;
        const numCircles = 8;
        const opacityStep = 0.1;

        for (let i = 0; i < numCircles; i++) {
          const radius = baseRadius + (i * 10);
          const opacity = 1 - (i * opacityStep);

          const circle = L.circle(coordinates, {
            color: "red",
            fillColor: "#7092e1",
            fillOpacity: opacity,
            radius: radius,
            stroke: false
          }).addTo(this.mymap);

          this.currentCircles.push(circle);
        }

        let step = 0;
        const interval = setInterval(() => {
          this.currentCircles.forEach((circle, index) => {
            const newOpacity = Math.max(0, 1 - ((step + index) % numCircles) * opacityStep);
            circle.setStyle({ fillOpacity: newOpacity });
          });
          step++;
        }, 300);

        this.mymap.setView(coordinates, 16);
      }
    },
    watchLayer(item) {
      if (this.activeLayers.indexOf(item.id) <= -1) {
        this.activeLayers.push(item.id);
      } else {
        this.activeLayers.splice(this.activeLayers.indexOf(item.id), 1);
        this.removeLayerByID(item.id);
      }
    },
    toggleContent(index) {
      this.activeTab2 = "fireStations";
      this.isContentVisible = this.isContentVisible === index ? null : index;
    },
    filterKeys(data, keysToRemove) {
      if (Array.isArray(data)) {
        return data.filter(([key]) => !keysToRemove.includes(key));
      } else if (typeof data === "object") {
        return Object.keys(data).filter(key => !keysToRemove.includes(key)).reduce((acc, key) => {
          acc[key] = data[key];
          return acc;
        }, {});
      }
      return data;
    },
    getValueTLS(key, arg) {
      return arg;
    },
    async generateNewRow(ids, Datas, colId) {
      let extraID = [];
      let listIDS = ids;

      if (this.datasID.length <= 0) {
        this.datasID = await this.getMarkerData(ids);
      } else if (this.datasID.length > 0) {
        ids.forEach(item => {
          if (this.rowID.indexOf(item) <= -1) {
            extraID.push(item);
          }
        });

        if (extraID.length > 0) {
          extraID.forEach(item => {
            if (this.listIdForSse.indexOf(item) <= -1) {
              this.listIdForSse.push(item);
            }

          });
          listIDS = listIDS.concat(extraID);
          this.datasID = await this.getMarkerData(ids);
        }
      }


      ids.forEach(item => {
        if (this.listIdForSse.indexOf(item) <= -1) {
          this.listIdForSse.push(item);
        }
      });


      const row = this.datasID;
      Object.entries(Datas).forEach(([key, value]) => {
        let rowObj = {};
        if (Array.isArray(value)) {
          value.forEach(x => {
            if (x[1] != null) {
              row.forEach((item) => {
                if (Array.isArray(x[1])) {
                  if (x[0] == "times") {
                    rowObj[x[0]] = x[1];
                  } else {
                    x[1].forEach(prop2 => {
                      if (prop2 == item.id) {
                        let colObj = {};
                        item.value.forEach(valueItem => {
                          colObj[valueItem.name] = valueItem.value;
                        });

                        if (!rowObj[x[0]]) {
                          rowObj[x[0]] = {};
                        }
                        rowObj[x[0]] = { ...rowObj[x[0]], [prop2]: colObj };
                      }
                    });
                  }

                } else if (typeof x[1] == "string") {
                  if (x[0] == "status" || x[0] == "fireApproval" || x[0] == "atributeApproval") {
                    rowObj[x[0]] = x[1];
                  } else {
                    if (x[1] == item.id) {
                      if (Array.isArray(item.value)) {
                        let newRow1 = {};
                        item.value.forEach(valueItem => {
                          newRow1[valueItem.name] = valueItem.value;
                        });
                        rowObj = { ...rowObj, [x[0]]: newRow1 };
                      }
                    } else if (x[0] != "destination" && x[1] != item.id) {
                      rowObj = { ...rowObj, [x[0]]: x[1] };
                    }
                  }


                } else if (typeof x[1] != "string" && !Array.isArray(x[1]) && x[1] != null) {
                  if (x[0] == "status" || x[0] == "fireApproval" || x[0] == "atributeApproval") {
                    rowObj[x[0]] = x[1];
                  } else {
                    let newRow2 = {};
                    Object.entries(x[1]).forEach((subObj) => {
                      let crossRoute = "";
                      if ("cross" in subObj[1] && "approach" in subObj[1].cross && "exit" in subObj[1].cross) {
                        crossRoute = subObj[1].cross["approach"] + "_" + subObj[1].cross["exit"];
                      }
                      if (crossRoute != "" && crossRoute != undefined && crossRoute.length > 0) {
                        subObj[1]["cross"]["finalWay"] = crossRoute;
                        newRow2[subObj[0]] = subObj[1];
                      } else {
                        subObj[1]["cross"]["finalWay"] = "";
                        newRow2[subObj[0]] = subObj[1];
                      }
                    });
                    rowObj = { ...rowObj, [x[0]]: newRow2 };
                  }
                }
                // else if (x[1] == null) {
                //   rowObj = { ...rowObj, [x[0]]: x[1] };
                // }
              });
            }
          });
        }
        this.$set(this.eventRows, key, rowObj);
        this.reRender += 1;
      });

      this.reRenderObj += 1;
    },
    formatDateTime(date, option, lang) {
      if (lang == "per")
        return new Intl.DateTimeFormat("fa-IR", option).format(date);
      if (lang == "eng")
        return new Intl.DateTimeFormat("en-Us", option).format(date);
    },
    getIrDate(idate, lang) {
      const date = new Date(idate);
      if (lang === "eng") {
        var time1 = this.formatDateTime(
          date,
          {
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
          },
          this.lang
        );
        var year1 = this.formatDateTime(date, { year: "numeric" }, this.lang);
        var month1 = this.formatDateTime(date, { month: "long" }, this.lang);
        var wday1 = this.formatDateTime(date, { weekday: "long" }, this.lang);
        var day1 = this.formatDateTime(date, { day: "numeric" }, this.lang);
        var str1 = `${wday1} ${day1} ${month1} ${year1} at  ${time1}`;
        return str1;
      } else if (lang === "per") {
        var time = this.formatDateTime(
          date,
          {
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
          },
          this.lang
        );
        var year = this.formatDateTime(date, { year: "numeric" }, this.lang);
        var month = this.formatDateTime(date, { month: "long" }, this.lang);
        var wday = this.formatDateTime(date, { weekday: "long" }, this.lang);
        var day = this.formatDateTime(date, { day: "numeric" }, this.lang);
        var str = `${wday} ${day} ${month} ${year} ساعت  ${time}`;
        return str;
      }
    },
    processList(val, id) {
      this.counterRow += 1;

      let datas = [];
      let rows = [];
      if ("times" in val && "id" in val || "status" in val) {
        Object.entries(val).forEach(subItem => {
          if (!["mapLayer", "routes", "origin", "id", "times", "status", "fireApproval", "atributeApproval", "title", "destinationLoc"].includes(subItem[0])) {
            if (Array.isArray(subItem[1])) {
              subItem[1].forEach((subArr) => {
                if (typeof subArr != "object" && subArr != null) {
                  if (datas.indexOf(subArr) <= -1)
                    datas.push(subArr);
                }
              });
            } else if (subItem[1] != null && subItem[0] != "onRouteTLs") {
              if (datas.indexOf(subItem[1]) <= -1)
                datas.push(subItem[1]);
            }
            rows.push(subItem);
          } else if (["times", "status", "fireApproval", "atributeApproval", "id", "title", "destinationLoc"].includes(subItem[0])) {
            rows.push(subItem);
          }
        });
      }

      this.rowID = this.rowID.concat(datas);
      if (rows.length > 0)
        this.rowData[id] = rows;
      if (this.counterRow == Object.keys(this.list).length) {
        this.generateNewRow(this.rowID, this.rowData, id);
        this.counterRow = 0;
        // this.rowID = [];
        // this.rowData = [];
      }
    },
    widthUpdated() {
      debounce(() => {
        this.reRender += 1;
      }, 2000);
    },
    updateDropdown() {
      const vm = this;
      var dropdown = vm.container;
      if (dropdown != null) {
        dropdown.innerHTML = "";
        vm.layerList.forEach(function(item, index) {
          var option = L.DomUtil.create("div", "", dropdown);
          option.style.width = "auto";
          option.style.height = "auto";
          option.style.backgroundColor = "white";
          option.style.boxShadow = "0 2px 5px rgba(0,0,0,0.2)";
          option.style.display = "block";
          option.style.alignItems = "right";
          option.style.justifyContent = "center";
          option.style.cursor = "pointer";
          option.style.textAlign = "right";
          option.style.marginTop = "5px";
          option.style.padding = "5px";
          option.innerHTML = item.title != null ? item.title : "";
          option.onmouseover = function() {
            option.style.backgroundColor = "#f0f0f0";
            if (vm.myData.attribute.activeLayers.indexOf(item._id) > -1) {
              option.style.backgroundColor = "rgba(129,131,232,0.8)";
              option.style.color = "white";
            } else {
              option.style.backgroundColor = "#f0f0f0";
              option.style.color = "black";
            }
          };
          option.onmouseout = function() {
            option.style.backgroundColor = "white";
            if (vm.myData.attribute.activeLayers.indexOf(item._id) > -1) {
              option.style.backgroundColor = "rgba(129,131,232,0.8)";
              option.style.color = "white";
            } else {
              option.style.backgroundColor = "#f0f0f0";
              option.style.color = "black";
            }
          };
          option.onclick = function(e) {
            e.stopPropagation();
            vm.tempTriggered = false;
            if (vm.myData.attribute.activeLayers.indexOf(item._id) > -1)
              vm.myData.attribute.activeLayers.splice(vm.myData.attribute.activeLayers.indexOf(item._id), 1);
            else {
              vm.tempTriggered = false;
              vm.myData.attribute.activeLayers.push(item._id);
              option.style.backgroundColor = "rgba(129,131,232,0.8)";
              option.style.color = "white";
            }
          };
          dropdown.appendChild(option);
        });
      }
    },
    createCustomCtrl() {
      const vm = this;
      this.customControl = L.Control.extend({
        options: {
          position: "topright"
        },

        onAdd: (map) => {
          var container = L.DomUtil.create("div", "leaflet-bar leaflet-control leaflet-control-custom");
          container.id = this.plotId;
          container.style.position = "relative";
          container.style.backgroundColor = "white";
          container.style.width = "auto";
          container.style.height = "auto";
          container.style.padding = "3px 6px";
          container.style.borderRadius = "5%";
          container.style.boxShadow = "0 2px 5px rgba(0,0,0,0.3)";
          container.style.cursor = "pointer";
          container.style.display = "flex";
          container.style.alignItems = "center";
          container.style.justifyContent = "center";

          container.innerHTML = `<span style=\"font-size: 12px;\">${this.dict.DB[this.lang]}</span>`;

          var dropdown = L.DomUtil.create("div", "", container);
          this.container = dropdown;
          dropdown.style.display = "none";
          dropdown.style.position = "absolute";
          dropdown.style.top = "40px";
          dropdown.style.right = "0px";
          dropdown.style.alignItems = "left";
          dropdown.style.backgroundColor = "transparent";
          dropdown.style.transition = "opacity 0.3s ease-in-out, transform 0.3s ease-in-out";
          dropdown.style.opacity = "0";
          dropdown.style.whiteSpace = "nowrap";

          this.layerList.filter(x => x.type == "marker").forEach(function(item, index) {
            var option = L.DomUtil.create("div", "", dropdown);
            option.style.width = "auto";
            option.style.height = "auto";
            option.style.backgroundColor = "white";
            option.style.boxShadow = "0 2px 5px rgba(0,0,0,0.2)";
            option.style.display = "block";
            option.style.alignItems = "right";
            option.style.justifyContent = "center";
            option.style.cursor = "pointer";
            option.style.textAlign = "right";
            option.style.marginTop = "0px";
            option.style.padding = "5px";
            option.innerHTML = item.title != null ? item.title : "";

            var checkbox = L.DomUtil.create("input", "", option);
            checkbox.type = "checkbox";
            checkbox.style.marginRight = "10px";
            checkbox.style.marginLeft = "10px";
            checkbox.style.marginTop = "5px";
            checkbox.checked = vm.myData.attribute.activeLayers.indexOf(item._id) > -1 ? true : false;


            // option.onmouseover = function() {
            //   if (vm.activeLayers.indexOf(item._id) > -1) {
            //   } else {
            //     option.style.backgroundColor = "#f0f0f0";
            //     option.style.color = "black";
            //   }
            // };
            // option.onmouseout = function() {
            //   if (vm.activeLayers.indexOf(item._id) > -1) {
            //     option.style.backgroundColor = "rgba(129,131,232,0.8)";
            //     option.style.color = "white";
            //   } else {
            //     option.style.backgroundColor = "#f0f0f0";
            //     option.style.color = "black";
            //   }
            // };

            option.onclick = function(e) {
              e.stopPropagation();
              e.stopImmediatePropagation();
              vm.tempTriggered = false;
              if (vm.myData.attribute.activeLayers.indexOf(item._id) > -1) {
                vm.myData.attribute.activeLayers.splice(vm.myData.attribute.activeLayers.indexOf(item._id), 1);
                checkbox.checked = false;
              } else {
                vm.tempTriggered = false;
                checkbox.checked = true;
                vm.myData.attribute.activeLayers.push(item._id);
                // option.style.backgroundColor = "rgba(129,131,232,0.8)";
                // option.style.color = "white";
              }
            };

            dropdown.appendChild(option);
          });

          container.onclick = function(e) {
            e.stopPropagation();
            if (dropdown.style.display === "none" || dropdown.style.opacity === "0") {
              dropdown.style.display = "block";
              setTimeout(() => {
                dropdown.style.opacity = "1";
                dropdown.style.transform = "translateY(0)";
              }, 10);
            } else {
              dropdown.style.opacity = "0";
              dropdown.style.transform = "translateY(-10px)";
              setTimeout(() => {
                dropdown.style.display = "none";
              }, 300);
            }
          };

          var submitButton = L.DomUtil.create("button", "", dropdown);
          submitButton.style.width = "100%";
          submitButton.style.padding = "5px";
          submitButton.style.marginTop = "10px";
          submitButton.style.backgroundColor = "#007bff";
          submitButton.style.color = "white";
          submitButton.style.border = "none";
          submitButton.style.cursor = "pointer";
          submitButton.innerHTML = "ذخیره لایه ها";

          submitButton.onclick = function(e) {
            e.stopPropagation();
            e.stopImmediatePropagation();
            vm.handleSubmit();
          };

          container.onclick = function(e) {
            e.stopPropagation();
            if (dropdown.style.display === "none" || dropdown.style.opacity === "0") {
              dropdown.style.display = "block";
              setTimeout(() => {
                dropdown.style.opacity = "1";
                dropdown.style.transform = "translateY(0)";
              }, 10);
            } else {
              dropdown.style.opacity = "0";
              dropdown.style.transform = "translateY(-10px)";
              setTimeout(() => {
                dropdown.style.display = "none";
              }, 300);
            }
          };

          // Close the dropdown when clicking outside
          map.on("click", function() {
            dropdown.style.opacity = "0";
            dropdown.style.transform = "translateY(-10px)";
            setTimeout(() => {
              dropdown.style.display = "none";
            }, 300);
          });

          return container;
        }
      });

      setTimeout(() => {
        this.mymap.addControl(new this.customControl());
        // vm.changeLayer(this.layerList[0]);
      }, 300);
    },
    pickData() {
      this.$store.dispatch("chpge/parent2child", {
        task: "pickedData",
        from: "widgetCombinedMap" + this.plotId,
        filter: {
          dataType: ["freeArray"],
          usedBy: [],
          parents: [],
          archive: null,
          perPage: 5000,
          page: 1,
          withChild: true
        }
      });
      this.$root.$emit("bv::show::modal", "modal-data-picker");
    },
    pickDataEvent() {
      this.$store.dispatch("chpge/parent2child", {
        task: "pickedData",
        from: "widgetCombinedMapEvent" + this.plotId,
        filter: {
          dataType: ["freeObject"],
          usedBy: [],
          parents: [],
          archive: null,
          perPage: 5000,
          page: 1,
          withChild: true
        }
      });
      this.$root.$emit("bv::show::modal", "modal-data-picker");
    },
    getData(id) {
      this.$store.dispatch("panel/getData", { id: id }).then((res) => {
        res.forEach((item => {
          if (Array.isArray(item.value) && item.value.length > 0) {
            item.value.forEach(subValue => {
              // this.createMarkers(subValue);
              if (this.tempLayerList.length > 0) {
                let foundedItem = this.tempLayerList.find(x => x._id == subValue._id);
                if (foundedItem != undefined)
                  foundedItem = subValue;
                else this.tempLayerList.push(subValue);
              } else this.tempLayerList.push(subValue);

            });
          } else if (Object.keys(item.value).length > 0) {
            // this.createMarkers(item.value);
            this.tempLayerList.push(item.value);
          }
        }));
      });
    },
    async getMarkerData(id) {
      try {
        const res = await this.$store.dispatch("panel/getData", { id: id });
        return res;
      } catch (error) {
        console.error("An error occurred:", error);
        return null;
      }
    },
    async getCategoryData(id) {
      try {
        const res = await this.categories.find(item => item._id == id).value;
        return res;
      } catch (error) {
        console.error("An error occurred:", error);
        return null;
      }
    },
    async getMarkerTypes(id) {
      try {
        const res = await this.$store.dispatch("data/getMarkerTypeById", { _id: id });
        return res;
      } catch (error) {
        console.error("An error occurred:", error);
        return null;
      }
    },
    removeLayerByID(layerId) {
      this.mymap.eachLayer((layer) => {
        if (layer.layerId === layerId) {
          this.mymap.removeLayer(layer);
        }
      });
    },
    changeLayer(item, delMark) {
      let tileLayers = [];
      this.mymap.eachLayer((layer) => {
        if (delMark != undefined && delMark == true) {
          if (layer instanceof L.Marker)
            this.mymap.removeLayer(layer);
        }
        if (layer instanceof L.Polyline) {
          this.mymap.removeLayer(layer);
        }
        if (layer instanceof L.TileLayer) {
          tileLayers.push(layer);
        }
      });
      if (tileLayers.length >= 1 && item != "none") {
        this.createMarkers(item);
        // if ("map" in item.details)
        //   this.mymap.setView(item.details.map.center, item.details.map.viewZoom);
        // else this.mymap.setView(this.myData.attribute.center, this.myData.attribute.viewZoom);
      }
    },
    calculateBearing(lat1, lon1, lat2, lon2) {
      const toRadians = (deg) => (deg * Math.PI) / 180;
      const toDegrees = (rad) => (rad * 180) / Math.PI;
      const φ1 = toRadians(lat1);
      const φ2 = toRadians(lat2);
      const Δλ = toRadians(lon2 - lon1);
      const y = Math.sin(Δλ) * Math.cos(φ2);
      const x = Math.cos(φ1) * Math.sin(φ2) -
        Math.sin(φ1) * Math.cos(φ2) * Math.cos(Δλ);
      let θ = Math.atan2(y, x);
      θ = toDegrees(θ);
      return (θ + 360) % 360;
    },
    updateVehicleMarker(vehicleMarker, prevPosition, currentPosition) {
      const [currLat, currLng] = currentPosition;

      let bearing = 0;
      if (prevPosition.length > 0) {
        const [prevLat, prevLng] = prevPosition;
        bearing = this.calculateBearing(prevLat, prevLng, currLat, currLng);
      }

      vehicleMarker.setRotationAngle(bearing);
      vehicleMarker.setLatLng([currLat, currLng]);

      this.prevPos = [currLat, currLng];
    },
    runPolyLine(routes) {
      const encodedPolyline = [];

      routes.forEach((route) => {
        route.legs.forEach(step => {
          step.steps.forEach((line) => {
            encodedPolyline.push(line.polyline);
          });
        });
      });

      const polylineOptionsBorder = {
        opacity: 0.8,
        lineJoin: "round",
        lineCap: "round",
        smoothFactor: 0.1,
        interactive: false,
        stroke: true,
        color: "#3a41f3",
        dashArray: null,
        weight: 10
      };

      const polylineOptions = {
        color: "white",
        weight: 5,
        opacity: 1,
        lineJoin: "round",
        lineCap: "round",
        smoothFactor: 0.1,
        interactive: true
      };

      const coordinates = [];
      encodedPolyline.forEach((item) => {
        coordinates.push(polyline.decode(item));
      });

      const polyLine = L.polyline(coordinates, polylineOptionsBorder).addTo(this.mymap);
      const polyLine2 = L.polyline(coordinates, polylineOptions).addTo(this.mymap);
      this.markerLayer.addLayer(polyLine);
      this.markerLayer.addLayer(polyLine2);
      this.mymap.fitBounds(L.polyline(coordinates).getBounds());

    },
    zoneOver: function(e) {
      e.target.setStyle({ fillColorPrev: e.target.options.fillColor });
      e.target.setStyle({ fillColor: "#96ceb4" });
    },
    zoneLeft: function(e) {
      e.target.setStyle({ fillColor: e.target.options.fillColorPrev });
    },
    zoneClick: function(e) {

      if (e.target.options.onClick.type === "page") {
      }

      this.$store.dispatch("chpge/changeDashPage", e.target.options.onClick.value);
    },
    async getMarkerTypeList(markerList) {
      let ID = [];
      markerList.forEach((item) => ID.push(item.typeId));
      const typeList = await this.getMarkerTypes(ID);
      return typeList;
    },
    async getDataList(markerList) {
      let ID = [];
      let catsID = [];

      markerList.forEach((item) => {
        if (!("isCategory" in item) || item.isCategory === false) {
          ID.push(item.data.id);
        }
      });

      let dataList = await this.getMarkerData(ID);
      return dataList;
    },
    async getDataCategory(markerList) {
      let ID = [];
      let lists = markerList.filter((item) => item.isCategory);

      if (lists.length > 0) {
        ID = await Promise.all(lists.map((item) => this.getCategoryData(item.data)));
      }
      if (ID.length > 0) {
        ID = ID.reduce((acc, val) => acc.concat(val), []);
      }
      const result = await this.getMarkerData(ID);
      return result;
    },

    async processMarkersForLayer(markerList, layerId, layer) {
      // this.mymap.setView(this.mymap.getCenter(), 20);

      var datas = [];
      const markerTypeList = await this.getMarkerTypeList(markerList);
      // const dataList = await this.getDataList(markerList);
      // let list = await this.getDataCategory(markerList);

      for (const item of markerList) {
        // if (Array.isArray(markerTypeList))
        const markerType = markerTypeList.find(x => x._id == item.typeId);

        let createIcon = {
          "iconUrl": markerType.details.properties.icon,
          "iconSize": [markerType.details.properties.width || 30, markerType.details.properties.height || 30],
          "iconAnchor": [markerType.details.properties.width / 2, markerType.details.properties.height / 2],
          "popupAnchor": [0, -10],
          "shadowAnchor": [0, 0],
          "shadowSize": [10, 10]
        };
        if (!("isCategory" in item) || !item.isCategory) {
          try {
            let ID = typeof item.data != "string" ? item.data.id : item.data;
            const value = await this.getMarkerData([ID]);
            let marker;
            value[0].value.forEach(x => {
              let foundedItem2 = value[0].value.find(x => x.name == markerType.details.properties.name);
              if (x.name == markerType.details.properties.locationProperty && x.value.length > 0) {
                datas.push(ID + "_" + x.id);

                marker = L.marker(x.value.split(","), {
                  icon: L.icon(createIcon),
                  rotationAngle: markerType.name.includes("truck") ? 45 : 0,
                  draggable: false,
                  isTruck: markerType.name.includes("truck"),
                  id: item.id
                }).bindPopup(foundedItem2 ? foundedItem2.value : "").openPopup();
                marker.addTo(this.mymap);
                this.watchlist.push({
                  type: "movable",
                  icon: item.id,
                  parent: ID,
                  data: x.id
                });
                this.markerLayer.addLayer(marker);
              }
            });
            if (markerType.details.variableIcon.enable) {
              let foundedItem;
              if (Array.isArray(value)) {
                value.forEach((x) => {
                  foundedItem = x.value.find(y => y.name == markerType.details.variableIcon.propertyName);
                });
              } else
                foundedItem = value.find(x => x.name == markerType.details.variableIcon.propertyName);
              this.watchlist.push({
                type: "variable",
                name: markerType.name,
                icon: item.id,
                parent: ID,
                data: foundedItem.id,
                values: markerType.details.variableIcon.shapes
              });
              datas.push(ID + "_" + foundedItem.id);
            }
            if ("onClick" in markerType.details && markerType.details.onClick.enable == true) {
              if (markerType.details.onClick.type != "form") {
                marker.bindPopup(`
                <div class="d-flex">
                  <i class="text-info mx-2 fas fa-plus-square" id="detail-${item.id}" data-id="${item.id}">
                    <p class="text-dark">${markerType.details.properties.name}</p>
                    ${this.dict.showMarkerInfo[this.lang]}
                  </i>
           </div>

          `).on("popupopen", () => {
                  document.getElementById(`detail-${item.id}`).addEventListener("click", (e) => {
                    console.log("click");
                    // this.$store.dispatch("chpge/changeDashPage", item.onClick.value);
                  });
                });
              } else {
                marker.bindPopup(`
          <div class="col-12">
            <i class="text-info mx-2 fas fa-plus-square" id="form-${item.id}">
            ${markerType.details.onClick.type}
            </i>
            </div>

          `).on("popupopen", () => {
                  document.getElementById(`form-${item.id}`).addEventListener("click", (e) => {
                    console.log("form clicked");
                    // this.$bvModal.show("modal_for_Form");
                    // this.markerName = item.name;
                    // this.getForm(item.onClick.form.id, item.onClick.form.value);
                  });
                });
              }
            }
          } catch (error) {
            console.error("An error occurred:", error);
          }
        }
        if (item.isCategory) {
          try {
            let list;
            const values = await this.getCategoryData(item.data);
            if (values.length > 0) {
              list = await this.getMarkerData(values);
            }
            list.forEach((value) => {
              let marker;
              value.value.forEach(x => {
                let foundedItem2 = value.value.find(x => x.name == markerType.details.properties.name);
                if (x.name == markerType.details.properties.locationProperty && x.value.length > 0) {
                  datas.push(value.id + "_" + x.id);

                  marker = L.marker(x.value.split(","), {
                    icon: L.icon(createIcon),
                    draggable: false,
                    rotationAngle: markerType.name.includes("truck") ? 45 : 0,
                    isTruck: markerType.name.includes("truck"),
                    id: value.id
                  }).bindPopup(foundedItem2 ? foundedItem2.value : "").openPopup();
                  marker.addTo(this.mymap);
                  this.watchlist.push({
                    type: "movable",
                    icon: value.id,
                    parent: value.id,
                    data: x.id
                  });
                  this.markerLayer.addLayer(marker);
                }
              });
              if (markerType.details.variableIcon.enable) {
                let foundedItem = value.value.find(x => x.name == markerType.details.variableIcon.propertyName);
                this.watchlist.push({
                  type: "variable",
                  icon: value.id,
                  parent: value.id,
                  data: foundedItem.id,
                  values: markerType.details.variableIcon.shapes
                });
                datas.push(value.id + "_" + foundedItem.id);
              }
              if ("onClick" in markerType.details && markerType.details.onClick.enable == true) {
                if (markerType.details.onClick.type != "form") {
                  marker.bindPopup(`
                    <div class="d-flex">
                      <i class="text-info mx-2 fas fa-plus-square" id="detail-${item.id}" data-id="${item.id}">
                        <p class="text-dark">${markerType.details.properties.name}</p>
                        ${this.dict.showMarkerInfo[this.lang]}
                      </i>
               </div>

              `).on("popupopen", () => {
                    document.getElementById(`detail-${item.id}`).addEventListener("click", (e) => {
                      console.log("click");
                      // this.$store.dispatch("chpge/changeDashPage", item.onClick.value);
                    });
                  });
                } else {
                  marker.bindPopup(`
              <div class="col-12">
                <i class="text-info mx-2 fas fa-plus-square" id="form-${item.id}">
                ${markerType.details.onClick.type}
                </i>
                </div>

              `).on("popupopen", () => {
                    document.getElementById(`form-${item.id}`).addEventListener("click", (e) => {
                    });
                  });
                }
              }
            });


          } catch (error) {
            console.error("An error occurred:", error);
          }
        }
      }
      if (this.watchlist.length > 0) {
        datas.forEach(item => {
          if (this.listIdForSse.indexOf(item) <= -1) {
            this.listIdForSse.push(item);
          }
        });
        this.counter += 1;


      }
    },
    createMarkers: function(layer) {
      this.markerLayer = new L.layerGroup;
      if (layer != undefined) {
        this.markerLayer["layerId"] = layer._id;

        this.markerLayer.addTo(this.mymap);
        this.createdlayers.push(layer._id);
        var markerList = layer.details.markers;

        this.processMarkersForLayer(markerList, layer._id, layer);
        if ("routes" in layer) {
          this.activeRoute = layer;
          this.runPolyLine(layer.routes);
        }
      }

    },
    removeMarkers: function(spaces) {
      var keys = Object.keys(this.levels),
        i = keys.length;

      for (i in keys) {
        for (var j = 0; j < this.markers.length; j++) {
          if (keys[i] === this.markers[j].options.id.floor)
            this.levels[keys[i]].removeLayer(this.markers[j]);
        }
      }
    },
    onMapZoom: function(e) {
      var zoomlevel = this.mymap.getZoom();
      if (zoomlevel > 21) {
        this.addMarkers();
      } else {
        this.removeMarkers();
      }
    },
    setupLeafletMap: function() {
      this.mymap = L.map(this.plotId);
      this.map.center = this.myData.attribute.center;
      this.map.minZoom = this.myData.attribute.minZoom;
      this.map.maxZoom = this.myData.attribute.maxZoom;
      this.map.maxNativeZoom = this.myData.attribute.maxNativeZoom;
      this.map.tileId = this.myData.attribute.tileId;
      this.map.viewZoom = this.myData.attribute.viewZoom;
      this.createMap();
      if (this.myData.datas.length > 0) {
        let counter = 0;
        let layer = [];

        for (var i = 0; i < this.myData.datas.length; i++) {
          if (this.myData.datas[i].category == "DB") {
            this.getfloorMap(this.myData.datas[i].id);
            counter++;
          } else if (this.myData.datas[i].category != "DB") {
            counter++;
            layer.push(this.myData.datas[i].id);
          }
        }
        // if (counter === this.myData.datas.length) {
        //   this.getData(layer); // Call this.getData() after loop finishes
        // }
      }
    },
    addMarkers: function() {
      var keys = Object.keys(this.levels),
        i = keys.length;

      for (i in keys) {
        for (var j = 0; j < this.markers.length; j++) {
          if (keys[i] === this.markers[j].options.id.floor)
            this.levels[keys[i]].addLayer(this.markers[j]);
        }
      }
    },
    createMap: function() {
      this.mymap.setView(this.map.center, this.map.viewZoom);
      var layers = [];
      this.mymap.eachLayer(function(layer) {
        layers.push(layer);
      });
      layers.forEach((item) => this.mymap.removeLayer(item));
      // var mapOptions = {
      //   minZoom: this.map.minZoom,
      //   maxZoom: this.map.maxZoom,
      //   maxNativeZoom: this.map.maxNativeZoom,
      //   attribution: "&copy; <a href=\"http://www.openstreetmap.org/copyright\">OpenStreetMap</a>",
      //   id: "mapbox/" + this.tiles[this.map.tileId],//'mapbox/outdoors-v11', //dark-v10, light-v10, streets-v11, outdoors-v11, satellite-v9, satellite-streets-v11, navigation-day-v1, navigation-night-v1
      //   accessToken: "pk.eyJ1IjoiYWhtYWRzaGFmaWVpIiwiYSI6ImNrc2VwdHZ1bjB4aHEybnAyN282a29nMDEifQ.6uf31gUq1E3Em0x9MsdeRg"
      // };
      // L.tileLayer("https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}", mapOptions).addTo(this.mymap);

      L.tileLayer.wms("https://name.isfahan.ir/saeeserver/wms", {
        layers: "monam:boostan,monam:roads,monam:water",
        format: "image/png",
        transparent: true,
        version: "1.3.0",
        tiled: true,
        crs: L.CRS.EPSG32639,
        uppercase: true,
        styles: "",
        attribution: false,
        minZoom: this.map.minZoom,
        maxZoom: this.map.maxZoom,
        maxNativeZoom: this.map.maxNativeZoom
      }).addTo(this.mymap);


      // var pointList=[];
      // for (var j = 0; j < floor.floorPoints.length; j++){
      //     pointList[j] = latLng(floor.floorPoints[j].lat,floor.floorPoints[j].lng);
      // }

      // var geometry = new L.Polygon(pointList, floor.floorOptions);
      // this.levels = new L.layerGroup;
      // this.levels.addLayer(geometry);

      // floor.floorZones.sort((a,b)=>(+a.zoneOptions.order > +b.zoneOptions.order) ? 1 : ((+b.zoneOptions.order > +a.zoneOptions.order) ? -1 : 0))

      // for (var zone of floor.floorZones){
      //   var pointList=[];
      //   for (var j = 0; j < zone.zonePoints.length; j++){
      //       pointList[j] = latLng(zone.zonePoints[j].lat,zone.zonePoints[j].lng);
      //   }
      //   zone.zoneOptions['_id']={floor:floor._id, zone:zone._id};

      //   var geometry = new L.Polygon(pointList, zone.zoneOptions);
      //   if (zone.zoneOptions.type==="lightZone")// || space.properties.stairs!=="undefined")
      //   {
      //     geometry.on('click', this.zoneClick);
      //     geometry.on('mouseover', this.zoneOver);
      //     geometry.on('mouseout',  this.zoneLeft);
      //   }
      //   this.levels.addLayer(geometry);
      // }
      // this.levels.addTo(this.mymap);
    },
    createSpaces: function(floor) {
      var pointList = [];
      for (var j = 0; j < floor.details.floorPoints.length; j++) {
        pointList[j] = latLng(floor.details.floorPoints[j].lat, floor.details.floorPoints[j].lng);
      }

      var geometry = new L.Polygon(pointList, floor.details.floorOptions);
      this.levels = new L.layerGroup;
      this.levels.addLayer(geometry);

      floor.details.floorZones.sort((a, b) => (+a.zoneOptions.order > +b.zoneOptions.order) ? 1 : ((+b.zoneOptions.order > +a.zoneOptions.order) ? -1 : 0));

      for (var zone of floor.details.floorZones) {
        var pointList = [];
        for (var j = 0; j < zone.zonePoints.length; j++) {
          pointList[j] = latLng(zone.zonePoints[j].lat, zone.zonePoints[j].lng);
        }
        zone.zoneOptions["_id"] = { floor: floor._id, zone: zone._id };

        var geometry = new L.Polygon(pointList, zone.zoneOptions);
        if (zone.zoneOptions.onClick.type) {
          geometry.on("click", this.zoneClick);
          geometry.on("mouseover", this.zoneOver);
          geometry.on("mouseout", this.zoneLeft);
        }
        this.levels.addLayer(geometry);
      }
      this.levels.addTo(this.mymap);
    },
    getfloorMap(floor) {
      this.$store.dispatch("data/getSingleMapLayer", { id: floor }).then(
        data => {
          if (data._id === floor)
            if (data.type === "indoormap")
              this.createSpaces(data);
            else if (data.type === "marker") {
              this.layerList.push(data);
            }
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    removeWidget() {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete this widget")
        .then(value => {
          if (value === true) {
            this.$store.dispatch("chpge/child2parent", { task: "rmDashItem", item: this.plotId });
          }
        })
        .catch(err => {
        });
    },
    editWidget() {
      this.$root.$emit("bv::show::modal", this.plotId);
    },
    getForm(id, assignedData) {
      this.$store.dispatch("data/formGetById", { _id: id }).then(res => {
        this.selectedForm = res;
        this.$store.dispatch("chpge/child2child", {
          task: "pick_form",
          to: "widget_Form",
          item: res,
          assignedData: assignedData
        });
      });
    },
    initModal: function() {
      // this.$refs.table1.refresh();
      this.myDataTmp = JSON.parse(JSON.stringify(this.myData));
      this.reRender += 1;
    },
    resetModal: function() {
      this.myData = JSON.parse(JSON.stringify(this.myDataTmp));

      this.submitted = null;

      this.successful = null;
      this.message = null;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {

          this.$store.dispatch("data/editDashboard", { _id: this.plotId, data: this.myData }).then(
            data => {
              this.$store.dispatch("chpge/child2parent", { task: "updateDash" });
              this.myDataTmp = JSON.parse(JSON.stringify(this.myData));
              this.$nextTick(() => {
                this.$bvModal.hide(this.plotId);
              });
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
              // alert(this.message);
            }
          );
        }
      });
    },
    resize() {
      const divElement = this.$refs.mainCont;
      if (divElement) {
        this.myData.attribute.mapHeight = divElement.clientHeight - 58;
        this.myData.attribute.mapWidth = divElement.clientWidth - 16;
      }
      // setTimeout(() => {
      //   this.setupLeafletMap();
      // }, "1000");

    },
    addItem: function(type) {
      this.$root.$emit("bv::show::modal", "modal-widget-pick-map" + this.plotId);
    },
    removeItem: function(id, cat) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.deleteConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            let founded = this.myData.datas.find(item => item.category == cat && item.id == id);
            this.myData.datas.splice(this.myData.datas.indexOf(founded), 1);
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }

    },
    removeDataItem: function(id) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.deleteConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            let founded = this.myData.eventData.find(item => item.id == id);
            this.myData.eventData.splice(this.myData.eventData.indexOf(founded), 1);
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }

    },
    initPickMapModal: function() {
      this.getAllMaps();
    },
    resetPickMapModal: function() {
      this.pickMap.items = [];
    },
    getAllMaps() {
      this.$store.dispatch("data/getAllMaps").then(
        data => {
          this.pickMap.items = data.message;
          this.pickMap.totalRows = this.pickMap.items.length;
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    sendDataSSE(datas) {
      this.myData.datas.forEach(x => {
        if (datas.indexOf(x.id) <= -1)
          datas.push(x.id);
      });
      if (datas.length > 0)
        this.$emit("getWidgetData", {
          id: this.plotId,
          list: datas,
          action: "add"
        });
      // this.$store.dispatch("data/sendDataForSSE", { id: Array.from(new Set(datas)), action: "add" });

      // this.$store.dispatch("panel/sendListID", { property: "widgetCombinedMapTable", value: datas });

    },
    pickMapOnFiltered(filteredItems) {
      this.pickMap.totalRows = filteredItems.length;
      this.pickMap.currentPage = 1;
    },
    pickMapOnRowClicked(item, index, event) {
      this.myData.datas.push({ type: item.type, id: item._id, name: item.title, category: "DB" });
      this.$bvModal.hide("modal-widget-pick-map" + this.plotId);
    },
    pickMapHandleClose() {
      this.$bvModal.hide("modal-widget-pick-map");
    },
    getAllCategories() {
      this.$store.dispatch("data/getAllCategoryforList").then((data) => {
          this.categories = data;
        }
      );
    },
    detectItem(item) {
      const filteredObject = Object.fromEntries(
        Object.entries(item).filter(([key, value]) => key !== "title" && key !== "times" &&
          key !== "trafficLights" && key !== "atributeApproval" && key !== "status" && key !== "fireApproval" && key !== "commands" && key !== "destinationLoc" && key !== "id")
      );
      return filteredObject;
    }
  },
  computed: {
    EventRowGenerator() {
      return Object.fromEntries(
        Object.entries(this.eventRows).sort(([, a], [, b]) => b.times[0]["fireAlarm"] - a.times[0]["fireAlarm"])
      );
    },
    updatedData() {
      return this.$store.state.panel.data;
    },
    child2parent() {
      return this.$store.state.chpge.child2child;
    },
    sseDatas() {
      const a = this.updateDOM;
      return this.sseData;
    }
  },
  watch: {
    list: {
      handler(val) {
        let callMe = async () => {
          for (const item of Object.entries(val)) {
            if (typeof item[1] == "object" && "status" in item[1]) {
              if (item[1].status === 0 && item[1].fireApproval === null) {
                const destInfo = await this.getMarkerData([item[1].destination]);
                let destination = {};

                destInfo[0].value.forEach(key => {
                  destination[key.name] = key.value;
                });

                const DialogObj = DialogUtility.confirm({
                  title: this.dict.firedAlarm[this.lang] + item[1].title,
                  locale: this.lang,
                  cssClass: "custom-dialog-font",
                  position: "center center",
                  Enabletrl: this.lang === "per",
                  content: `
                <table border="1" style="border-collapse: collapse; width: 100%;">
                  <tr style="background-color: #f2f2f2;">
                    <td style="padding: 8px;"><strong>${this.dict.building[this.lang]}</strong></td>
                    <td style="padding: 8px;">${destination.name}</td>
                  </tr>
                  <tr>
                    <td style="padding: 8px;"><strong>${this.dict.coord[this.lang]}</strong></td>
                    <td style="padding: 8px;">${destination.coordinator}</td>
                  </tr>
                  <tr style="background-color: #f2f2f2;">
                    <td style="padding: 8px;"><strong>${this.dict.address[this.lang]}</strong></td>
                    <td style="padding: 8px;">${destination.address}</td>
                  </tr>
                  <tr>
                    <td style="padding: 8px;"><strong>${this.dict.phone[this.lang]}</strong></td>
                    <td style="padding: 8px;">${destination.phone}</td>
                  </tr>
                </table>
                <br><br>
                ${this.dict.confirmEve[this.lang]}`,
                  okButton: {
                    text: this.dict.ok[this.lang],
                    click: async () => {
                      item[1].fireApproval = true;
                      this.list[item[0]] = item[1];

                      await this.$store.dispatch("data/updateData", {
                        _id: this.myData.eventData[0].id,
                        values: [{ id: "", value: this.list }]
                      });

                      DialogObj.hide();
                      this.reRenderObj += 1;
                    }
                  },
                  cancelButton: {
                    text: this.dict.cancel[this.lang],
                    click: async () => {
                      item[1].fireApproval = false;
                      this.list[item[0]] = item[1];

                      await this.$store.dispatch("data/updateData", {
                        _id: this.myData.eventData[0].id,
                        values: [{ id: "", value: this.list }]
                      });

                      DialogObj.hide();
                    }
                  },
                  showCloseIcon: true,
                  closeOnEscape: false,
                  animationSettings: { effect: "Zoom" }
                });

                if (this.lang === "per") {
                  DialogObj.element.classList.add("e-control-rtl");
                } else {
                  DialogObj.element.classList.remove("e-control-rtl");
                  DialogObj.element.classList.add("e-control-ltr");
                }
              } else if (item[1].status === 2 && item[1].atributeApproval === null) {
                const destInfo = await this.getMarkerData([item[1].fireStations[0]]);
                const truckInfo = await this.getMarkerData([item[1].fireTrucks[0]]);

                let destination = {};
                let trucks = {};
                let dest = item[1].routes;

                destInfo[0].value.forEach(key => {
                  destination[key.name] = key.value;
                });
                truckInfo[0].value.forEach(key => {
                  trucks[key.name] = key.value;
                });

                const DialogObj = DialogUtility.confirm({
                  title: this.dict.resourceConf[this.lang] + item[1].title,
                  locale: this.lang,
                  cssClass: "custom-dialog-font",
                  position: "center center",
                  Enabletrl: this.lang === "per",
                  content: `
                <table border="1" style="border-collapse: collapse; width: 100%;">
                  <tr style="background-color: #f2f2f2;">
                    <td style="padding: 8px;"><strong>${this.dict.chosenStation[this.lang]}</strong></td>
                    <td style="padding: 8px;">${destination.name}</td>
                  </tr>
                  <tr>
                    <td style="padding: 8px;"><strong>${this.dict.chosenTrucks[this.lang]}</strong></td>
                    <td style="padding: 8px;">${trucks.brand} / ${trucks.type} / ${trucks["serial number"]}</td>
                  </tr>
                  <tr style="background-color: #f2f2f2;">
                    <td style="padding: 8px;"><strong>${this.dict.chosenRoute[this.lang]}</strong></td>
                    <td style="padding: 8px;">${dest[0].legs[0].summary}</td>
                  </tr>
                  <tr>
                    <td style="padding: 8px;"><strong>${this.dict.chosenTime[this.lang]}</strong></td>
                    <td style="padding: 8px;">${dest[0].legs[0].duration.text}</td>
                  </tr>
                  <tr style="background-color: #f2f2f2;">
                    <td style="padding: 8px;"><strong>${this.dict.chosenWay[this.lang]}</strong></td>
                    <td style="padding: 8px;">${dest[0].legs[0].distance.text}</td>
                  </tr>
                </table>`,
                  okButton: {
                    text: this.dict.ok[this.lang],
                    click: async () => {
                      item[1].atributeApproval = true;
                      this.list[item[0]] = item[1];

                      await this.$store.dispatch("data/updateData", {
                        _id: this.myData.eventData[0].id,
                        values: [{ id: "", value: this.list }]
                      });

                      this.reRenderObj += 1;

                      if (this.activeLayers.length > 0 && this.tempLayerList.length > 0) {
                        if (this.activeLayers.indexOf(this.tempLayerList[this.tempLayerList.length - 1]._id) <= -1) {
                          this.activeLayers.push(this.tempLayerList[this.tempLayerList.length - 1]._id);
                          this.activeLayer = this.tempLayerList[this.tempLayerList.length - 1];
                        } else {
                          this.activeLayers.splice(this.activeLayers.indexOf(this.tempLayerList[this.tempLayerList.length - 1]._id), 1);
                          this.activeLayers.push(this.tempLayerList[this.tempLayerList.length - 1]._id);
                          this.activeLayer = this.tempLayerList[this.tempLayerList.length - 1];
                        }
                      }

                      DialogObj.hide();
                    }
                  },
                  cancelButton: {
                    text: this.dict.cancel[this.lang],
                    click: async () => {
                      item[1].atributeApproval = false;
                      this.list[item[0]] = item[1];

                      await this.$store.dispatch("data/updateData", {
                        _id: this.myData.eventData[0].id,
                        values: [{ id: "", value: this.list }]
                      });

                      DialogObj.hide();
                    }
                  },
                  showCloseIcon: true,
                  closeOnEscape: false,
                  animationSettings: { effect: "Zoom" }
                });

                if (this.lang === "per") {
                  DialogObj.element.classList.add("e-control-rtl");
                } else {
                  DialogObj.element.classList.remove("e-control-rtl");
                  DialogObj.element.classList.add("e-control-ltr");
                }
              }


              this.processList(item[1], item[0]);
            }
          }
        };
        debounce(() => {
          callMe();
        }, 15);
      },
      deep: true,
      immediate: true
    },
    "myData.attribute.activeLayers": {
      handler(val) {
        setTimeout(() => {
          if (val.length > 0) {
            val.forEach(item => {
              this.layerList.forEach(layer => {
                if (layer._id == item) {
                  this.changeLayer(layer, false);
                }
              });
            });
          } else if (val.length <= 0) {
            this.changeLayer("none");
          }
        }, 600);
      }, deep: true
    },
    counter: {
      handler() {
        if (this.createdlayers.length == this.counter) {
          if (this.listIdForSse.length > 0)
            this.sendDataSSE(this.listIdForSse);
          // const debouncedFunction = debounce(() => {
          //   this.sendDataSSE(this.listIdForSse);
          //
          //   // const lastPropName = Object.keys(this.eventRows)[0];
          //   // let layer = this.tempLayerList.find(item => item._id == this.eventRows[lastPropName].id);
          //   // this.createMarkers(layer);
          // }, 1000);
          //
          // debouncedFunction();
        }
      }
    },
    resized() {
      this.resize();
    },
    setting(newValue) {
      if (newValue) {
        this.$root.$emit("bv::show::modal", this.plotId);
      }
    },
    layerList(val) {
      setTimeout(() => {
        if (val.length > 1)
          this.updateDropdown();
      }, 180);
    },
    sseDatas: {
      handler(newValue) {
        const vm = this;
        if (newValue)
          newValue.forEach((iData) => {
            let foundedData = this.myData.datas.find(x => x.id == iData.id && x.type == "freeArray");
            if (foundedData != undefined) {
              if (Array.isArray(iData.value)) {
                this.tempLayerList = iData.value;
                iData.value.forEach(res => {
                  let foundedItem = this.tempLayerList.find(x => x._id === res._id);
                  if (!foundedItem) {
                    this.tempLayerList.push(res);
                  } else {
                    Object.assign(foundedItem, res);
                  }
                });
              }
            } else if (iData.value != null && typeof iData.value == "object") {
              let lay = this.tempLayerList.find(x => x._id == iData.value._id);
              if (lay === undefined && Object.keys(iData.value).length > 0) {
                // this.tempLayerList.push(iData.value);
              } else if (lay !== undefined) {
                lay.details = iData.value.details;
              }
            }

            if (Array.isArray(iData.value)) {
              iData.value.forEach(x => {
                this.watchlist.forEach(wData => {
                  if (wData.type != "movable" && JSON.stringify(iData.id) === JSON.stringify(wData.parent)) {
                    for (let val of wData.values) {
                      if (val.value == x.value) {
                        let selIcon = val.icon;
                        this.markerLayer.eachLayer(marker => {
                          if (marker.options.id === wData.icon) {
                            let newIcon = L.icon({
                              iconUrl: selIcon.address,
                              iconSize: marker.options.icon.options.iconSize,
                              iconAnchor: marker.options.icon.options.iconAnchor,
                              popupAnchor: marker.options.icon.options.popupAnchor,
                              shadowAnchor: marker.options.icon.options.shadowAnchor
                            });
                            marker.setIcon(newIcon);
                          }
                        });
                        break;
                      }
                    }
                  } else if (wData.type == "movable" && x.id == wData.data && iData.id == wData.parent) {
                    this.markerLayer.eachLayer(marker => {
                      if (marker.options.id === wData.icon && x.value.length > 0) {
                        marker.setLatLng(x.value.split(","));
                      }
                    });
                  }
                });
              });
            } else {
              this.watchlist.forEach(wData => {
                let sentID = iData.id.split("_");
                if (wData.type != "movable") {
                  if (sentID.length > 1 ? JSON.stringify(sentID[0]) === JSON.stringify(wData.parent) : JSON.stringify(sentID[0]) === JSON.stringify(wData.data)) {
                    for (let val of wData.values) {
                      if (val.value == iData.value) {
                        let selIcon = val.icon;
                        this.markerLayer.eachLayer(marker => {
                          if (marker.options.id === wData.icon) {
                            let newIcon = L.icon({
                              iconUrl: selIcon.address,
                              iconSize: marker.options.icon.options.iconSize,
                              iconAnchor: marker.options.icon.options.iconAnchor,
                              popupAnchor: marker.options.icon.options.popupAnchor,
                              shadowAnchor: marker.options.icon.options.shadowAnchor
                            });
                            marker.setIcon(newIcon);
                          }
                        });
                        break;
                      }
                    }
                  }
                } else if (wData.type == "movable") {
                  if (sentID[1] == wData.data && sentID[0] == wData.parent) {
                    this.markerLayer.eachLayer(function(marker) {
                      if (marker.options.id === wData.icon && marker.options.isTruck) {
                        if (iData.value.length > 0) {
                          vm.updateVehicleMarker(marker, vm.prevPos, iData.value.split(","));
                        } else if (marker.options.id === wData.icon && !marker.options.isTruck) {
                          marker.setLatLng(iData.value.split(","));
                        }
                      }
                    });
                  }
                }

              });
            }

            this.myData.eventData.forEach((eve) => {
              if (eve.id === iData.id) {
                Object.entries(iData.value).forEach(([key, newValue]) => {
                  let isExist;
                  if (Object.keys(this.list).length > 0 && key in this.list) {
                    if (Array.isArray(this.list[key])) {
                      isExist = this.list[key].some(existingData => JSON.stringify(existingData) == JSON.stringify(newValue));
                    } else isExist = JSON.stringify(this.list[key]) == JSON.stringify(newValue);

                    if (isExist == false) {
                      this.$set(this.list, key, newValue);
                    }
                  } else {
                    this.$set(this.list, key, newValue);
                  }
                });
              }
            });
          });
      }, deep: true, immediate: true
    },
    child2parent(newValue) {
      if (newValue.to == "widgetCombinedMap" + this.plotId) {
        if (Object.keys(newValue.item).length > 0) {
          this.myData.datas.push({
            id: newValue.item.id,
            name: newValue.item.name,
            type: newValue.item.dataType,
            category: "controller"
          });
        }
      }
      if (newValue.to == "widgetCombinedMapEvent" + this.plotId) {
        if (Object.keys(newValue.item).length > 0) {
          this.myData.eventData.push({
            id: newValue.item.id,
            name: newValue.item.name,
            type: newValue.item.dataType
          });
        }
      }
    },
    "activeLayers": {
      handler(val) {
        val.forEach((id) => {
          this.tempLayerList.forEach(item => {
            if (id == item._id) {
              this.changeLayer(item, true);
            }
          });
        });
      }, deep: true
    },
    "activeLayer": {
      handler(val) {
        if (Object.keys(val).length > 0) {
          setTimeout(() => {
            this.$nextTick(() => {
              this.changeLayer(val, true);

            });
          }, 50);
        }
      }, deep: true
    }
  },
  mounted() {

    window.addEventListener("resize", this.widthUpdated);
    this.getAllCategories();
    this.listIdForSse = [];

    if (this.data && this.data.datas && this.data.attribute) {
      this.myData = this.data;
      let idList = [];
      this.data.datas.forEach((item) => {
        idList.push(item.id);
      });


      if (!("activeLayers" in this.myData.attribute)) {
        this.myData.attribute["activeLayers"] = [];
      }
      if (this.myData.eventData.length > 0) {
        this.listIdForSse.push(this.myData.eventData[0].id);
        idList.push(this.myData.eventData[0].id);
        setTimeout(() => {
          this.$store.dispatch("panel/getData", { id: [this.myData.eventData[0].id] }).then(res => {
            this.$nextTick(() => {
              this.list = res[0].value;
            });
          });
          const foundedID = this.myData.datas.find(item => item.type == "freeArray").id;
          if (foundedID != undefined)
            this.$store.dispatch("panel/getData", { id: [foundedID] }).then(res => {
              this.tempLayerList = res[0].value;
              // this.activeLayer = this.tempLayerList[this.tempLayerList.length - 1];
            });

          this.$emit("sseData", {
            id: this.plotId,
            list: idList
          });
        }, 20);

      }
      setTimeout(() => {
        this.resize();
        this.mapinit = true;
      }, 10);
      setTimeout(() => {
        this.setupLeafletMap();

        if (this.myData.datas.length > 0)
          this.createCustomCtrl();
      }, 30);
    }

  }
};
</script>

<style>
.icon-step0-eng, .icon-step0-per {
  background-color: #007bff;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  background-image: url('../assets/images/steps/FIRE_ckeck.svg') !important;
  background-size: 20px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.icon-step1-eng, .icon-step1-per {
  background-color: #007bff;
  width: 35px !important;
  height: 35px !important;
  border-radius: 50% !important;
  background-image: url('../assets/images/steps/search.svg') !important;
  background-size: 30px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.icon-step2-eng {
  background-color: #007bff;
  width: 40px !important;
  height: 34px !important;
  border-radius: 50% !important;
  background-image: url('../assets/images/steps/step3.svg') !important;
  background-size: 28px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.icon-step2-per {
  background-color: #007bff;
  width: 40px !important;
  height: 34px !important;
  border-radius: 50% !important;
  background-image: url('../assets/images/steps/stepRev2.svg') !important;
  background-size: 28px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.icon-step3-eng, .icon-step3-per {
  background-color: #007bff;
  width: 35px !important;
  height: 35px !important;
  border-radius: 50% !important;
  background-image: url('../assets/images/steps/step5.svg') !important;
  background-size: 30px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.icon-step4-eng {
  background-color: #007bff;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background-image: url('../assets/images/steps/step6.svg') !important;
  background-size: 30px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.icon-step4-per {
  background-color: #007bff;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background-image: url('../assets/images/steps/stepRev1.svg') !important;
  background-size: 30px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.icon-step5-per, .icon-step5-eng {
  background-color: #007bff;
  width: 35px !important;
  height: 35px !important;
  border-radius: 50% !important;
  background-image: url('../assets/images/steps/step7.svg') !important;
  background-size: 25px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.icon-step6-per, .icon-step6-eng {
  background-color: #007bff;
  width: 35px !important;
  height: 35px !important;
  border-radius: 50% !important;
  background-image: url('../assets/images/steps/retrun.svg') !important;
  background-size: 28px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.icon-step7-per, .icon-step7-eng {
  background-color: #007bff;
  width: 35px !important;
  height: 35px !important;
  border-radius: 50% !important;
  background-image: url('../assets/images/steps/end.svg') !important;
  background-size: 28px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

</style>
<style scoped>


.remove {
  cursor: pointer;
  color: rgb(175, 235, 11);
  font-size: 18px;
}

.edit {
  cursor: pointer;
  color: rgb(9, 180, 210);
  font-size: 18px;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-100%);
}


</style>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
{
  opacity: 0;
}

.custom-card {
  width: 100%; /* Full width for responsiveness */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a shadow to the card */
  border-radius: 8px; /* Add rounded corners */
  overflow: hidden;
  margin-bottom: 15px; /* Space between cards */
}

.e-card-header {
  padding: 15px;
}

.stepper-container {
  margin-top: 10px; /* Adjust the margin as needed */
}


.chevron-icon.rotate {
  transform: rotate(180deg);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
{
  opacity: 0;
}

.table {
  width: 100%; /* Ensure table takes full width */
  background-color: white;
}

.table thead {
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
}


.stepper-container {
  margin-top: 10px; /* Adjust the margin as needed */
}


.custom-polyline {
  stroke: blue; /* Border color */
  stroke-width: 10px; /* Border width */
}

.leaflet-control-attribution {
  display: none;

}
</style>

<style>
.custom-dialog-font .e-dlg-header {
  font-size: 14px !important;
  color: red;
}

.custom-dialog-font .e-dlg-content {
  font-size: 13px !important;
}

.e-stepper .e-step {
  background-color: #007bff !important; /* Default step color */
  color: white; /* Default text color */
}

.e-stepper .e-step.e-active {
  background-color: #28a745 !important; /* Active step color */
  color: white !important; /* Active step text color */
}

.e-stepper.e-step.e-active {
  background-color: #28a745 !important; /* Active step color */
  color: white !important; /* Active step text color */
}

.e-stepper.e-step.e-step-label {
  color: white !important; /* Label text color */
}

.e-stepper.e-step.e-step-text {
  color: white !important; /* Step text color */
}

.e-stepper-progressbar .e-progressbar-value {
  background-color: #000049 !important;
}

.e-step-container .e-step-label-container .e-label-after {
  background-color: #000049 !important;

  color: white !important;
}

.e-stepper-progressbar {
  height: 5px !important;
  margin-top: 10px !important;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader span {
  width: 5px;
  height: 5px;
  margin: 0 3px;
  background-color: forestgreen;
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out both;
}

.loader span:nth-child(2) {
  animation-delay: -0.32s;
}

.loader span:nth-child(3) {
  animation-delay: -0.16s;
}
</style>







