<template>
  <div>
    <!--    <b-modal-->
    <!--      id="modal-alert-editor"-->
    <!--      size="lg"-->
    <!--      centered-->

    <!--      header-text-variant="dark"-->
    <!--      header-border-variant="primary"-->
    <!--      body-text-variant="dark"-->
    <!--      hide-header-close-->
    <!--      no-close-on-backdrop-->

    <!--      content-class="shadow"-->
    <!--      :title="modalTitle"-->
    <!--      :dir="dict.dir[lang]"-->
    <!--      :ok-title="dict.ok[lang]"-->
    <!--      :cancel-title="dict.cancel[lang]"-->
    <!--      @shown="initModal"-->
    <!--      @hidden="resetModal"-->
    <!--      @ok="handleOk"-->
    <!--      @cancel="resetModal"-->

    <!--    >-->
    <!--     -->
    <!--    </b-modal>-->
    <div>
      <!-- <b-container fluid> -->
      <div class="row" id="events_manager">
        <div class="col-12 col-md-6">
          <ejs-dropdownlist
            floatLabelType="Auto"
            :dataSource="alertTypeList"
            :value="data.type"
            :fields="{ text: 'text', value: 'value' }"
            :placeholder="dict.typeAlert[lang]"
            :enableRtl="lang === 'per'"
            :required="true"
            @change="(val) => {data.type = val.value}"
          ></ejs-dropdownlist>
        </div>
        <div class="col-12 col-md-12 row">
          <div class="col-md-4 mt-4">
            <ejs-switch
              :enableRtl="lang == 'per'"
              v-model="data.engMessage.isDynamic"
              :checked="data.engMessage.isDynamic"
              @change="(val)=>{data.engMessage.isDynamic=val.checked}"
            />
            <span style="font-size: 13px" class="mx-2">{{ dict.dynamic[lang] }}</span>
          </div>
          <div class="col-md" v-if="data.engMessage.isDynamic==false" :key="data.engMessage.isDynamic">
            <ejs-textbox
              floatLabelType="Auto"
              :value="data.engMessage.value"
              :placeholder="dict.messageAlertEng[lang]"
              :enableRtl="lang === 'per'"
              :required="true"
              @input="(val) => {data.engMessage.value = val.value}"
            ></ejs-textbox>
          </div>
          <div class="col-md" v-if="data.engMessage.isDynamic==true">
            <div class="d-flex align-items-center" :key="data.engMessage.isDynamic">
              <ejs-textbox
                :value="data.engMessage.data['name']"
                :placeholder="dict.pickData[lang]"
                :enableRtl="lang == 'per'"
                floatLabelType="Auto"
                :required="data.engMessage.isDynamic"
                :showClearButton="true"
                v-model="data.engMessage.data['name']"
              ></ejs-textbox>
              <b-button @click="pickDataForEnMessage" class="btn-sm" variant="primary"
              ><span class="e-icons e-edit-6"></span>
              </b-button>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 row mt-3">
          <div class="col-md-4 mt-4">
            <ejs-switch
              :enableRtl="lang == 'per'"
              v-model="data.message.isDynamic"
              :checked="data.message.isDynamic"
              @change="(val)=>{data.message.isDynamic=val.checked}"
            />
            <span style="font-size: 13px" class="mx-2">{{ dict.dynamic[lang] }}</span>
          </div>
          <div class="col-md" v-if="data.message.isDynamic==false" :key="data.message.isDynamic">
            <ejs-textbox
              floatLabelType="Auto"
              :value="data.message.value"
              :placeholder="dict.messageAlert[lang]"
              :enableRtl="lang === 'per'"
              :required="true"
              @input="(val) => {data.message.value = val.value}"
            ></ejs-textbox>
          </div>
          <div class="col-md" v-if="data.message.isDynamic==true">
            <div class="d-flex align-items-center" :key="data.message.isDynamic">
              <ejs-textbox
                :value="data.message.data['name']"
                :placeholder="dict.pickData[lang]"
                :enableRtl="lang == 'per'"
                floatLabelType="Auto"
                :required="data.message.isDynamic"
                :showClearButton="true"
                v-model="data.message.data['name']"
              ></ejs-textbox>
              <b-button @click="pickDataForPerMessage" class="btn-sm" variant="primary"
              ><span class="e-icons e-edit-6"></span>
              </b-button>
            </div>
          </div>
        </div>

        <div v-if="dataType==='Number'" class="col-12 col-md-6">
          <ejs-textbox
            floatLabelType="Auto"
            :value="data.range.min"
            :placeholder="dict.minAlert[lang]"
            :enableRtl="lang === 'per'"
            :required="true"
            @input="(val) => {data.range.min = val.value}"
            type="number"
          ></ejs-textbox>
        </div>
        <div v-if="dataType==='Number'" class="col-12 col-md-6">
          <ejs-textbox
            floatLabelType="Auto"
            :value="data.range.max"
            :placeholder="dict.maxAlert[lang]"
            :enableRtl="lang === 'per'"
            :required="true"
            @input="(val) => {data.range.max = val.value}"
            type="number"
          ></ejs-textbox>
        </div>

        <div v-if="dataType==='Boolean'" class="col-12 col-md-6" :key="dataType">
          <ejs-dropdownlist
            floatLabelType="Auto"
            :dataSource="triggerList"
            :value="data.range.state"
            :fields="{ text: 'text', value: 'value' }"
            :placeholder="dict.state[lang]"
            :enableRtl="lang === 'per'"
            :required="true"
            @change="(val) => {data.range.state = val.value}"
          ></ejs-dropdownlist>
        </div>

        <div v-if="dataType==='String'" class="col-12 col-md-6">
          <ejs-textbox
            floatLabelType="Auto"
            :value="data.range.term"
            :placeholder="dict.term[lang]"
            :enableRtl="lang === 'per'"
            :required="true"
            @input="(val) => {data.range.term = val.value}"
          ></ejs-textbox>
        </div>

      </div>
      <!-- </b-container> -->
    </div>
    <data-picker-modal v-if="showDataPicker" />
    <!--    <dataPickerModal></dataPickerModal>-->

  </div>
</template>

<script>
/* eslint-disable */
import { v4 as uuidv4 } from "uuid";
import { FormValidator } from "@syncfusion/ej2-inputs";
import DataPickerModal from "@/components/setContModalDataPicker.vue";


export default {
  name: "dataPickerAlarm",
  data() {
    return {
      showDataPicker: false,
      dict: {
        dynamic: { eng: "choose value", per: "انتخاب داده" },
        pickData: { per: "انتخاب داده", eng: "Pick Data" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        dir: { eng: "ltr", per: "rtl" },
        typeAlert: { eng: "event type", per: "نوع رویداد" },
        messageAlert: { eng: "message persian", per: "پیام رویداد فارسی" },
        messageAlertEng: { eng: "english message", per: "پیام رویداد انگلیسی" },
        minAlert: { eng: "min", per: "حداقل" },
        maxAlert: { eng: "max", per: "حداکثر" },
        state: { eng: "trigger state", per: "trigger state" },
        term: { eng: "term", per: "term" }
      },
      type: "",
      modalTitle: "",
      dataType: null,
      data: {
        id: "",
        type: null,
        message: {
          isDynamic: false,
          value: "",
          data: { name: "" }
        },
        engMessage: {
          isDynamic: false,
          value: "",
          data: { name: "" }
        },
        range: {
          min: "",
          max: "",
          state: null,
          term: ""
        }
      },

      alertTypeList: [{ text: "Select One", value: null },
        { text: "Alarm", value: "alarm" },
        { text: "Warning", value: "warning" },
        { text: "Notice", value: "notice" }
      ],

      triggerList: [{ text: "Select One", value: null },
        { text: "True", value: true },
        { text: "False", value: false }
      ],

      submitted: false,
      successful: false,
      message: "",
      validator: null,
      reRen: 0
    };
  },
  components: {
    DataPickerModal
  },
  computed: {
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  watch: {
    parent2child(newValue, oldValue) {
      if (newValue.task === "editAlert" && newValue.from != "pickDataForEnMessage") {
        this.type = newValue.type;
        this.modalTitle = newValue.type + " Alert";
        this.dataType = newValue.item.dataType;
        this.data.id = (newValue.item.id || newValue.item.id === 0) ? newValue.item.id : uuidv4();
        this.data.type = newValue.item.details.type ? newValue.item.details.type : null;
        this.data.message = newValue.item.details.message ? newValue.item.details.message : {
          value: "",
          isDynamic: false,
          data: { name: "" }
        };
        this.data.engMessage = newValue.item.details.engMessage ? newValue.item.details.engMessage : {
          value: "",
          isDynamic: false,
          data: { name: "" }
        };
        this.data.range = newValue.item.details.range ? newValue.item.details.range : {
          min: "",
          max: "",
          state: null,
          term: ""
        };
      }

    },
    child2parent(newValue) {
      if (newValue.task == "pickData" && newValue.to == "pickDataForEnMessage") {
        this.data.engMessage.data = newValue.item;
        this.data.engMessage.value = newValue.item._id;
      } else if (newValue.task == "pickData" && newValue.to == "pickDataForPerMessage") {
        this.data.message.data = newValue.item;
        this.data.message.value = newValue.item._id;
      }
    }

  },
  methods: {
    pickDataForEnMessage() {
      this.showDataPicker = true;
      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: "pickDataForEnMessage",
        filter: {
          dataType: ["!complex", "Object", "String"],
          usedBy: [],
          parents: [],
          archive: null,
          withChild: false,
          page: 1,
          perPage: 5000
        }
      });
      this.$root.$emit("bv::show::modal", "modal-pick-data");
    },
    pickDataForPerMessage() {
      this.showDataPicker = true;
      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: "pickDataForPerMessage",
        filter: {
          dataType: ["!complex", "Object", "String"],
          usedBy: [],
          parents: [],
          archive: null,
          perPage: 5000,
          page: 1,
          withChild: false
        }
      });
      this.$root.$emit("bv::show::modal", "modal-pick-data");
    },
    initModal: function() {
      this.getAllEventTypes();
      setTimeout(() => {
        // this.validator = new FormValidator("#events_manager", {
        //   rules: {},
        //   customPlacement: (element, error) => {
        //     element.parentElement.parentElement.parentElement.insertBefore(error, element.parentElement.parentElement.nextSibling);
        //   }
        // });
        this.reRen += 1;
      }, 200);

    },
    resetModal: function() {
      this.type = "";
      this.dataType = "";
      this.modalTitle = "";
      this.data = {
        id: "",
        type: null,
        message: "",
        range: { min: "", max: "", state: null, term: "" }
      };

      this.reRen = 0;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.message = "";
      this.submitted = true;
      const isValid = new FormValidator("#events_manager").validate();
      if (isValid) {
        this.$store.dispatch("chpge/child2parent", { task: "editAlert", type: this.type, item: this.data });
        return true;
      } else return false;

    },
    getAllEventTypes() {
      this.alertTypeList = [];
      this.$store.dispatch("data/getAllEventTypes").then((res) => {
        res.forEach(item => {
          this.alertTypeList.push({ "text": item.name, "value": item._id });
        });
      });
    }
  },
  mounted() {
    this.initModal();
  },
  beforeDestroy() {
    this.resetModal();
  }
};
</script>

<style scoped>
.close {
  font-size: 20px;
  color: #dc3545;
  cursor: pointer;
}

.close :hover {
  color: #9b9b9b;
}
</style>