<template>
  <div class="p-3">
    <h5 class="text-center text-bold">{{title[lang]}}</h5>
  </div>
</template>

<script>
export default {
  name: "wbTest",
  props: {
    widgetId: String,
    data: Array,
    editable: Boolean,
    resized: Number,
    setting: Boolean,
    lang: String,
    theme: String
  },
  data() {
    return {
      title:{per:'خام',eng:'wb component'}
    };
  },
  watch: {
    setting: {
      handler(val) {
        if (val)
          this.$emit("getWidgetData", {
            id: this.widgetId,
            attribute: {},
            datas: []
          });
      }
    },
  },
  mounted() {
    this.$emit("sseData", {
      id: this.widgetId,
      list: ["67963aed4a7d3d314d37114a"]
    })
  }
};
</script>

<style scoped>

</style>