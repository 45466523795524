<template>
  <div style="height: 100%; width: 100%">
    <div
      class="card-body p-1 d-flex justify-content-center align-items-center"
      style="height: calc(100% - 40px); width: 100%"
    >
      <div :ref="widgetId" :id="widgetId" style="height: 100%; width: 100%"></div>
    </div>

    <b-modal
      :id="widgetId"
      size="lg"
      centered
      body-class="my-body-class"
      hide-header-close
      :title="modalTitle[lang]"
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
      :dir="dict.dir[lang]"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div class="yekan">
        <b-container fluid="md" class="p-0">
          <div class="p-3">
            <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
              <li
                v-for="(item, index) in tabs"
                class="mga-tab-nav-item"
                @click="activeTab = item.value"
                :key="index"
              >
                <a
                  class="mga-tab-nav-link"
                  :class="{ active: activeTab === item.value }"
                  :id="item.name"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="tab1"
                  aria-selected="true"
                >
                  {{ item[lang] }}
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body h-100 p-3" style="overflow: hidden">
            <transition name="slide-fade" mode="out-in">
              <div class="tab-content h-100" :key="activeTab">
                <div v-if="activeTab === 'tab1'" class="h-100">
                  <div fluid class="container-fluid yekan p-0 h-100">
                    <b-table
                      ref="table1"
                      :items="myData.datas"
                      :fields="groupFields"
                      thead-class="hidden_header"
                      responsive="sm"
                      small
                    >
                      <template #cell(name)="row">
                        <p class="pt-3">{{ row.item.name }}</p>
                      </template>
                      <template #cell(color)="row">
                        <div class="d-flex align-items-center p-2">
                          <ejs-textbox
                            :value="row.item.color"
                            :enableRtl="lang === 'per'"
                            floatLabelType="Auto"
                            type="color"
                            style="width:120px"
                            v-model="row.item.color"
                          ></ejs-textbox>
                        </div>
                      </template>
                      <template #cell(mode)="row">
                        <div class="mt-3">
                          <ejs-dropdownlist
                            :enableRtl="lang === 'per'"
                            :dataSource="modeList"
                            floatLabelType="Auto"
                            :fields="{ text: lang, value: 'value' }"
                            v-model="row.item.mode"
                            @change="(val)=>{row.item.mode=val.value}"
                          ></ejs-dropdownlist>
                        </div>
                      </template>
                      <template #cell(shape)="row">
                        <div class="mt-3">
                          <ejs-dropdownlist
                            :enableRtl="lang === 'per'"
                            :dataSource="shapeList"
                            floatLabelType="Auto"
                            :fields="{ text: 'text', value: 'value' }"
                            v-model="row.item.shape"
                            @change="(val)=>{row.item.shape=val.value}"
                          ></ejs-dropdownlist>
                        </div>
                      </template>
                      <template #cell(dash)="row">
                        <div class="mt-3">
                          <ejs-dropdownlist
                            :enableRtl="lang === 'per'"
                            :dataSource="dashList"
                            floatLabelType="Auto"
                            :fields="{ text: lang ,value: 'value' }"
                            v-model="row.item.dash"
                            @change="(val)=>{row.item.dash=val.value}"
                          ></ejs-dropdownlist>
                        </div>
                      </template>
                      <!--                      <template #cell(archive)="row">-->
                      <!--                        <div class="mt-4 d-flex">-->
                      <!--                          <ejs-switch-->
                      <!--                            :enableRtl="lang == 'per'"-->
                      <!--                            v-model="row.item.isArchive"-->
                      <!--                            :checked="row.item.isArchive"-->
                      <!--                            @change="(val)=>{row.item.isArchive=val.checked}"-->
                      <!--                          />-->
                      <!--                          <span style="font-size: 12.5px" class="mx-2">{{ dict.ArchivesEnable[lang] }}</span>-->
                      <!--                        </div>-->
                      <!--                      </template>-->
                      <template #cell(modify)="row">
                        <b-button
                          pill
                          size="sm"
                          variant="outline-danger"
                          @click="removeItem(row.index)"
                          class="mr-2 mt-3"
                          style="
                              margin-inline: 10px;
                              outline: none !important;
                              border: none;
                              box-shadow: none;
                            "
                        >
                          <b-icon
                            icon="trash-fill"
                            aria-hidden="true"
                          ></b-icon>
                        </b-button>
                        <!--                        <div style="display: flex; justify-content: flex-end">-->
                        <!--                        -->
                        <!--                        </div>-->
                      </template>

                    </b-table>
                    <div
                      style="
                        display: flex;
                        justify-content: right;
                        align-items: center;
                      "
                    >
                      <b-button
                        @click="addItem"
                        size="sm"
                        variant="outline-danger"
                        style="
                          width: 20px;
                          padding: 0px;
                          outline: none !important;
                          border: none;
                          box-shadow: none;
                        "
                      >
                        <i>
                          <font-awesome-icon icon="fas fa-plus" />
                        </i>
                      </b-button>
                    </div>
                  </div>
                </div>
                <div v-if="activeTab === 'tab2'" class="h-100">
                  <div fluid class="container-fluid yekan p-0 h-100">
                    <div class="row mx-auto">
                      <div class="col-md-6 col-12">
                        <ejs-textbox
                          :value="myData.attribute.title"
                          :placeholder=" dict.title[lang]"
                          :enableRtl="lang === 'per'"
                          floatLabelType="Auto"
                          v-model="myData.attribute.title"
                        ></ejs-textbox>
                      </div>
                      <div class="col-md-6 col-12">
                        <ejs-textbox
                          :value="myData.attribute.fontColor"
                          :placeholder=" dict.font[lang]"
                          :enableRtl="lang === 'per'"
                          floatLabelType="Auto"
                          type="color"
                          @input="updateColor"
                          v-model="myData.attribute.fontColor"
                        ></ejs-textbox>
                      </div>
                      <div class="col-md-6 col-12">
                        <ejs-textbox
                          :value="myData.attribute.lineColor"
                          :placeholder=" dict.line[lang]"
                          :enableRtl="lang === 'per'"
                          floatLabelType="Auto"
                          type="color"
                          @input="updateColor"
                          v-model="myData.attribute.lineColor"
                        ></ejs-textbox>
                      </div>
                      <div class="col-md-6 col-12">
                        <ejs-textbox
                          :value="myData.attribute.gridColor"
                          :placeholder=" dict.grid[lang]"
                          :enableRtl="lang === 'per'"
                          floatLabelType="Auto"
                          type="color"
                          @input="updateColor"
                          v-model="myData.attribute.gridColor"
                        ></ejs-textbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </b-container>
      </div>
    </b-modal>
    <dataPickerModal :plotId="widgetId" />
  </div>
</template>

<script>
/* eslint-disable */

import Plotly from "plotly.js-dist/plotly";
import dataPickerModal from "./widgetPickDataModal.vue";
import { DialogUtility } from "@syncfusion/ej2-popups";

export default {
  name: "CustomPath",
  components: {
    dataPickerModal
  },
  props: {
    widgetId: String,
    data: Object,
    editable: Boolean,
    resized: Number,
    updateDOM: Number,
    setting: Boolean,
    sseData: Array,
    archiveData: Array,
    lang: String,
    theme: String
  },
  data() {
    return {
      activeTab: "tab1", // Set the initial active tab
      tabs: [
        { per: "داده", eng: "data", value: "tab1" },
        { per: "ویژگی", eng: "attribute", value: "tab2" }
      ],
      modalTitle: { per: "ویرایش چارت", eng: "Edit Chart" },
      myData: {
        datas: [],
        attribute: {
          title: "line chart",
          fontColor: "#000000",
          gridColor: "#d1d1d1",
          lineColor: "#d1d1d1"
        }
      },
      myDataTmp: {},

      groupFields: [
        { key: "name", label: "" },
        { key: "color", label: "" },
        { key: "mode", label: "" },
        { key: "shape", label: "" },
        { key: "dash", label: "" },
        { key: "archive", label: "" },
        { key: "modify", label: "" }
      ],

      submitted: false,
      successful: false,
      message: "",

      chartData: { dids: [], datasets: [] },
      modeList: [
        { per: "خطوط", eng: "lines", value: "lines" },
        { per: "نشانگرها", eng: "markers", value: "markers" },
        { per: "همه موارد", eng: "lines and markers", value: "lines+markers" }
      ],
      shapeList: [
        { text: "linear", value: "linear" },
        { text: "spline", value: "spline" },
        { text: "hv", value: "hv" },
        { text: "vh", value: "vh" },
        { text: "hvh", value: "hvh" },
        { text: "vhv", value: "vhv" }
      ],
      dashList: [
        { per: "ساده", eng: "solid", value: "solid" },
        { per: "نقطه", eng: "dot", value: "dot" },
        { per: "خط", eng: "dash", value: "dash" },
        { per: "نقطه و خط", eng: "dashdot", value: "dashdot" }
      ],


      dict: {
        ArchivesEnable: { eng: "archive", per: "داده آرشیو شده" },
        dir: { eng: "ltr", per: "rtl" },
        title: { eng: "title", per: "عنوان" },
        tab1: { eng: "user profile", per: "مشخصات کاربر" },
        tab2: { eng: "security settings", per: "تنظیمات امنیتی" },
        mode: { eng: "chart mode", per: "نوع ترسیم" },
        shape: { eng: "chart shape", per: "نحوه ترسیم" },
        dash: { eng: "chart dash", per: "نوع خط" },
        grid: { eng: "grid color", per: "رنگ گرید" },
        line: { eng: "line color", per: "رنگ خطوط" },
        font: { eng: "font color", per: "رنگ نوشتار" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        data: { eng: "data", per: "داده" },
        attribute: { eng: "attribute", per: "ویژگی" },
        deleteConfirm: { eng: "Confirmation Delete", per: "تاییدیه حذف" },
        deleteConfirmContent: {
          eng: "Are you sure to remove the data ?",
          per: "آیا از حذف این رکورد اطمینان دارید ؟"
        }
      },


      layout: {
        // height: 300,
        // width: 300,
        legend: true,
        margin: {
          l: 40,
          r: 40,
          b: 40,
          t: 40,
          pad: 4
        },
        paper_bgcolor: "transparent",
        plot_bgcolor: "transparent",
        font: { color: "#000000" },
        modebar: { bgcolor: "transparent", color: "rgb(14, 186, 186)", activecolor: "rgb(10, 139, 139)" },

        xaxis: {
          automargin: true,
          gridcolor: "#02113e",
          linecolor: "#02113e",
          autorange: true,
          rangeselector: {
            bgcolor: "rgb(14, 186, 186)",
            buttons: [
              {
                count: 1,
                label: "1m",
                step: "minute",
                stepmode: "backward",
                font: { color: "red" }
              },
              {
                count: 1,
                label: "1h",
                step: "hour",
                stepmode: "backward"
              },
              {
                count: 1,
                label: "1d",
                step: "day",
                stepmode: "backward"
              },
              { step: "all" }
            ]
          }

          // rangeslider: {range: ['2022-12-19', '2022-12-21']},
          //               type: 'date'
        },

        yaxis: {
          gridcolor: "#02113e",
          linecolor: "#02113e",
          autorange: true,
          // range: [86.8700008333, 138.870004167],
          type: "linear",
          automargin: true
        }

      },

      config: {
        useResizeHandler: true,
        displayModeBar: true,
        displaylogo: false,
        responsive: true,
        modeBarButtonsToRemove: ["toImage", "sendDataToCloud"],
        modeBarButtonsToAdd: [{
          name: "downloadCsv", title: "Download data as csv", icon: Plotly.Icons.disk, click: function(e) {
            for (var trace of e.data) {
              if (!(trace.hasOwnProperty("visible") && (trace.visible != true))) {
                var chart_title = trace.name;
                var csvData = [];
                // csvData.push([trace.name,null]);
                csvData.push(["time", "value"]);
                for (var i = 0; i < trace.x.length; i++) {
                  csvData.push([trace.x[i], trace.y[i]]);
                }
                var csvString = csvData.map(e => e.map(a => "\"" + ((a || "").toString().replace(/"/gi, "\"\"")) + "\"").join(",")).join("\r\n"); //quote all fields, escape quotes by doubling them.
                var universalBOM = "\uFEFF";
                var link = window.document.createElement("a");
                link.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent(universalBOM + csvString));
                link.setAttribute("download", chart_title + ".csv");
                // link.setAttribute('download', 'example.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }
          }
        }]
      }
    };

  },
  computed: {
    updatedData() {
      return this.$store.state.panel.record;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    getSSeData() {
      const a = this.updateDOM;
      return this.sseData;
    }
  },
  watch: {
    getSSeData: {
      handler(newValue) {
        for (var Data of newValue) {
          for (var dindx = 0; dindx < this.chartData.datasets.length; dindx++) {
            if (JSON.stringify(Data.id) === JSON.stringify(this.chartData.datasets[dindx].did)) {
              var x = new Date(Data._time);
              var y = Data.value;
              Plotly.extendTraces(this.widgetId, { y: [[y]], x: [[x]] }, [dindx], 100);

              var currentLength = this.chartData.datasets[dindx].x.length;
            }
          }
        }
      }, deep: true
    },
    child2parent(newValue) {
      if ((newValue.task === "pickData") && (newValue.to === this.widgetId)) {
        if (newValue.item.type === "data") {
          this.myData.datas.push({
            ...newValue.item,
            color: "#000000",
            mode: "lines",
            shape: "linear",
            dash: "solid",
            isArchive: true
          });
        }
      }
    },
    resized() {
      this.resizeChart();
    },
    data(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        if (newValue) {
          this.myData = JSON.parse(JSON.stringify(newValue));
          this.selChart();
        }
      }
    },
    setting(newValue) {
      if (newValue)
        this.$root.$emit("bv::show::modal", this.widgetId);
    }
  },
  methods: {
    callToAddSSE() {
      let idList = [];
      this.data.datas.forEach((item) => {
        idList.push(item._id);
      });

      this.$emit("getWidgetData", {
        id: this.widgetId,
        list: idList,
        action: "add"
      });
    },
    callToRemoveSSE() {
      let idList = [];
      this.data.datas.forEach((item) => {
        idList.push(item._id);
      });

      this.$emit("getWidgetData", {
        id: this.widgetId,
        list: idList,
        action: "remove"
      });
    },
    removeWidget() {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.deleteConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            this.$store.dispatch("chpge/child2parent", { task: "rmDashItem", item: this.widgetId });
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    },
    editWidget() {
      // console.log('editwidget',this.widgetId)
      this.$root.$emit("bv::show::modal", this.widgetId);
    },

    selChart(index) {
      var color = ["rgb(231, 43, 190)", "rgb(120, 120, 224)", "rgb(72, 200, 74)", "rgb(216, 192, 39)"];
      this.chartData = { dids: [], datasets: [] };
      if (this.myData && this.myData.datas) {
        for (var d of this.myData.datas) {
          var dslen = this.chartData.datasets.length;
          this.chartData.dids.push(d._id);
          this.chartData.datasets.push({
            type: "scatter",
            mode: d.mode,
            name: d.name,
            line: { shape: d.shape, color: d.color, dash: d.dash, width: 2 },  //'hv' , 'vh', 'hvh', 'vhv' , 'spline' , 'linear' // dash: 'solid','dot','dashdot','dash,
            marker: {
              color: color[dslen + 1],
              size: 10
            },
            did: d._id,
            x: [],
            y: []
          });
        }
        if (this.chartData.dids.length > 0)
          this.charthandler();
        else
          this.plotChart();

      }
    },

    charthandler() {
      // if ("datas" in this.data) {
      //   let idList = [];
      //   this.data.datas.forEach((item) => {
      //     if (item.isArchive == true)
      //       idList.push(item._id);
      //   });
      //   this.$emit("archiveData", {
      //     id: this.widgetId,
      //     list: idList
      //   });
      // }
      this.$store.dispatch("data/getChartData", {
        dids: this.chartData.dids,
        interval: "inherent",
        from: "",
        to: "",
        type: [],
        maxdata: 100,
        datalimit: true
      }).then(
        charts => {
          for (var chart of charts) {
            var index = this.chartData.datasets.findIndex(x => JSON.stringify(x.did) === JSON.stringify(chart.id));
            if (index != -1) {

              var x = [];
              var y = [];
              chart.values.forEach(element => {
                if (element._time && element.value) {
                  x.push(new Date(element._time));
                  y.push(element.value);
                } else {
                  //console.log(element)
                }
              });
              this.chartData.datasets[index].x = x;
              this.chartData.datasets[index].y = y;
            }
          }
          this.plotChart();
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    plotChart() {
      this.layout.font.color = this.myData.attribute.fontColor;
      this.layout.xaxis.gridcolor = this.myData.attribute.gridColor;
      this.layout.yaxis.gridcolor = this.myData.attribute.gridColor;
      this.layout.xaxis.linecolor = this.myData.attribute.lineColor;
      this.layout.yaxis.linecolor = this.myData.attribute.lineColor;

      Plotly.newPlot(this.$refs[this.widgetId], this.chartData.datasets, this.layout, this.config);

      var myPlot = document.getElementById(this.widgetId);
      var iiiddd = this.widgetId;
      var abc = 0;
      myPlot.on("plotly_afterplot", function() {
        abc += 1;
        if (abc === 1) {
          var update = {
            autosize: true
          };
          Plotly.relayout(iiiddd, update);
        }
      });

    },
    initModal: function() {
      //console.log('init modal', this.myData)
      // this.$refs.table1.refresh();
      this.myDataTmp = JSON.parse(JSON.stringify(this.myData));
    },
    resetModal: function() {
      //console.log('reset modal')
      //console.log('#2')
      this.myData = JSON.parse(JSON.stringify(this.myDataTmp));

      this.submitted = null;

      this.successful = null;
      this.message = null;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      //console.log('submit',this.type)
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {

          this.$store.dispatch("data/editDashboard", { _id: this.widgetId, data: this.myData }).then(
            data => {
              this.$store.dispatch("chpge/child2parent", { task: "updateDash" });
              this.myDataTmp = JSON.parse(JSON.stringify(this.myData));
              this.$nextTick(() => {
                this.$bvModal.hide(this.widgetId);
              });
            }
          );
        }
      });
    },
    addItem: function(type) {
      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: this.widgetId,
        filter: {
          dataType: ["Number", "Boolean", "Object"],
          usedBy: [],
          parents: [],
          archive: null,
          perPage: 1000,
          page: 1,
          withChild: false
        }
      });
      this.$root.$emit("bv::show::modal", "modal-widget-pick-data-" + this.widgetId);
    },
    removeItem: function(index) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.deleteConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            this.myData.datas.splice(index, 1);
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    },
    updateColor() {
      this.layout.font.color = this.myData.attribute.fontColor;

      this.layout.xaxis.gridcolor = this.myData.attribute.gridColor;
      this.layout.yaxis.gridcolor = this.myData.attribute.gridColor;


      this.layout.xaxis.linecolor = this.myData.attribute.lineColor;
      this.layout.yaxis.linecolor = this.myData.attribute.lineColor;

      Plotly.redraw(this.widgetId);
    },
    resizeChart() {
      var update = {
        autosize: true
      };
      Plotly.relayout(this.widgetId, update);
    }


  },

  mounted() {
    if (this.data && this.data.datas && this.data.attribute) {
      this.myData = this.data;
      if ("datas" in this.data) {
        let idList = [];
        this.data.datas.forEach((item) => {
          idList.push(item._id);
        });

        this.$emit("sseData", {
          id: this.widgetId,
          list: idList
        });
      }
    }
    this.selChart();
  }
};
</script>


<style scoped>
.remove {
  cursor: pointer;
  color: rgb(175, 235, 11);
  font-size: 18px;
}

.edit {
  cursor: pointer;
  color: rgb(9, 180, 210);
  font-size: 18px;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-100%);

}


</style>

<style>
.my-body-class {
  padding: 0px !important;
  /* Add any other custom styles you want */
}
</style>


