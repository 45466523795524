<template>
  <div>
    <ul class="mx-0 px-0">
      <li class="mx-0 px-2" v-for="(value, key) in data" :key="key">
      <span style="color: midnightblue" @click="toggle(key)" :class="{ caret: isObject(value), 'caret-down': openedKeys.includes(key) }">
        {{ key }} :
      </span>
        <span class="e-icons e-add" @click="addItem(key)"></span>
        <span class="e-icons e-minus" @click="removeItem(key)"></span>
        <ul class="m-1 px-1" v-if="isObject(value)" :class="{ nested: !openedKeys.includes(key), active: openedKeys.includes(key) }">
          <tree-view :data="value" v-if="isObject(value)"></tree-view>
        </ul>
        <span v-else style="font-size: 13px">{{ value }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TreeView",
  props: ["data"],
  data() {
    return {
      openedKeys: []
    };
  },
  methods: {
    isObject(val) {
      return val && typeof val === "object";
    },
    toggle(key) {
      const index = this.openedKeys.indexOf(key);
      if (index > -1) {
        this.openedKeys.splice(index, 1);
      } else {
        this.openedKeys.push(key);
      }
    },
    addItem(key) {
      if (this.isObject(this.data[key])) {
        this.$set(this.data[key], "newKey", "newValue");
      } else {
        this.$set(this.data, "newKey", "newValue");
      }
    },
    removeItem(key) {
      this.$delete(this.data, key);
    }
  }
};
</script>

<style>
ul {
  list-style-type: none;
}

.caret {
  cursor: pointer;
  user-select: none;
}

.caret::before {
  content: "\25B6";
  display: inline-block;
  margin-right: 6px;
}

.caret-down::before {
  content: "\25BC";
}

.nested {
  display: none;
}

.active {
  display: block;
}

.icon {
  margin-left: 6px;
  cursor: pointer;
}
</style>
