<template>
  <div>
    <b-modal
      id="modal-widget-selector"
      size="lg"
      centered
      body-class="MWS-body-class"
      body-text-variant="dark"
      content-class="shadow"
      @shown="initModal"
      @hidden="resetModal"
      hide-footer
    >
      <template #modal-header>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-between">
          <div><h5>{{ dict.title[lang] }}</h5></div>
          <div><i>
            <font-awesome-icon icon="fas fa-xmark" class="close" @click="handleClose" />
          </i></div>
        </div>
      </template>

      <!-- <div :dir="dict.dir[lang]" class="container-fluid p-0 bg-none yekan" :class="lang==='per'?'text-end':'text-start'" style="min-height: calc(100vh - 60px)"> -->
      <div :dir="dict.dir[lang]" class="p-0">
        <!-- <b-container fluid> -->

        <div class="mga-tab text-center h-100 p-0">
          <div class="mga-tab-header">
            <!-- <ul class="d-flex p-0 m-0" style="border: 1px solid blue"> -->
            <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
              <li class="mga-tab-nav-item" @click="activeTab = 'tab1'">
                <p class="mga-tab-nav-link"
                   :class="{ active: activeTab === 'tab1' }"
                   id="tab1-tab"
                   data-toggle="tab"
                   role="tab"
                   aria-controls="tab1"
                   aria-selected="true"
                >
                  {{ dict.tab1[lang] }}
                </p>
              </li>
              <li class="mga-tab-nav-item" @click="activeTab = 'tab2'">
                <p class="mga-tab-nav-link"
                   :class="{ active: activeTab === 'tab2' }"
                   id="tab2-tab"
                   data-toggle="tab"
                   role="tab"
                   aria-controls="tab2"
                   aria-selected="true"
                >
                  {{ dict.tab2[lang] }}
                </p>
              </li>
<!--              <li class="mga-tab-nav-item" @click="activeTab = 'tab3'">-->
<!--                <p class="mga-tab-nav-link"-->
<!--                   :class="{ active: activeTab === 'tab3' }"-->
<!--                   id="tab2-tab"-->
<!--                   data-toggle="tab"-->
<!--                   role="tab"-->
<!--                   aria-controls="tab3"-->
<!--                   aria-selected="true"-->
<!--                >-->
<!--                  {{ dict.tab3[lang] }}-->
<!--                </p>-->
<!--              </li>-->
            </ul>
          </div>
          <div class="mga-tab-body ltrclass">
            <transition :name="dict.transition[lang]" mode="out-in">
              <div class="tab-content h-100" :key="activeTab">
                <div v-if="activeTab === 'tab1'" class="h-100">
                  <div style="height:300px">
                    <PerfectScrollbar style="padding:0 10px 0 0 !important">
                      <div style="height:100%;  display:flex; flex-wrap:wrap; gap:10px; padding:10px ">
                        <div v-for="(item, i) in widgetsList" :key="i" class="widget"
                             @click="addDashItem('widget',item)">
                          {{ item.title }}
                        </div>
                        <div class="new-widget" @click="addWidget">
                          <i>
                            <font-awesome-icon icon="fas fa-plus" size="lg" />
                          </i>
                        </div>
                      </div>
                    </PerfectScrollbar>
                  </div>
                </div>
                <div v-else-if="activeTab === 'tab2'" class="h-100">
                  <div style="height:300px">
                    <PerfectScrollbar style="padding:0 10px 0 0 !important">
                      <div v-if="contWidgetsList.length<=0" class="d-flex align-items-center justify-content-center mt-5">
                        <p class="text-danger">{{dict.noData[lang]}}</p>
                      </div>
                      <div style="height:100%;  display:flex; flex-wrap:wrap; gap:10px; padding:10px ">
                        <div v-for="(item, i) in contWidgetsList" :key="i" class="widget"
                             @click="addDashItem('contWidget',item)">
                          {{ item.title }}
                        </div>
                      </div>
                    </PerfectScrollbar>
                  </div>
                </div>
<!--                <div v-else-if="activeTab === 'tab3'" class="h-100">-->
<!--                  tab3-->
<!--                </div>-->
              </div>
            </transition>
          </div>
        </div>

        <!-- </b-container> -->
      </div>
    </b-modal>
    <addWidget />
  </div>
</template>

<script>
/* eslint-disable */
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import addWidget from "./widgetAddModal.vue";

export default {
  name: "dataPickerModal",
  props: {
    plotId: String
  },
  components: {
    PerfectScrollbar,
    addWidget
  },
  data() {
    return {
      activeTab: "tab1",
      widgetsList: [],
      contWidgetsList: [],
      to: "",
      type: "data",
      dict: {
        dir: { eng: "ltr", per: "rtl" },
        transition: { eng: "slide-fade-ltr", per: "slide-fade-rtl" },
        title: { eng: "widget palette", per: "پالت ویجت" },
        tab1: { eng: "widget 1", per: "ویجت 1" },
        tab2: { eng: "widget 2", per: "ویجت 2" },
        tab3: { eng: "widget 3", per: "ویجت 3" },
        noData: { eng: "No controller widget found.", per: "هیچ ویجت کنترلری پیدا نشد" },
      }
    };
  },
  computed: {
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    updateWL() {
      return this.$store.state.chpge.child2parent;
    }
  },
  watch: {
    parent2child(newValue) {
      if (newValue.task === "pickData") {
        this.to = newValue.from;
        this.filterData = newValue.filter;
      }
    },
    updateWL(newValue) {
      if (newValue.task === "updateWidgetList") {
        console.log("updateWidgetList");
        // this.getControlBlocks();
      }
    }
  },
  methods: {
    initModal: function() {
      this.getAllWidgets();
    },
    resetModal: function() {
      this.widgetsList = [];
      this.contWidgetsList = [];
    },
    handleClose() {
      this.$bvModal.hide("modal-widget-selector");
    },
    addWidget() {
      this.$root.$emit("bv::show::modal", "modal-addWidget");
    },
    addDashItem(type, item) {
      this.$store.dispatch("chpge/child2parent", { task: "addDashItem", type: type, item: item });
      this.$bvModal.hide("modal-widget-selector");
    },

    getAllWidgets() {
      // this.$store.dispatch('data/getWidgetsList').then(
      //     data => {
      //
      //         data.forEach(widget=>{
      //             this.widgetsList.push({id:widget._id ,title:widget.attributes.title, size:widget.attributes.size, data:{}})
      //
      //         })
      //         console.log("==============================: ",this.widgetsList)
      //     },
      //     error => {
      //         this.message =
      //         (error.response && error.response.data && error.response.data.message) ||
      //         error.message ||
      //         error.toString();
      //         // this.successful = false;
      //     }
      // );
      this.widgetsList = [
        { data: {}, size: { width: 5, height: 5 }, id: "chart", title: "Line chart" },
        { data: {}, size: { width: 5, height: 5 }, id: "pieChart", title: "Pie Chart" },
        { data: {}, size: { width: 5, height: 5 }, id: "radialGauge", title: "Radial Gauge" },
        { data: {}, size: { width: 5, height: 5 }, id: "linearGauge", title: "Linear Gauge" },
        { data: {}, size: { width: 5, height: 5 }, id: "deviceStatus", title: "Device Status" },
        // { data: {}, size: { width: 5, height: 5 }, id: "map", title: "Map" },
        // { data: {}, size: { width: 5, height: 5 }, id: "textInput", title: "input text" },
        // { data: {}, size: { width: 5, height: 5 }, id: "numberInput", title: "input number" },
        // { data: {}, size: { width: 5, height: 5 }, id: "switchInput", title: "input switch" },
        // { data: {}, size: { width: 5, height: 5 }, id: "checkboxInput", title: "input checkbox" },
        { data: {}, size: { width: 5, height: 5 }, id: "widgetCombinedMapTable", title: "fire events" },
        // { data: {}, size: { width: 5, height: 5 }, id: "fireEvents", title: "events table" },
        // { data: {}, size: { width: 5, height: 5 }, id: "wbComponentPure", title: "pure" },
        // { data: {}, size: { width: 5, height: 5 }, id: "tableWidget", title: "table widget" },
        // { data: {}, size: { width: 5, height: 5 }, id: "LinearGuage", title: "linear guage" },
        // { data: {}, size: { width: 5, height: 5 }, id: "sliderVertical", title: "vertical Slider" },
        // { data: {}, size: { width: 5, height: 5 }, id: "sliderHorizontal", title: "horizontal Slider" },
        // { data: {}, size: { width: 5, height: 5 }, id: "sliderCircular", title: "circular Slider" },
        // { data: {}, size: { width: 5, height: 5 }, id: "gauge", title: "Gauge" },

        // { data: {}, size: { width: 5, height: 5 }, id: "proccessflow", title: "Process flow" },
        // { data: {}, size: { width: 5, height: 5 }, id: "scada", title: "Scada" },
        // { data: {}, size: { width: 5, height: 5 }, id: "markerWidget", title: "markers layer" },
      ];

      this.$store.dispatch("data/getAllData", { dataType: ["complex"], usedBy: [], parents: [], archive: null,page:1,perPage:2000 }).then(
        data => {
          data.newmatches.forEach(node => {
            let ai = node.usedBy.findIndex(x => x.utype === "widget");
            if (ai === -1) {
              var widgetData = {
                datas: [{ type: node.type, _id: node._id, name: node.name }],
                attribute: { title: node.name }
              };
              this.contWidgetsList.push({
                id: this.toCamelCase(node.value.type),
                title: node.name,
                size: { width: 5, height: 5 },
                data: widgetData
              });
            }
          });
          // console.log("==============================: ",this.contWidgetsList)
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    toCamelCase(inputString) {
      // Split the input string into words
      const words = inputString.split(/\s+/);

      // Format each word to start with a lowercase letter
      const camelCaseString = words
        .map((word, index) => {
          if (index === 0) {
            // Capitalize the first word
            return word.charAt(0).toLowerCase() + word.slice(1);
          } else {
            // Format subsequent words to start with an uppercase letter
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          }
        })
        .join("");

      return camelCaseString;
    }
  }
};
</script>

<style scoped>
.close {
  font-size: 20px;
  color: #dc3545;
  cursor: pointer;
}

.close :hover {
  color: #9b9b9b;
}

.ps {
  height: 100%;
}

.widget {
  width: 145px;
  height: 145px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ced4da;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
}

.widget:hover {
  border: 2px solid #113cfc;
  color: #113cfc;
}

.new-widget {
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ced4da;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
}

.new-widget:hover {
  border: 2px dashed #113cfc;
  color: #113cfc;
}

/* .mga-tab {
    position: relative;
    display: flex;
    flex-direction: column;
}
.mga-tab-header {
    padding: 0.5rem 0.5rem 0 0.5rem;
    margin-bottom: 0;
    background-color: transparent;
}
.mga-tab-body {
    flex: 1 1 auto;
    height: 100%;
    overflow: hidden;
    padding:0;

}

.mga-tab-nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.mga-tab-nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #6c757d;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .mga-tab-nav-link {
        transition: none;
    }
}
.mga-tab-nav-link:hover, .mga-tab-nav-link:focus {
    color: #113cfc;
}
.mga-tab-nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
}
.mga-tab-nav-tabs {
    border-bottom: 1px solid #b8b8b8;
}
.mga-tab-nav-tabs .mga-tab-nav-link {
    margin-bottom: -1px;
}
.mga-tab-nav-tabs .mga-tab-nav-link:hover, .mga-tab-nav-tabs .mga-tab-nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
    isolation: isolate;
}
.mga-tab-nav-tabs .mga-tab-nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}
.mga-tab-nav-tabs .mga-tab-nav-link.active,
.mga-tab-nav-tabs .mga-tab-nav-item.show .mga-tab-nav-link {
    color:#113cfc;
    border-bottom: 1mm solid rgb(17, 60, 252);
} */


</style>
<style scoped src="@/assets/styles/theme.css"></style>
<style scoped>
.slide-fade-rtl-enter-active, .slide-fade-rtl-leave-active, .slide-fade-ltr-enter-active, .slide-fade-ltr-leave-active {
  transition: transform 0.3s ease;
}

.slide-fade-rtl-enter, .slide-fade-rtl-leave-to {
  transform: translateX(100%);
}

.slide-fade-ltr-enter, .slide-fade-ltr-leave-to {
  transform: translateX(-100%);
}
</style>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"></style>


<style>
.MWS-body-class {
  padding: 0 !important;
}
</style>
