<template>
  <div dir="ltr">
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 56">
      <rect class="cls-7" y="-.34" width="240.51" height="56.68" rx="7.91" ry="7.91" />
      <rect class="cls-9" x="2.96" y="2.22" width="234.58" height="51.56" rx="4.56" ry="4.56" />
      <rect class="cls-2" x="4.94" y="4.1" width="174.81" height="47.14" rx="2.3" ry="2.3" />
      <rect class="cls-4" x="28.09" y="4.1" width="153.96" height="47.14" />
      <path class="cls-4"
            d="m184.34,4.1h48.17c1.46,0,2.64,1.18,2.64,2.64v41.44c0,1.46-1.18,2.64-2.64,2.64h-48.17V4.1h0Z" />
      <rect class="cls-5" x="9.45" y="7.72" width="13.96" height="6.38" />
      <rect class="cls-3" x="8.55" y="6.59" width="16.43" height="2.5" />
      <rect class="cls-8" x="8.55" y="11.6" width="16.43" height="2.5" />
      <rect class="cls-5" x="8.55" y="9.1" width="16.43" height="2.5" />

      <!-- Add text elements with IDs -->
      <text id="pelak2" x="35" y="30" class="text-size">{{ pelakData.pelak2 }}</text>
      <text id="pelak3" x="100" y="30" class="text-size">{{ pelakData.pelak3 }}</text>
      <text id="pelak4" x="140" y="30" class="text-size">{{ pelakData.pelak4 }}</text>
      <text id="pelak1" x="200" y="30" class="text-size">{{ pelakData.pelak1 }}</text>

    </svg>
  </div>
</template>

<script>
export default {
  name: "svgPlak",
  props: {
    data: { default: {} }
  },
  data() {
    return {
      pelakData: {}
    };
  },
  mounted() {
    this.pelakData=this.data
  }
};
</script>
<style>
.text-size {
  font-size: 24px !important;
  color: black !important;
}
</style>
<style scoped>
.cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8 {
  stroke-width: 0px;
}

.cls-2 {
  fill: #193179;
}

.cls-3 {
  fill: #d60000;
}

.cls-4 {
  fill: #d2d2d2;
}

.cls-9 {
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.cls-5 {
  fill: #fff;
}

.cls-6 {
  fill: #d70000;
}

.cls-7 {
  fill: #a5a5a5;
}

.cls-8 {
  fill: #078e00;
}

</style>
