<template>
  <div
    :dir="dict.dir[lang]"
    class="container-fluid p-0 bg-none"
    :class="lang === 'per' ? 'text-end' : 'text-start'"
    style="min-height: calc(100vh - 60px)"
  >
    <div class="p-3 m-0 bg-page-title d-flex">
      <div
        :class="
          dict.dir[lang] === 'rtl'
            ? 'page-title-icon-rtl'
            : 'page-title-icon-ltr'
        "
      >
        <img
          src="@/assets/icons/data_archive-1.png"
          alt="dashboard"
          style="width: 60px; background: none !important"
        />
      </div>
      <div class="d-flex align-items-center">
        <h5 class="mb-0">{{ dict.title[lang] }}</h5>
      </div>
    </div>

    <div
      class="row g-0 p-2"
      :class="lang === 'per' ? 'text-end' : 'text-start'"
      style="min-height: calc(100vh - 152px)"
    >
      <b-container fluid>
        <b-card no-body>
          <!-- style="max-height: 720px; overflow-y: scroll; height:100%" -->
          <b-card-body style="min-height: calc(100vh - 170px)">
            <ejs-grid
              ref="wb_admin"
              :width="'auto'"
              :locale="lang"
              :key="list.length"
              height="100%"
              :allowSorting="true"
              :toolbar="toolbarEType"
              :allowFiltering="true"
              :toolbarClick="toolbarClick"
              :allowPdfExport="true"
              :enableAdaptiveUI="screenWidth<=600"
              :allowPaging="true"
              :enableRtl="lang == 'per'"
              :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
              :dataSource="list"
            >
              <e-columns>
                <e-column
                  v-for="(item, index) in fields"
                  :key="index"
                  :field="item.key"
                  :width="screenWidth<=600?'170':'auto'"
                  :headerText="item[lang]"
                  :template="item.hasTemplate ? item.key : null"
                ></e-column>
              </e-columns>
              <template v-slot:endTime="{data}">
                <p>{{ timeConverter(data.endTime) }}</p>
              </template>
              <template v-slot:startTime="{data}">
                <p>{{ timeConverter(data.startTime) }}</p>
              </template>
              <template v-slot:modify="{data}">
                <div class="d-flex justify-content-between">

                  <span
                    @click="edit(data)"
                    style="font-size: 17px"
                    class="e-icons e-edit-6">
                  </span>
                  <span
                    @click="remove(data._id)"
                    style="font-size: 17px"
                    class="e-icons e-delete-1 text-danger"></span>
                  <span
                    v-if="data.destination!='api'"
                    @click="getData(data)"
                    style="font-size: 17px"
                    class="e-icons e-chart-2d-clustered-column"></span>
                  <span
                    v-if="data.destination!='api' && exportLoading==false"
                    @click="getExport(data)"
                    style="font-size: 17px"
                    class="e-icons e-export-excel-2"></span>
                  <span
                    v-show="exportLoading"
                    class="spinner-border spinner-border-sm"
                  ></span>

                </div>
              </template>

            </ejs-grid>

          </b-card-body>
        </b-card>
      </b-container>
    </div>
    <setEquipDataSel />
    <dataPickerModal plotId="SHD" />
    <b-modal
      id="search-modal"
      size="lg"
      centered
      hide-header-close
      content-class="shadow"
      no-close-on-backdrop
      @shown="initSearchModal"
      @hidden="handleSearchModalReset"
    >
      <template #modal-header>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-between">
          <h5>{{ dict.searchModal[lang] }}</h5>
        </div>
      </template>

      <template #modal-footer>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-end">
          <div class="d-flex" style="gap: 10px">
            <b-button variant="primary" @click="handleExport">{{
                dict.search[lang]
              }}
            </b-button>
            <b-button variant="secondary" @click="handleSearchModalReset">{{
                dict.cancel[lang]
              }}
            </b-button>
          </div>
        </div>
      </template>

      <div>
        <div class="panel_body" id="panelBody">
          <b-list-group flush>
            <b-list-group-item
              style="background-color: transparent; color: black"
            >
              <div class="d-flex justify-content-around gap-3 mb-3">
                <ejs-textbox
                  :autocomplete="'off'"
                  floatLabelType="Auto"
                  :required="true"
                  :placeholder="dict.name[lang]"
                  v-model="equipment.name"
                ></ejs-textbox>

                <ejs-dropdownlist
                  floatLabelType="Auto"
                  :required="true"
                  :dataSource="destinationType"
                  v-model="equipment.destination"
                  @change="
                    (val) => {
                      equipment.destination = val.value;
                    }
                  "
                  :placeholder="dict.destination[lang]"
                  :fields="{ text: 'text', value: 'value' }"
                ></ejs-dropdownlist>
              </div>


              <div
                style="
                  font-size: 13px;
                  color: rgba(0, 0, 0, 0.42);
                  margin-bottom: 5px;
                  font-weight: 400;
                "
              >
                {{ dict.dataSelect[lang] }}
              </div>


              <div class="d-flex">
                <div class="d-flex" style="flex-wrap: wrap; gap: 5px">
                  <span
                    v-for="(record, index) in equipment.data"
                    :key="index"
                    class="
                      badge
                      d-flex
                      p-2
                      align-items-center
                      bg-primary
                      text-light
                      rounded-pill
                    "
                  >
                    <span class="px-1">{{ record.name }}</span>
                    <p @click="removeItem(record)">
                      <i class="text-light"
                      ><font-awesome-icon
                        icon="fas fa-xmark-circle"
                        size="lg"
                      /></i>
                    </p>
                  </span>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: right;
                    align-items: center;
                  "
                >
                  <b-button
                    @click="addItem"
                    size="sm"
                    variant="outline-danger"
                    style="
                      width: 20px;
                      padding: 0px;
                      outline: none !important;
                      border: none;
                      box-shadow: none;
                    "
                  >
                    <i>
                      <font-awesome-icon icon="fas fa-plus" />
                    </i>
                  </b-button>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-12 col-md-6 d-flex justify-content-around">
                  <ejs-textbox
                    :autocomplete="'off'"
                    floatLabelType="Auto"
                    :readonly="true"
                    :enabled="equipment.destination=='api'"
                    :placeholder="dict.trigger[lang]"
                    v-model="equipment.trigger.name"
                  ></ejs-textbox>
                  <i
                    v-if="equipment.destination =='api'"
                    @click="pickData"
                    style="cursor: pointer"
                    class="text-secondary pt-4 mt-4"
                  >
                    <font-awesome-icon
                      icon="fas fa-edit"
                      size="lg"
                    />
                  </i>
                </div>

                <div class="col-12 col-md-6 mt-4"
                     v-if="equipment.trigger.dataType!='Boolean' && equipment.destination=='api'"
                     :key="equipment.trigger.dataType">
                  <ejs-textbox
                    :autocomplete="'off'"
                    floatLabelType="Auto"
                    :required="true"
                    placeholder="value"
                    :type="equipment.trigger.dataType"
                    v-model="equipment.trigger.value"
                  ></ejs-textbox>
                </div>
                <div class="col-12 col-md-6 my-4 pb-3"
                     v-if="equipment.trigger.dataType =='Boolean' && equipment.destination=='api'"
                     :key="equipment.trigger.dataType">
                  <ejs-dropdownlist
                    floatLabelType="Auto"
                    :required="true"
                    :dataSource="triggerBoolean"
                    v-model="equipment.trigger.value"
                    @change="
                    (val) => {
                      equipment.trigger.value = val.value;
                    }
                  "
                    placeholder="value"
                    :fields="{ text: 'text', value: 'value' }"
                  ></ejs-dropdownlist>
                </div>
              </div>

              <b-list-group horizontal="md" style="gap: 10px">
                <b-form-group
                  :label="dict.startTime[lang]"
                  label-for="from"
                  :invalid-feedback="errors.first('from')"
                  style="width: 100%; padding: 0px 0px 10px 0px"
                  v-show="lang === 'per'"
                >
                  <custom-date-picker
                    v-model="equipment.startTime"
                    name="from"
                    v-validate="'required'"
                  />

                  <div
                    tabindex="-1"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    class="invalid-feedback"
                    ref="fromData"
                    :style="{
                      display:
                        submitted && errors.has('from') ? 'block' : 'none',
                    }"
                  >
                    {{ errors.first("from") }}
                  </div>
                </b-form-group>

                <b-form-group
                  :label="dict.endTime[lang]"
                  label-for="to"
                  :invalid-feedback="errors.first('to')"
                  style="width: 100%; padding: 0px 0px 10px 0px"
                  v-show="lang === 'per'"
                >
                  <custom-date-picker
                    v-model="equipment.endTime"
                    name="to"
                    v-validate="'required'"
                  />

                  <div
                    tabindex="-1"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    class="invalid-feedback"
                    :style="{
                      display: submitted && errors.has('to') ? 'block' : 'none',
                    }"
                  >
                    {{ errors.first("to") }}
                  </div>
                </b-form-group>

                <div v-show="lang === 'eng'" class="w-100">
                  <input id="startDTP" type="text" />
                </div>

                <div v-show="lang === 'eng'" class="w-100">
                  <input id="endDTP" type="text" />
                </div>
              </b-list-group>

              <b-list-group horizontal="md" style="gap: 10px; margin-top: 10px">
                <div style="width: 100%; padding: 0px 0px 10px 0px">
                  <input type="text" tabindex="1" id="msel1" />
                </div>
                <div style="width: 100%; padding: 0px 0px 0px 0px">
                  <ejs-dropdownlist
                    floatLabelType="Auto"
                    :enabled="equipment.operations[0]!='none'"
                    :dataSource="intervalList"
                    v-model="equipment.resamplingRate"
                    @change="
                    (val) => {
                      equipment.resamplingRate = val.value;
                    }
                  "
                    :enableRtl="lang=='per'"
                    :placeholder="dict.resamplingRate[lang]"
                    :fields="{ text: lang, value: 'value' }"
                  ></ejs-dropdownlist>
                </div>
              </b-list-group>

              <div class="d-flex gap-5">
                <div class="form-check form-switch mt-4" style="width: 100%">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="CBA1"
                    v-model="equipment.outputLimit.status"
                    @change="disableNumericTextBox"
                  />
                  <label class="form-check-label"
                         style="font-size: 14px;color: rgba(82,86,91,0.84);font-weight: lighter!important;" for="CBA1">
                    {{ dict.actOutLim[lang] }} </label>
                </div>

                <div style="width: 100%; padding: 0px 0px 0px 0px">
                  <input type="text" tabindex="1" id="maxdata" />
                </div>
              </div>


              <div class="d-flex gap-5">
                <div class="form-check form-switch mt-4" style="width: 100%">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="CBA1"
                    v-model="equipment.gapHandling.status"
                    @change="disableNumericTextBox"
                  />
                  <label class="form-check-label"
                         style="font-size: 14px;color: rgba(82,86,91,0.84);font-weight: lighter!important;" for="CBA1">
                    {{ dict.actGapHndl[lang] }} </label>
                </div>

                <div style="width: 100%; padding: 0px 0px 0px 0px">
                  <ejs-textbox
                    :autocomplete="'off'"
                    :enabled="equipment.gapHandling.status"
                    v-model="equipment.gapHandling.value"
                    :placeholder="dict.gapHandling[lang]"
                    floatLabelType="Auto"
                  ></ejs-textbox>
                </div>
              </div>


            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="searched-data-modal"
      size="lg"
      title="search result chart"
      centered
      hide-header-close
      content-class="shadow"
    >
      <div>
        <div class="d-flex justify-content-between my-3">
          <p v-if="chartLoading">{{ dict.loadingChart[lang] }}</p>
          <p class="text-danger text-center" v-if="Object.keys(resultList).length<=0 && chartLoading==false">
            {{ dict.noData[lang] }}</p>
          <!--          <button @click="cancelIngestion" class="btn-sm btn-danger">{{dict.cancelIngest[lang]}} <span-->
          <!--            style="font-size: 17px"-->
          <!--            class="e-icons e-pan"></span></button>-->
        </div>
        <div ref="plot1" id="plot1"></div>
      </div>
      <template #modal-footer>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-end">
          <div class="d-flex" style="gap: 10px">
            <b-button variant="secondary" @click="cancelIngestion">{{
                dict.cancel[lang]
              }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import { MultiSelect } from "@syncfusion/ej2-dropdowns";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { DateTimePicker } from "@syncfusion/ej2-calendars";
import { NumericTextBox } from "@syncfusion/ej2-inputs";
import setEquipDataSel from "./setEquipDataSel.vue";
import Plotly from "plotly.js-dist/plotly";
import * as XLSX from "xlsx";
import dataPickerModal from "./widgetPickDataModal.vue";
import { FormValidator } from "@syncfusion/ej2-inputs";
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";
import { mapGetters } from "vuex";
import { DialogUtility } from "@syncfusion/ej2-popups";

export default {
  name: "setEquipLight",
  components: {
    setEquipDataSel,
    dataPickerModal
  },
  data() {
    return {
      selectedId: "",
      deleteConfirm: { eng: "Confirmation Delete", per: "تاییدیه حذف" },
      cancelConfirm: { eng: "Confirmation Cancel", per: "تاییدیه لغو" },
      deleteConfirmContent: {
        eng: "Are you sure to remove the record ?",
        per: "آیا از حذف این رکورد اطمینان دارید ؟"
      },
      cancelConfirmContent: {
        eng: "Are you sure to cancel the record ?",
        per: "آیا از لغو این رکورد اطمینان دارید ؟"
      },
      title: "Recording Settings",
      equipment: {
        name: "",
        format: "json",
        data: [],
        trigger: {
          dataType: "String",
          name: "",
          type: "",
          value: "",
          _id: ""
        },
        startTime: null,
        endTime: null,
        resamplingRate: null,
        outputLimit: {
          status: false,
          number: 200
        },
        gapHandling: {
          status: false,
          value: ""
        },
        destination: "platform",
        operations: []
      },
      cancel: { per: "بستن", eng: "cancel" },
      dataFields: [
        { key: "icon", label: "", thStyle: { width: "5%" } },
        { key: "dtype", label: "", thStyle: { width: "30%" } },
        { key: "dname", label: "", thStyle: { width: "30%" } },
        { key: "did", label: "", thStyle: { width: "40%" } },
        { key: "modify", label: "", thStyle: { width: "5%" } }],
      mode: "",
      destinationType: [
        { text: "platform", value: "platform" }
        // { text: "api", value: "api" }
      ],
      triggerBoolean: [
        { text: "true", value: true },
        { text: "false", value: false }
      ],

      typeList: [
        { per: "یک مورد انتخاب کنید", eng: "Select One", value: "null" },
        { per: "هیچکدام", eng: "none", value: "none" },
        { per: "آخرین", eng: "last", value: "last" },
        { per: "اولین", eng: "first", value: "first" },
        { per: "حداقل", eng: "minimum", value: "min" },
        { per: "حداکثر", eng: "maximum", value: "max" },
        { per: "میانگین", eng: "average", value: "mean" },
        { per: "شمارش", eng: "count", value: "count" }
      ],

      intervalList: [],

      intervalListfull: [
        { per: "2 ثانیه", eng: "2 sec", value: "2s" },
        { per: "5 ثانیه", eng: "5 sec", value: "5s" },
        { per: "10 ثانیه", eng: "10 sec", value: "10s" },
        { per: "15 ثانیه", eng: "15 sec", value: "15s" },
        { per: "30 ثانیه", eng: "30 sec", value: "30s" },
        { per: "1 دقیقه", eng: "1 min", value: "1m" },
        { per: "3 دقیقه", eng: "3 min", value: "3m" },
        { per: "5 دقیقه", eng: "5 min", value: "5m" },
        { per: "15 دقیقه", eng: "15 min", value: "15m" },
        { per: "30 دقیقه", eng: "30 min", value: "30m" },
        { per: "45 دقیقه", eng: "45 min", value: "45m" },
        { per: "1 ساعت", eng: "1 hour", value: "1h" },
        { per: "2 ساعت", eng: "2 hour", value: "2h" },
        { per: "3 ساعت", eng: "3 hour", value: "3h" },
        { per: "4 ساعت", eng: "4 hour", value: "4h" },
        { per: "1 روز", eng: "1 day", value: "1d" },
        { per: "1 هفته", eng: "1 week", value: "1w" },
        { per: "1 ماه", eng: "1 month", value: "1mo" },
        { per: "3 ماه", eng: "3 month", value: "3mo" },
        { per: "1 سال", eng: "1 year", value: "1y" }
      ],

      recordsList: [],
      chartLoading: false,
      exportLoading: false,
      submitted: false,
      successful: false,
      message: "",

      layout: {
        legend: true,
        legend_tracegroupgap: 180,
        // template:'plotly_dark',
        autosize: true,
        width: "100%",
        height: "auto",
        margin: {
          l: 80,
          r: 40,
          b: 40,
          t: 40,
          pad: 4
        },
        paper_bgcolor: "white",
        plot_bgcolor: "#e5ecf6",
        modebar: { bgcolor: "transparent", color: "rgb(14, 186, 186)", activecolor: "rgb(10, 139, 139)" },
        // xaxis: {
        //     autorange: true,
        //     gridcolor:"#ffffff",
        // },

        // yaxis: {
        //     // showgrid:false,
        //     gridcolor:"#ffffff",
        //     // linecolor:"#f0f5f9",
        //     autorange: true,
        //     type: 'linear'
        // },

        grid: {

          rows: 1,
          columns: 1,
          pattern: "independent",
          roeorder: "bottom to top"

        }

      },
      config: {
        displayModeBar: true,
        displaylogo: false,
        responsive: true,
        modeBarButtonsToRemove: ["sendDataToCloud"]
      },
      dict: {
        refresh: { eng: "refresh datas", per: "به روزرسانی اطلاعات" },
        actGapHndl: { eng: "active gap handling", per: "فعال کردن جایگزین" },
        actOutLim: { eng: "active output limit", per: "فعال کردن محدودیت تعداد نتایج" },
        dataSelect: { eng: "Selected data", per: "داده انتخاب شده" },
        noData: { eng: "no result to see!", per: ".نتیجه ای برای مشاهده یافت نشد" },
        loadingChart: { eng: "chart datas are loading....", per: "اطلاعات در حال بارگیری ..." },
        newArchive: { eng: "New record", per: "ایجاد رکورد" },
        dir: { eng: "ltr", per: "rtl" },
        title: { eng: "data Ingestion", per: " فرایند استخراج داده" },
        searchModal: { eng: "Search Panel", per: "پنل جستجو" },
        search: { eng: "Search", per: "جستجو" },
        cancel: { eng: "Cancel", per: "لغو" },
        cancelIngest: { eng: "cancel process", per: "متوقف کردن پردازش" },

        tab1: { eng: "data", per: "داده" },
        tab2: { eng: "structure", per: "ساختار" },
        tab3: { eng: "controller", per: "رخدادها" },

        wbname: { eng: "workbench name", per: "نام میزکار" },
        wbowner: { eng: "workbench owner", per: "مالک میزکار" },
        wbresources: { eng: "workbench resources", per: "منابع میزکار" },

        regmsg: { eng: "Don't have an account?", per: "حساب کاربری ندارید؟" },
        register: { eng: "Register now", per: "ثبت نام" },
        errCaptcha: { eng: "invalid captcha", per: "کد امنیتی وارد شده اشتباه می باشد" },
        errUserPass1: { eng: "invalid username or password", per: "نام کاربری یا کلمه عبور اشتباه است" },
        errUserPass2: { eng: "check your keyboard language", per: "• وضعيت زبان(En/FA)سيستم خود را كنترل كنيد" },
        errUserPass3: { eng: "the password is caps sensitive", per: "• پسورد به حروف بزرگ و كوچك حساس است" },
        name: { per: "نام", eng: "name" },
        format: { per: "فرمت", eng: "format" },
        data: { per: "داده", eng: "data" },
        trigger: { per: "محرک", eng: "trigger" },
        startTime: { per: "زمان شروع", eng: "start time" },
        endTime: { per: "زمان پایان", eng: "end time" },
        resamplingRate: { per: "نرخ واکشی", eng: "resampling rate" },
        outputLimit: { per: "محدودیت خروجی", eng: "output limit" },
        gapHandling: { per: "بررسی فواصل", eng: "gap handling" },
        destination: { per: "مقصد", eng: "destination" },
        operations: { per: "عملگرها", eng: "operations" }
      },
      fields: [
        { key: "name", per: "نام", eng: "name", hasTemplate: false },
        { key: "resamplingRate", per: "نرخ واکشی", eng: "resampling rate", hasTemplate: false },
        { key: "destination", per: "مقصد", eng: "destination", hasTemplate: false },
        { key: "startTime", per: "زمان شروع", eng: "start time", hasTemplate: true },
        { key: "endTime", per: "زمان پایان", eng: "end time", hasTemplate: true },
        { key: "modify", per: "", eng: "", hasTemplate: true }
      ],
      list: [],
      resultList: {},
      numericTextBox: null,
      showSearchModal: false,
      ok: { eng: "Ok", per: "قبول" }

    };
  },
  computed: {
    ...mapGetters("chpge", ["screenWidth", "screenHeight"]),
    toolbarEType() {
      return ["Search", { text: this.dict.newArchive[this.lang], id: "new_type", prefixIcon: "e-icons e-add" },
        { text: this.dict.refresh[this.lang], id: "refresh_table", prefixIcon: "e-icons e-refresh-2" }
      ];
    },
    updatePage() {
      return this.$store.state.chpge.reload;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    workbench() {
      return this.$store.state.data.workbench.activeWorkbench;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    wb() {
      return this.$store.state.data.workbench;
    }

  },
  watch: {
    "wb": {
      handler() {
        this.getList();
        this.initModal();
      }, deep: true
    },
    child2parent(newValue) {
      if ((newValue.task === "pickData") && (newValue.to === "historicalData")) {
        if (newValue.item.type === "data") {
          // let index = this.equipment.data.findIndex(x => x._id === newValue.item._id);
          // if (index == -1)
          this.equipment.data.push(newValue.item);
        }
      } else if ((newValue.task === "pickData") && (newValue.to === "historicalData_triggerData")) {
        if (newValue.item.type === "data") {
          this.equipment.trigger._id = newValue.item._id;
          this.equipment.trigger.type = newValue.item.type;
          this.equipment.trigger.name = newValue.item.name;
          this.equipment.trigger.dataType = newValue.item.dataType;
        }
      }
    },
    "equipment.destination": {
      handler(val) {
        if (val == "platform")
          this.equipment.trigger = "none";
        else {
          this.equipment.trigger = {
            dataType: "String",
            name: "",
            type: "",
            value: "",
            _id: ""
          };
        }
      }
    }
  },
  methods: {
    toolbarClick(args) {
      if (args.item.id == "new_type") {
        this.openSearchBox();
      } else if (args.item.id == "refresh_table") {
        this.getList();
      }

    },
    utcMaker(val) {
      let iranDate = new Date(val);

      const year = iranDate.getUTCFullYear();
      const month = String(iranDate.getUTCMonth() + 1).padStart(2, "0");  // Months are 0-indexed, so we add 1
      const day = String(iranDate.getUTCDate()).padStart(2, "0");
      const hours = String(iranDate.getUTCHours()).padStart(2, "0");
      const minutes = String(iranDate.getUTCMinutes()).padStart(2, "0");

      const formattedUTCDate = `${year}-${month}-${day}T${hours}:${minutes}Z`;
      return formattedUTCDate;
    },
    timeConverter(val) {
      let utcDate = new Date(val);

      let localDate = utcDate.toLocaleString("en-IR", {
        timeZone: "Asia/Tehran",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
      });

      return localDate;
    },
    getExport(value) {
      this.exportLoading = true;
      this.$store.dispatch("data/getSingleIngestion", { _id: value._id }).then(res => {

        Object.keys(res.data).forEach(function(key) {
          var wb = XLSX.utils.book_new();
          var ws = XLSX.utils.json_to_sheet(res.data[key]);
          XLSX.utils.book_append_sheet(wb, ws);
          XLSX.writeFile(wb, key + ".xlsx");
        });

        this.exportLoading = false;
      }).catch(() => {
        this.exportLoading = false;
      });
    },
    getData(value) {
      this.selectedId = value._id;

      this.chartLoading = true;
      this.$bvModal.show("searched-data-modal");
      this.$store.dispatch("data/getSingleIngestion", { _id: value._id }).then((data) => {
        this.resultList = data.data;
        var types = value.operations;
        var charts = [];
        var cnt = 0;
        var color = {
          max: "rgb(231, 43, 190)",
          min: "rgb(120, 120, 224)",
          mean: "rgb(72, 200, 74)",
          last: "rgb(216, 192, 39)",
          count: "rgb(174,33,33)",
          sum: "rgb(16,74,213)",
          first: "rgb(0, 0, 0)",
          value: "rgb(111,19,209)",
          none: "rgb(244,189,8)"
        };
        var ytitles = [];
        Object.keys(data.data).forEach(function(key) {
          ytitles.push(key);
          var chart = { name: key, datasets: [] };
          cnt++;
          types.forEach(x => {
              chart.datasets.push({
                type: "scatter",
                mode: "lines",
                name: x,
                line: { color: color[x] },
                x: [],
                y: [],
                xaxis: "x" + cnt,
                yaxis: "y" + cnt,
                legendgroup: x,
                showlegend: cnt > 1 ? false : true
              });
            }
          );
          data.data[key].forEach(x => {
            for (var tttype in types) {
              chart.datasets[tttype].x.push(new Date(x.timestamp));
              if (types[tttype] == "none")
                chart.datasets[tttype].y.push(parseFloat(x["value"]));
              else
                chart.datasets[tttype].y.push(parseFloat(x[types[tttype]]));
            }
          });
          charts.push(...chart.datasets);
        });
        this.layout.grid.rows = cnt;
        for (var cnt_i = 1; cnt_i <= cnt; cnt_i++) {
          this.layout["xaxis" + cnt_i] = {
            anchor: "x" + cnt_i,
            autorange: true,
            gridcolor: "#ffffff",
            calendar: "persian"
          };
          this.layout["yaxis" + cnt_i] = {
            anchor: "y" + cnt_i,
            autorange: true,
            gridcolor: "#ffffff",
            type: "linear",
            title: {
              text: ytitles[cnt_i - 1],
              font: {
                family: "Courier New, monospace",
                size: 18,
                color: "#7f7f7f"
              }
            }
          };
        }
        this.layout.height = window.innerHeight * cnt / 2.4;
        Plotly.newPlot("plot1", charts, this.layout, this.config);
        this.chartLoading = false;

      }).catch(() => {
        this.chartLoading = false;
      }).finally(() => {
        this.chartLoading = false;
      });
    },
    edit(val) {
      this.mode = "edit";
      this.equipment = val;
      this.$bvModal.show("search-modal");
    },
    remove(val) {
      const DialogObj = DialogUtility.confirm({
        title: this.deleteConfirm[this.lang],
        locale: this.lang,
        Enabletrl: this.lang == "per" ? true : false,
        content: this.deleteConfirmContent[this.lang],
        okButton: {
          text: this.ok[this.lang], click: () => {
            this.$store.dispatch("data/removeIngestion", { _id: val }).then(() => {
              this.getList();
            });
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }

    },
    cancelIngestion(val) {
      const DialogObj = DialogUtility.confirm({
        title: this.cancelConfirm[this.lang],
        locale: this.lang,
        Enabletrl: this.lang == "per" ? true : false,
        content: this.cancelConfirmContent[this.lang],
        okButton: {
          text: this.ok[this.lang], click: () => {
            this.$store.dispatch("data/cancelIngestion", { _id: this.selectedId }).then(() => {
              this.$bvModal.hide("searched-data-modal");
              this.getList();
            }).catch(() => {
              this.$bvModal.hide("searched-data-modal");
              this.getList();
            });
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }

    },
    pickData() {
      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: "historicalData_triggerData",
        filter: {
          dataType: ["!complex"],
          usedBy: [],
          parents: [],
          archive: false,
          withChild: false,
          page: 1,
          perPage: 5000
        }
      });
      this.$root.$emit("bv::show::modal", "modal-widget-pick-data-" + "SHD");
    },
    initModal: function() {
      this.getDashboard();
      //get data
      // this.modalTitle = this.type + ' ' + this.equipment.type
      // this.typeList = [{ text: 'Select One', value: null },'Actuator',{ text: 'Humidity Sensor', value: 'SensorHum' },{ text: 'Temperature Sensor', value: 'SensorTmp' }]
    },
    handleExport() {
      const isValid = new FormValidator("#panelBody").validate();

      if (this.mode != "edit") {
        if (isValid) {
          this.$nextTick(() => {
            this.$bvModal.hide("search-modal");
          });


          this.equipment.startTime = this.utcMaker(this.equipment.startTime);
          this.equipment.endTime = this.utcMaker(this.equipment.endTime);

          if (this.equipment.destination == "platform")
            this.equipment.trigger = "none";

          this.$store.dispatch("data/getHistoricalData", this.equipment).then(() => {
              this.getList();
              this.successful = true;
            }
          ).catch((error) => {
            this.message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            this.successful = false;
            // alert(this.message);
            this.getList();
          });

        }
      } else
        this.updateData();
      //
      // this.$validator.validate().then(isValid => {
      //
      //   // charthandler(type){
      //   //     console.log('charthandler',type)
      //   //     this.activeChart = type+'Data';
      //   //     this.$store.dispatch('data/getHistoricalData',{dids:this[type+'Data'].dids}).then(
      //   //         charts => {
      //   //         console.log(type)
      //   //         console.log(charts)
      //   //         for (var chart of charts){
      //
      //   //             var index = this[type+'Data'].datasets.findIndex(x=>JSON.stringify(x.did) === JSON.stringify(chart.did))
      //   //             console.log(index)
      //   //             if (index != -1){
      //
      //   //             this[type+'Data'].datasets[index].x = chart.x.map(time => new Date(time))
      //   //             this[type+'Data'].datasets[index].y = chart.y.map(value => parseFloat(value))
      //   //             // console.log(JSON.stringify(this[type+'Data'].datasets[index]))
      //   //             }
      //   //         }
      //   //         Plotly.newPlot(this.$refs.plot1, this[type+'Data'].datasets, this.layout);
      //   //         },
      //   //         error => {
      //   //             this.message =
      //   //                 (error.response && error.response.data && error.response.data.message) ||
      //   //                 error.message ||
      //   //                 error.toString();
      //   //                 // this.successful = false;
      //   //         }
      //   //     )
      //   // },
      //
      // });

    },
    getDashboard() {

      this.$store.dispatch("data/getRecordSettings").then(
        data => {
          this.recordsList = [];
          if ((Object.keys(data).includes("data")) && (typeof (x) === "object" || typeof (x) === "array")) {
            for (var d of data.data) {
              this.recordsList.push({ text: d.dname, value: d.did }); //{did:d.did, dname:d.dname, dtype:d.dtype}
            }

          }


          this.intervalList = [];
          var indx = this.intervalListfull.findIndex(x => x.value === data.interval);
          for (var i = indx; i < this.intervalListfull.length; i++) {
            this.intervalList.push(this.intervalListfull[i]);
          }


        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    addItem: function(type) {
      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: "historicalData",
        filter: {
          dataType: ["!complex"],
          usedBy: [],
          parents: [],
          archive: null,
          withChild: false,
          page: 1,
          perPage: 5000
        }
      });
      this.$root.$emit("bv::show::modal", "modal-widget-pick-data-" + "SHD");
    },
    removeItem: function(record) {
      let index = this.equipment.data.findIndex(x => x._id === record._id);
      if (index != -1)
        this.equipment.data.splice(index, 1);
    },
    openSearchBox: function(type) {
      this.$root.$emit("bv::show::modal", "search-modal");
    },
    getList: function() {
      this.$store.dispatch("data/getAllIngestion").then((res) => {
        this.list = res.data.data;
      });
    },
    initSearchModal: function(type) {
      const multiSelectObject = new MultiSelect({
        dataSource: this.typeList,
        enableRtl: this.lang == "per",
        value: this.equipment.operations,
        // popupHeight: '200px',
        //set width to popup list
        // popupWidth: '250px',
        fields: { text: this.lang, value: "value" },
        // set placeholder to MultiSelect input element
        placeholder: this.dict.operations[this.lang],
        floatLabelType: "Auto",
        change: (args) => {
          if (args.value.indexOf("none") > -1) {
            this.equipment.operations = ["none"];
            multiSelectObject.value = ["none"];
            multiSelectObject.refresh();
            this.equipment.gapHandling.status = false;
            this.equipment.gapHandling.value = "N/A";
            this.equipment.resamplingRate = "none";
          } else this.equipment.operations = args.value;
        }
      });

      multiSelectObject.appendTo("#msel1");


      const startDateTimePicker = new DateTimePicker({
        value: this.equipment.startTime, // Set the initial date
        placeholder: this.dict.startTime[this.lang],
        floatLabelType: "Auto",
        locale: "en",

        change: (args) => {
          this.equipment.startTime = args.value;
        }
      });

      const endDateTimePicker = new DateTimePicker({
        value: this.equipment.endTime, // Set the initial date
        placeholder: this.dict.endTime[this.lang],
        floatLabelType: "Auto",
        locale: "en",
        change: (args) => {
          this.equipment.endTime = args.value;
        }
      });
      startDateTimePicker.appendTo("#startDTP");
      endDateTimePicker.appendTo("#endDTP");

      this.numericTextBox = new NumericTextBox({
        value: this.equipment.maxdata, // Set the initial date
        placeholder: this.dict.endTime[this.lang],
        floatLabelType: "Auto",
        min: 0,
        step: 1,
        format: "N0",
        enabled: this.equipment.outputLimit.status,
        change: (args) => {
          this.equipment.outputLimit.number = args.value;
        }
      });
      this.numericTextBox.appendTo("#maxdata");
      this.showSearchModal = true;
    },
    disableNumericTextBox: function(type) {
      this.numericTextBox.enabled = this.equipment.outputLimit.status;
    },
    handleSearchModalReset() {
      this.mode = "";
      this.exportLoading = false;
      this.equipment = {
        format: "json",
        name: "",
        data: [],
        trigger: {
          dataType: "String",
          name: "",
          type: "",
          _id: "",
          value: ""
        },
        startTime: null,
        endTime: null,
        resamplingRate: null,
        outputLimit: {
          status: false,
          number: 200
        },
        gapHandling: {
          status: false,
          value: ""
        },
        destination: "platform",
        operations: []
      };
      this.showSearchModal = false;
      this.submitted = null;
      this.successful = null;
      this.message = null;
      this.$nextTick(() => {
        this.$bvModal.hide("search-modal");
      });
    },
    updateData() {
      this.$validator.validate().then(isValid => {
        if (isValid) {
          this.$nextTick(() => {
            this.$bvModal.hide("search-modal");
          });

          this.equipment.startTime = this.utcMaker(this.equipment.startTime);
          this.equipment.endTime = this.utcMaker(this.equipment.endTime);

          if (this.equipment.destination == "platform")
            this.equipment.trigger = "none";

          // this.equipment=

          this.$store.dispatch("data/editIngestion", { updates: this.equipment, _id: this.equipment._id }).then(() => {

            this.getList();
          });
        }
      });
    }

  },
  mounted() {
    this.initModal();
    this.getList();
    this.$store.dispatch("data/sendDataForSSE", { id: [], action: "add" });
  },
  provide: {
    grid: [Page, Sort, Toolbar, PdfExport, ExcelExport, Edit, Search]
  }

};
</script>

<style scoped src="@/assets/styles/theme.css"></style>

<style>

.e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before, .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before, .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::after, .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after {
  background-color: #1381e2;
}

.e-multiselect.e-input-group.e-control-wrapper.e-float-input.e-input-focus .e-float-text.e-label-top, .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text {
  color: #1381e2;
}


</style>

<style scoped>
.td-class {
  width: 20%;
  text-align: left;
  font-weight: bold;
  font-size: large;
}
</style>

<style>
.vpd-main {
  width: 100%;
  display: inline-block;
}

.vpd-input-group {
  border: 1px solid #0d80f3;
}
</style>


